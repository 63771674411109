import {
  createEffect,
  createSignal,
  For,
  JSX,
  JSXElement,
  Show,
} from "solid-js";
import CheckBox from "./CheckBox";
import Button from "./Button";
import Spacer from "./Spacer";
import { handleRequiredInObject } from "../Pages/Login/validate";
import ChevronDown from "../Icons/ChevronDown";
type Props = {
  hideChooseAll?: boolean;
  customButton?: JSX.Element;
  name: string;
  label?: string;
  disabled?: boolean;
  value?: { [key: string]: boolean };
  onChange?: (name: string, value: boolean) => void;
  error?: boolean;
  errorLabel?: string;
  icon?: boolean;
  options: { [key: string]: string };
  class?: string;
  showReport?: boolean;
  placeholder?: string;
  required?: boolean;
  header?: string;
  store: {};
  errorSetter: () => {};
  singular: boolean;
  onclick?: (id: string) => {};
  hideSearch?: boolean;
};

const MultiSelect = (props: Props) => {
  const [open, setOpen] = createSignal(false);
  const [checkAll, setCheckAll] = createSignal(true);
  const values = () => {
    return Object.keys(props.value).filter((key) => props.value?.[key]);
  };

  const [search, setSearch] = createSignal("");

  createEffect(() => {
    if (!open()) {
      setSearch("");
    }
  });
  return (
    <div class={"m-auto md:m-0 relative " + props.class}>
      <div class="text-right text-sm text-darkGrey">
        {props.label} {props.required && "*"}
      </div>
      <button
        id={props.name}
        onClick={() => {
          if (props.errorSetter && props.required) {
            handleRequiredInObject(props.name, props.store, props.errorSetter);
          }
          setOpen(!open());
        }}
        disabled={props.disabled || !props.options}
        class={`w-full overflow-hidden ${
          props.error && props.error(props.name) ? "border border-error" : ""
        } h-[72px] text-blue p-6 text-right rounded-xl text-sm disabled:text-darkGrey bg-lightGrey `}
      >
        <Show when={props.value && props.options}>
          <p class="text-darkGrey line-clamp-2 w-10/12">
            <For each={values()} fallback={props.placeholder}>
              {(value, index) => {
                return (
                  <span>
                    {props.options[value]}
                    <Show when={index() < values().length - 1}>, </Show>
                  </span>
                );
              }}
            </For>
          </p>
        </Show>
      </button>
      <Show when={open() && !props.disabled}>
        <Closer
          handler={() => {
            if (props.errorSetter && props.required) {
              handleRequiredInObject(
                props.name,
                props.store,
                props.errorSetter
              );
            }
            setOpen(false);
          }}
        />
        <div class="bg-white rounded shadow-blackShadow absolute z-30 w-full lg:min-w-[280px] p-4  max-h-none h-[65vh] md:h-[50vh] overflow-y-scroll">
          <Show when={!props.singular}>
            <div class="flex items-center">
              <p class="w-8/12 md:text-xs">{props.header} </p>{" "}
              <Button
                name="choose all"
                onClick={() => {
                  Object.keys(props.options).forEach((key) => {
                    props.onChange(key, checkAll());
                  });
                  setCheckAll(!checkAll());
                }}
                class={
                  "text-center text-xs md:p-0 lg:w-24 " +
                  +(props.hideChooseAll ? "opacity-0" : "scale-75")
                }
                border
                short
              >
                בחר הכל
              </Button>
            </div>
          </Show>
          <Spacer height="4px" />
          <Show when={!props.hideSearch}>
            <input
              type="text"
              class="h-10 bg-lightGrey rounded-md w-full"
              value={search()}
              placeholder="חיפוש"
              onInput={(e) => setSearch(e.currentTarget.value)}
            />
          </Show>
          <For
            each={Object.keys(props.options).filter((key) => {
              return props.options[key]
                ?.toLowerCase()
                .includes(search().toLowerCase());
            })}
          >
            {(key) => {
              return (
                <div class="text-blue text-sm p-3 flex gap-2 items-center">
                  <Show when={!props.singular}>
                    <CheckBox
                      textSize={"12px"}
                      label={props.options[key]}
                      value={props.value?.[key]}
                      setValue={(value) => {
                        props.onChange(key, value);
                      }}
                    />
                    {/* <p>{props.options[key]}</p> */}
                  </Show>
                  <Show when={props.singular}>
                    <button
                      onclick={() => {
                        props.onclick(key);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      <p>{props.options[key]}</p>
                    </button>
                  </Show>
                </div>
              );
            }}
          </For>

          {props.customButton}
        </div>
      </Show>{" "}
      <div class="absolute top-1/3 left-6 cursor-pointer pointer-events-none">
        {" "}
        <Show when={!props.disabled}>
          <ChevronDown />
        </Show>
      </div>
      <div class="text-right text-error">{props.errorLabel}</div>
    </div>
  );
};

export default MultiSelect;
export function Closer(props: { handler: (e) => void }) {
  return (
    <button
      id="closer"
      class="fixed inset-0 opacity-0 z-0"
      onclick={props.handler}
    />
  );
}
