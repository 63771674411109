import { screenState } from "../App";
import Spacer from "../Components/Spacer";


export const SafeAreaDiv =( { position }: { position: "top" | "bottom" }) => {
    const height = () => screenState[position];
  
    return (
      <div
        style={{ width: "100%", height: `${height()}px`, "z-index": 100 }}
        class="fixed bg-white bg-opacity-0 md:hidden"
        classList={{
          "bottom-0": position === "bottom",
          "top-0": position === "top",
        }}
      >
        <Spacer height={height() + "px"} />
      </div>
    );
  }