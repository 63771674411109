/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * * `ER` - מזגן בכל חדר
 * * `CL` - מיזוג מרכזי
 */
export enum AirConditioningStateEnum {
  ER = "מזכן בכל חדר",
  CL = "מיזוג מרכזי",
}

export interface AnnotatedAsset {
  id: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor: number;
  /** תיאור כללי */
  general_description?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  realtor_contacts: RealtorSlim[];
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels: AssetLevel[];
  separate_units: AssetSeparateUnit[];
  image_urls: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  ranking: string;
}

export interface Asset {
  id: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor: number;
  /** תיאור כללי */
  general_description?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels: AssetLevel[];
  separate_units: AssetSeparateUnit[];
  image_urls: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  realtor_contacts: RealtorSlim[];
  realtors_can_view: RealtorSlim[];
  potential_buyers: PotentialBuyer;
  /**
   * שטח הגג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  roof_size?: string | null;
  /**
   * שנת בנייה
   * @min 1850
   * @max 2500
   */
  construction_year?: number | null;
  /** בהשכרה */
  is_rented?: boolean;
  /**
   * שירותים
   * @min 0
   * @max 32767
   */
  toilets?: number | null;
  /** מועמד להריסה */
  is_for_demolition?: boolean;
}

/**
 * * `NC` - חדש מקבלן
 * * `NB` - חדש - נבנה ב5 שנים האחרונות
 * * `FL` - משופץ - שופץ ב5 שנים האחרונות
 * * `PD` - שמור - לא שופץ אך מצבו טוב
 * * `NF` - לשיפוץ - זקוק לשיפוץ ותחזוקה
 */
export enum AssetConditionEnum {
  NC = "חדש מקבלן",
  NB = "חדש - נבנה ב5 שנים האחרונות",
  FL = "משופץ - שופץ ב5 שנים האחרונות",
  PD = "שמור - לא שופץ אך מצבו טוב",
  NF = "לשיפוץ - זקוק לשיפוץ ותחזוקה",
}

export enum AssetConditionEnumValidation {
  NC = "NC",
  NB = "NB",
  FL = "FL",
  PD = "PD",
  NF = "NF",
}

export function EnumKey(num, key) {
  const res = Object.keys(num).find((k) => {
    return num[k] === key;
  });

  return res;
}

/**
 * * `FT` - חזית
 * * `BK` - עורף
 * * `WG` - חצי חזית
 */
export enum AssetFacingEnum {
  FT = "חזית",
  BK = "עורף",
  WG = "חצי חזית",
}

export enum AssetFacingEnumValidation {
  FT = "FT",
  BK = "BK",
  WG = "WG",
}

export interface AssetLevel {
  id: number;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  built_area: string;
  /**
   * שטח מרפסת/גג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  outside_area?: string | null;
}

export interface AssetSeparateUnit {
  id: number;
  /**
   * גודל היחידה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  unit_size: string;
  /** האם היחידה מושכרת */
  is_currently_rented?: boolean;
  /**
   * מחיר השכירות
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
}

/**
 * * `AT` - דירה
 * * `GT` - דירת גן
 * * `RT` - דירת גג
 * * `PE` - פנטהאוז
 * * `DX` - דופלקס
 * * `TX` - טריפלקס
 * * `PH` - בית פרטי
 * * `TF` - דו משפחתי
 */
export enum AssetTypeEnum {
  AT = "דירה",
  GT = "דירת גן",
  RT = "דירת גג",
  PE = "פנטהאוז / מיני פנטהאוז",
  DX = "דופלקס",
  TX = "טריפלקס",
  PH = "בית פרטי",
  TF = "דו משפחתי",
}

export interface AssetViewSetResponseSchema {
  id: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor: number;
  /** תיאור כללי */
  general_description?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels: AssetLevel[];
  separate_units: AssetSeparateUnit[];
  image_urls: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  realtor_contacts: RealtorSlim[];
  realtors_can_view: RealtorSlim[];
  potential_buyers: PotentialBuyer[];
}

export interface Banner {
  id: number;
  /**
   * לקוח
   * @maxLength 50
   */
  client: string;
  image_url: string;
  /**
   * מייל של המפרסם
   * @format email
   * @maxLength 254
   */
  advertiser_email?: string | null;
}

export interface BasicEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
}

export enum BlankEnum {
  Value = "",
}

/**
 * * `AV`  לקוח פעיל-
 * * `DT` - לקוח רדום
 * * `PT` - לקוח עבר
 * * `CT` - איש קשר
 */

export enum BuyerEndCustomerActivityStateEnum {
  AV = "לקוח פעיל",
  DT = "לקוח רדום",
  PT = "לקוח עבר",
  CT = "איש קשר",
  RP = "לקוח פעיל",
}

/**
 * * `FA` - דירה ראשונה
 * * `IM` - משפר דיור
 * * `IN` - משקיע
 */
export enum BuyerEndCustomerStatusEnum {
  FA = "דירה ראשונה",
  IM = "משפר דיור",
  IN = "משקיע",
}

export enum BuyerEndCustomerStatusValidation {
  FA = "FA",
  IM = "IM",
  IN = "IN",
}

export interface City {
  id: number;
  /** שם */
  name: string;
}

export interface CreateAsset {
  id: number;
  /** תיאור כללי */
  general_description?: string | null;
  /** עיר */
  city: number;
  /** שכונה */
  neighborhood?: number | null;
  /** רחוב */
  street: number;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels?: AssetLevel[];
  separate_units?: AssetSeparateUnit[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  /**
   * שטח הגג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  roof_size?: string | null;
  /**
   * שנת בנייה
   * @min 1850
   * @max 2500
   */
  construction_year?: number | null;
  /** בהשכרה */
  is_rented?: boolean;
  /**
   * שירותים
   * @min 0
   * @max 32767
   */
  toilets?: number | null;
  /** מועמד להריסה */
  is_for_demolition?: boolean;
  number_of_potential_buyers: number;
}

export interface CreateAssetInteractionSerializerForPhoneCall {
  id: number;
  /** נכס */
  asset: number;
  /** לקוח */
  customer: number;
}

export interface CreateDeal {
  id: number;
  /** לקוח מוכר */
  selling_customer?: number | null;
  /** סוכן שייצג את המוכר */
  selling_customer_realtor: number;
  /** לקוח קונה */
  purchasing_customer: number;
  /** סוכן שייצג את הקונה */
  purchasing_customer_realtor: number;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  payed_price?: string | null;
  /**
   * תאריך עסקה
   * @format date-time
   */
  deal_date: string;
  /** נכס */
  asset: number;
  /** סוג עסקה */
  deal_type: DealTypeEnum;
}

export interface CreateDealSerializerSchema {
  id: number;
  /** לקוח מוכר */
  selling_customer?: number | null;
  /** סוכן שייצג את המוכר */
  selling_customer_realtor: number;
  /** לקוח קונה */
  purchasing_customer: number;
  /** סוכן שייצג את הקונה */
  purchasing_customer_realtor: number;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  payed_price?: string | null;
  /**
   * תאריך עסקה
   * @format date-time
   */
  deal_date: string;
  /** סוג עסקה */
  deal_type: DealTypeEnum;
}

export interface CreateDontCallMeConsent {
  id: number;
  /** סוכן */
  realtor: number | null;
  /** לקוח */
  customer: number | null;
}

export interface CreateGesherTask {
  /** תוכן */
  content?: string | null;
  /** סוכן שדווח עליו */
  reported_realtor?: number | null;
  /** נכס */
  asset?: number | null;
}

export interface CreateTask {
  id: number;
  /**
   * כותרת
   * @maxLength 50
   */
  title: string;
  /** הערות */
  notes?: string | null;
  /** שיוך לקוח */
  customers?: number[];
  /** שיוך סוכנים */
  other_realtors?: (number | null)[];
  /** שיוך נכס */
  asset?: number | null;
  /** סטטוס */
  status?: StatusEnum;
  /** @format date-time */
  created_at: string;
  /**
   * תאריך התחלה
   * @format date-time
   */
  start_time?: string | null;
  /**
   * תאריך סיום
   * @format date-time
   */
  end_time?: string | null;
  reminders?: Reminder[];
}

/**
 * * `RT` - השכרה
 * * `PE` - רכישה
 */
export enum DealTypeEnum {
  RT = "השכרה",
  PE = "רכישה",
}

export interface DisplayTask {
  id: number;
  /**
   * כותרת
   * @maxLength 50
   */
  title: string;
  /** הערות */
  notes?: string | null;
  customers: BasicEndCustomer[];
  other_realtors: MinimalRealtor[];
  asset: Asset;
  /** סטטוס */
  status?: StatusEnum;
  /**
   * נוצרה בתאריך
   * @format date-time
   */
  created_at: string;
  /**
   * תאריך התחלה
   * @format date-time
   */
  start_time?: string | null;
  /**
   * תאריך סיום
   * @format date-time
   */
  end_time?: string | null;
  reminders: Reminder[];
}

export enum NotificationTypes {
  UNANSWERED_CALL = "שיחה שלא נענתה",
  NEW_CALL = "שיחה חדשה",
  RELEVANT_ASSET = "נכס רלוונטי",
  TASK_REMINDER = "תזכורת על משימה",
}
export interface DontCallMeConsent {
  id: number;
  /**
   * זמן הסכמה
   * @format date-time
   */
  consent_date?: string | null;
  /** ניתנה הסכמה */
  is_signed?: boolean;
  /** לקוח */
  customer: number | null;
  /** סוכן */
  realtor: number | null;
}

export interface EndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  /**
   * תאריך הרשמה
   * @format date
   */
  registration_date: string;
  /** סטטוס לקוח */
  buyer_end_customer_status?:
    | BuyerEndCustomerStatusEnum
    | BlankEnum
    | NullEnum
    | null;
  /** סוכן */
  realtor: number;
  /** רמת פעילות לקוח */
  buyer_end_customer_activity_state?:
    | BuyerEndCustomerActivityStateEnum
    | BlankEnum
    | NullEnum
    | null;
  number_of_potential_buyer_matches: number;
  /** לקוח VIP */
  is_vip?: boolean;
  /** מהיכן הגיע */
  lead_source?: LeadSourceEnum | BlankEnum | NullEnum | null;
  /** אל תתקשר אליי */
  dont_call_me: boolean;
  notes: EndCustomerNote[];
  purchase_listings: PurchaseListingResponse[];
  phonecalls: PhoneCall[];
  interested_in_assets: RealtorHomeAsset[];
  sale_listings: Asset[];
  dont_call_me_consent: boolean;
  consent_document_id: number | null;
}

export interface EndCustomerNote {
  id: number;
  /** תוכן */
  content: string;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
}

/**
 * * `IE` - מיידי
 * * `TS` - 3-6 חודשים
 * * `TM` - עד 10 חודשים
 * * `FX` - גמיש
 */
export enum EvictionRangeEnum {
  IE = "מיידי",
  TS = "3-6 חודשים",
  TM = "עד 10 חודשים",
  FX = "גמיש",
}

export enum EvictionRangeEnumValidation {
  IE = "IE",
  TS = "TS",
  TM = "TM",
  FX = "FX",
}

export interface GiveConsentDontCallMeConsent {
  customer_id: number;
  customer_phone: string;
}

export interface HomeRealtor {
  id: number;
  agency: MinimalAgency;
  name: string;
  can_import_clients: boolean;
  /**
   * מספר טלפון
   * @maxLength 128
   */
  phone: string;
  /**
   * לינקדאין
   * @format uri
   * @maxLength 200
   */
  linkedin?: string | null;
  activity_areas: City[];
  leads?: RealtorLeadEndCustomer[];
  active_assets?: RealtorHomeAsset[];
  num_sold_assets: number;
  buyers?: RealtorHomeBuyerEndCustomer[];
  sellers?: RealtorHomeSellerEndCustomer[];
  num_new_assets: number;
  num_updated_assets: number;
  notifications: RealtorHomeNotifications[];
  realtor_blacklist: MinimalRealtor[];
  /**
   * כתובת דוא"ל
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  virtual_phone: string;
  /**
   * טיקטוק
   * @format uri
   * @maxLength 200
   */
  tiktok?: string | null;
  /**
   * אינסטגרם
   * @format uri
   * @maxLength 200
   */
  instagram?: string | null;
  /**
   * אתר עסקי
   * @format uri
   * @maxLength 200
   */
  webpage?: string | null;
  /** אודות */
  about?: string | null;
  /**
   * פייסבוק
   * @format uri
   * @maxLength 200
   */
  facebook?: string | null;
  image_url: string;
  /** הצג נכסים בפרופיל */
  show_asset_numbers?: boolean;
  /** הצג קונים בפרופיל */
  show_buyer_numbers?: boolean;
  /** הצג עסקאות בפרופיל */
  show_deal_numbers?: boolean;
  /** צריך אונבורדינג */
  needs_onboarding?: boolean;
  slug: string;
  uuid: string;
}

/**
 * * `Y2` - יד 2
 * * `OT` - אחר
 * * `PT` - פלייר
 * * `SN` - שלט
 * * `FB` - פייסבוק
 * * `OP` - און מפ
 * * `MN` - מדלן
 */
export enum LeadSourceEnum {
  Y2 = "יד 2",
  OT = "אחר",
  PT = "פלייר",
  SN = "שלט",
  FB = "פייסבוק",
  OP = "און מפ",
  MN = "מדלן",
}

export interface ListAsset {
  id: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor: number;
  /** תיאור כללי */
  general_description?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels: AssetLevel[];
  separate_units: AssetSeparateUnit[];
  image_urls: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  realtor_contacts: RealtorSlim[];
  realtors_can_view: RealtorSlim[];
  /**
   * שטח הגג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  roof_size?: string | null;
  /**
   * שנת בנייה
   * @min 1850
   * @max 2500
   */
  construction_year?: number | null;
  /** בהשכרה */
  is_rented?: boolean;
  /**
   * שירותים
   * @min 0
   * @max 32767
   */
  toilets?: number | null;
  /** מועמד להריסה */
  is_for_demolition?: boolean;
}

export interface MinimalAgency {
  id: number;
  /**
   * שם סוכנות
   * @maxLength 50
   */
  name: string;
}

export interface MinimalRealtor {
  id: number;
  name: string;
  /**
   * מספר טלפון
   * @maxLength 128
   */
  phone: string;
  image_url: string;
}

export interface Neighborhood {
  id: number;
  /** שם */
  name: string;
}

export enum NullEnum {
  Null = null,
}

export interface PaginatedAssetViewSetResponseSchemaList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: AssetViewSetResponseSchema[];
}

export interface PaginatedCityList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: City[];
}

export interface PaginatedDisplayTaskList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: DisplayTask[];
}

export interface PaginatedListAssetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: ListAsset[];
}

export interface PaginatedNeighborhoodList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: Neighborhood[];
}

export interface PaginatedRealtorAssetMatchesList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: RealtorAssetMatches[];
}

export interface PaginatedRealtorProfileList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: RealtorProfile[];
}

export interface PaginatedStreetList {
  /** @example 123 */
  count?: number;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next?: string | null;
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous?: string | null;
  results?: Street[];
}

export interface PatchedCreateTask {
  id?: number;
  /**
   * כותרת
   * @maxLength 50
   */
  title?: string;
  /** הערות */
  notes?: string | null;
  /** שיוך לקוח */
  customers?: number[];
  /** שיוך סוכנים */
  other_realtors?: (number | null)[];
  /** שיוך נכס */
  asset?: number | null;
  /** סטטוס */
  status?: StatusEnum;
  /** @format date-time */
  created_at?: string;
  /**
   * תאריך התחלה
   * @format date-time
   */
  start_time?: string | null;
  /**
   * תאריך סיום
   * @format date-time
   */
  end_time?: string | null;
  reminders?: Reminder[];
}

export interface PatchedEndCustomer {
  id?: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone?: string;
  /**
   * תאריך הרשמה
   * @format date
   */
  registration_date?: string;
  /** סטטוס לקוח */
  buyer_end_customer_status?:
    | BuyerEndCustomerStatusEnum
    | BlankEnum
    | NullEnum
    | null;
  /** סוכן */
  realtor?: number;
  /** רמת פעילות לקוח */
  buyer_end_customer_activity_state?:
    | BuyerEndCustomerActivityStateEnum
    | BlankEnum
    | NullEnum
    | null;
  number_of_potential_buyer_matches?: number;
  /** לקוח VIP */
  is_vip?: boolean;
  /** מהיכן הגיע */
  lead_source?: LeadSourceEnum | BlankEnum | NullEnum | null;
  /** אל תתקשר אליי */
  dont_call_me?: boolean;
  notes?: EndCustomerNote[];
  purchase_listings?: PurchaseListingResponse[];
  phonecalls?: PhoneCall[];
  interested_in_assets?: RealtorHomeAsset[];
  sale_listings?: Asset[];
  dont_call_me_consent?: boolean;
  consent_document_id?: number | null;
}

export interface PatchedListAsset {
  id?: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor?: number;
  /** תיאור כללי */
  general_description?: string | null;
  city?: City;
  neighborhood?: Neighborhood;
  street?: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms?: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at?: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified?: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels?: AssetLevel[];
  separate_units?: AssetSeparateUnit[];
  image_urls?: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  realtor_contacts?: RealtorSlim[];
  realtors_can_view?: RealtorSlim[];
  /**
   * שטח הגג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  roof_size?: string | null;
  /**
   * שנת בנייה
   * @min 1850
   * @max 2500
   */
  construction_year?: number | null;
  /** בהשכרה */
  is_rented?: boolean;
  /**
   * שירותים
   * @min 0
   * @max 32767
   */
  toilets?: number | null;
  /** מועמד להריסה */
  is_for_demolition?: boolean;
}

export interface PatchedPurchaseListingUpdate {
  id?: number;
  /** עיר */
  city?: number;
  /** שכונות */
  neighborhoods?: number[];
  /**
   * תקציב
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  budget?: string;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  /** מועד פינוי */
  requested_eviction_range?:
    | RequestedEvictionRangeEnum
    | BlankEnum
    | NullEnum
    | null;
  /**
   * מספר חדרים
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  number_of_rooms?: string;
  /**
   * מקומה
   * @min 0
   * @max 32767
   */
  from_floor?: number | null;
  /**
   * עד קומה
   * @min 0
   * @max 32767
   */
  to_floor?: number | null;
  /** לא קומה ראשונה */
  not_first_floor?: boolean;
  /** לא קומה אחרונה */
  not_last_floor?: boolean;
  /**
   * שטח הנכס
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_size?: string | null;
  /**
   * שטח בנוי מגרש
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_outside_size?: string | null;
  /** מעלית */
  should_have_elevator?: boolean;
  /**
   * מעלית מקומה
   * @min 0
   * @max 32767
   */
  elevator_from_floor?: number | null;
  /** להציג מבנים מסוכנים */
  should_show_dangerous_buildings?: boolean;
  /** חניה בטאבו */
  should_have_parking_in_registry?: boolean;
  /** מרפסת שמש */
  should_have_sun_terrace?: boolean;
  /** מחסן */
  should_have_storage_space?: boolean;
  /**
   * תאריך פינוי מבוקש
   * @format date
   */
  requested_eviction_date?: string | null;
  /** ממ"ד */
  should_have_in_house_shelter?: boolean;
}

export interface PatchedRealtorPatch {
  /**
   * טיקטוק
   * @format uri
   * @maxLength 200
   */
  tiktok?: string | null;
  /**
   * אתר עסקי
   * @format uri
   * @maxLength 200
   */
  webpage?: string | null;
  /**
   * לינקדאין
   * @format uri
   * @maxLength 200
   */
  linkedin?: string | null;
  /** אודות */
  about?: string | null;
  /**
   * כתובת דוא"ל
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * פייסבוק
   * @format uri
   * @maxLength 200
   */
  facebook?: string | null;
  /**
   * אינסטגרם
   * @format uri
   * @maxLength 200
   */
  instagram?: string | null;
  /** הצג נכסים בפרופיל */
  show_asset_numbers?: boolean;
  /** הצג קונים בפרופיל */
  show_buyer_numbers?: boolean;
  /** הצג עסקאות בפרופיל */
  show_deal_numbers?: boolean;
}

export interface PhoneCall {
  id: number;
  /** לקוח */
  customer: number;
  /** סוכן */
  realtor: number;
  /**
   * מזהה ייחודי של הטלפנייה
   * @maxLength 20
   */
  call_id: string;
  /** @format date-time */
  call_date: string;
  /**
   * משך השיחה
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,0})?$
   */
  call_duration: string;
  /**
   * קישור לקובץ שיחה
   * @format uri
   * @maxLength 200
   */
  call_url?: string | null;
  call_file_url: string;
  call_file_is_downloaded: boolean;
}

export interface PhoneCheckEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  /** ארכיון */
  is_archived?: boolean;
  /** רמת פעילות לקוח */
  buyer_end_customer_activity_state?:
    | BuyerEndCustomerActivityStateEnum
    | BlankEnum
    | NullEnum
    | null;
}

export interface PotentialBuyer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  agency: number;
  /** סטטוס לקוח */
  buyer_end_customer_status?:
    | BuyerEndCustomerStatusEnum
    | BlankEnum
    | NullEnum
    | null;
  notes: PotentialBuyerNote[];
}

export interface PotentialBuyerNote {
  id: number;
  /** תוכן */
  content: string;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
}

export interface PublicAsset {
  id: number;
  /** לקוח */
  seller?: number | null;
  /** סוכן */
  realtor: number;
  /** תיאור כללי */
  general_description?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  number_of_rooms: string;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * קומה עליונה במבנה
   * @min 0
   * @max 32767
   */
  highest_floor?: number | null;
  /** מצב הנכס */
  asset_condition?: AssetConditionEnum | BlankEnum | NullEnum | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * שטח גינה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_outside_area?: string | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  /**
   * תאריך פינוי
   * @format date
   */
  eviction_date?: string | null;
  /**
   * תאריך סיום שכירות
   * @format date
   */
  rent_end_date?: string | null;
  /** זמן פינוי */
  eviction_range?: EvictionRangeEnum | BlankEnum | NullEnum | null;
  /**
   * ארנונה
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  property_tax_price?: string | null;
  /**
   * ועד בית
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  house_committee_price?: string | null;
  /** צפון */
  north_airway?: boolean;
  /** דרום */
  south_airway?: boolean;
  /** מזרח */
  east_airway?: boolean;
  /** מערב */
  west_airway?: boolean;
  /** לאן פונה הנכס */
  asset_facing?: AssetFacingEnum | BlankEnum | NullEnum | null;
  /**
   * חדרי אמבטיה
   * @min 0
   * @max 32767
   */
  bathrooms?: number | null;
  /** יחידת הורים */
  has_parent_unit?: boolean;
  /** ממ"ד */
  has_in_house_shelter?: boolean;
  /**
   * שטח ממ"ד
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  in_house_shelter_size?: string | null;
  /** מחסן */
  has_storage_space?: boolean;
  /**
   * שטח מחסן
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  storage_space_size?: string | null;
  /** מעלית */
  has_elevator?: boolean;
  /**
   * מספר מעליות
   * @min 0
   * @max 32767
   */
  number_of_elevators?: number | null;
  /** חניה בטאבו */
  has_parking_in_registry?: boolean;
  /**
   * מספר חניות
   * @min 0
   * @max 32767
   */
  number_of_parking_spots?: number | null;
  /** מיזוג */
  has_air_conditioning?: boolean;
  /** אופן מיזוג */
  air_conditioning_state?:
    | AirConditioningStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** יחידה נפרדת */
  has_separate_unit?: boolean;
  /** הערות */
  notes?: string | null;
  /** נגישות לנכים */
  is_accessible_for_disabled_people?: boolean;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  /** להציג מספר בניין */
  should_show_address_number?: boolean;
  /**
   * תאריך יצירה
   * @format date-time
   */
  created_at: string;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  /** נמכר */
  is_sold?: boolean;
  asset_levels: AssetLevel[];
  separate_units: AssetSeparateUnit[];
  image_urls: string[];
  /** מרפסת שמש */
  has_sun_terrace?: boolean;
  /**
   * שטח מרפסת שמש
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  sun_terrace_size?: string | null;
  /** מבנה מסוכן */
  is_dangerous_building?: boolean;
  realtor_contacts: RealtorSlim[];
  realtors_can_view: RealtorSlim[];
  /**
   * שטח הגג
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  roof_size?: string | null;
  /**
   * שנת בנייה
   * @min 1850
   * @max 2500
   */
  construction_year?: number | null;
  /** בהשכרה */
  is_rented?: boolean;
  /**
   * שירותים
   * @min 0
   * @max 32767
   */
  toilets?: number | null;
  /** מועמד להריסה */
  is_for_demolition?: boolean;
  banner: Banner;
}

export interface PurchaseListing {
  new_asset: boolean;
  id: number;
  /** עיר */
  city: number;
  /** שכונות */
  neighborhoods?: number[];
  asset_types_list?: Array<string>;
  /**
   * תקציב
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  budget: string;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  /** מועד פינוי */
  requested_eviction_range?:
    | RequestedEvictionRangeEnum
    | BlankEnum
    | NullEnum
    | null;
  /**
   * מספר חדרים
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  number_of_rooms: string;
  /**
   * מקומה
   * @min 0
   * @max 32767
   */
  from_floor?: number | null;
  /**
   * עד קומה
   * @min 0
   * @max 32767
   */
  to_floor?: number | null;
  /** לא קומה ראשונה */
  not_first_floor?: boolean;
  /** לא קומה אחרונה */
  not_last_floor?: boolean;
  /**
   * שטח הנכס
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_size?: string | null;
  /**
   * שטח בנוי מגרש
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_outside_size?: string | null;
  /** מעלית */
  should_have_elevator?: boolean;
  /**
   * מעלית מקומה
   * @min 0
   * @max 32767
   */
  elevator_from_floor?: number | null;
  /** להציג מבנים מסוכנים */
  should_show_dangerous_buildings?: boolean;
  /** חניה בטאבו */
  should_have_parking_in_registry?: boolean;
  /** מרפסת שמש */
  should_have_sun_terrace?: boolean;
  /** מחסן */
  should_have_storage_space?: boolean;
  /**
   * תאריך פינוי מבוקש
   * @format date
   */
  requested_eviction_date?: string | null;
  /** ממ"ד */
  should_have_in_house_shelter?: boolean;
}

export interface PurchaseListingResponse {
  id: number;
  city: City;
  neighborhoods: Neighborhood[];
  /**
   * תקציב
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  budget: string;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  /** מועד פינוי */
  requested_eviction_range?:
    | RequestedEvictionRangeEnum
    | BlankEnum
    | NullEnum
    | null;
  /**
   * מספר חדרים
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  number_of_rooms: string;
  /**
   * מקומה
   * @min 0
   * @max 32767
   */
  from_floor?: number | null;
  /**
   * עד קומה
   * @min 0
   * @max 32767
   */
  to_floor?: number | null;
  /** לא קומה ראשונה */
  not_first_floor?: boolean;
  /** לא קומה אחרונה */
  not_last_floor?: boolean;
  /**
   * שטח הנכס
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_size?: string | null;
  /**
   * שטח בנוי מגרש
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_outside_size?: string | null;
  /** מעלית */
  should_have_elevator?: boolean;
  /**
   * מעלית מקומה
   * @min 0
   * @max 32767
   */
  elevator_from_floor?: number | null;
  /** להציג מבנים מסוכנים */
  should_show_dangerous_buildings?: boolean;
  /** חניה בטאבו */
  should_have_parking_in_registry?: boolean;
  /** מרפסת שמש */
  should_have_sun_terrace?: boolean;
  /** מחסן */
  should_have_storage_space?: boolean;
  /**
   * תאריך פינוי מבוקש
   * @format date
   */
  requested_eviction_date?: string | null;
  /** ממ"ד */
  should_have_in_house_shelter?: boolean;
}

export interface PurchaseListingUpdate {
  id: number;
  /** עיר */
  city: number;
  /** שכונות */
  neighborhoods?: number[];
  /**
   * תקציב
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  budget: string;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  /** מועד פינוי */
  requested_eviction_range?:
    | RequestedEvictionRangeEnum
    | BlankEnum
    | NullEnum
    | null;
  /**
   * מספר חדרים
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,1})?$
   */
  number_of_rooms: string;
  /**
   * מקומה
   * @min 0
   * @max 32767
   */
  from_floor?: number | null;
  /**
   * עד קומה
   * @min 0
   * @max 32767
   */
  to_floor?: number | null;
  /** לא קומה ראשונה */
  not_first_floor?: boolean;
  /** לא קומה אחרונה */
  not_last_floor?: boolean;
  /**
   * שטח הנכס
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_size?: string | null;
  /**
   * שטח בנוי מגרש
   * @format decimal
   * @pattern ^-?\d{0,3}(?:\.\d{0,2})?$
   */
  asset_outside_size?: string | null;
  /** מעלית */
  should_have_elevator?: boolean;
  /**
   * מעלית מקומה
   * @min 0
   * @max 32767
   */
  elevator_from_floor?: number | null;
  /** להציג מבנים מסוכנים */
  should_show_dangerous_buildings?: boolean;
  /** חניה בטאבו */
  should_have_parking_in_registry?: boolean;
  /** מרפסת שמש */
  should_have_sun_terrace?: boolean;
  /** מחסן */
  should_have_storage_space?: boolean;
  /**
   * תאריך פינוי מבוקש
   * @format date
   */
  requested_eviction_date?: string | null;
  /** ממ"ד */
  should_have_in_house_shelter?: boolean;
}

export interface PurchaseListingWithMatchingAssetsAndCustomer {
  end_customer: PurchasingEndCustomer;
  purchase_listing: PurchaseListingResponse;
  assets: AnnotatedAsset[];
}

export interface PurchasingEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  agency: number;
  /** סטטוס לקוח */
  buyer_end_customer_status?:
    | BuyerEndCustomerStatusEnum
    | BlankEnum
    | NullEnum
    | null;
  /** רמת פעילות לקוח */
  buyer_end_customer_activity_state?:
    | BuyerEndCustomerActivityStateEnum
    | BlankEnum
    | NullEnum
    | null;
  /** לקוח VIP */
  is_vip?: boolean;

  /**האם לקוח רדום */
  is_dormant?: boolean;
  whatsapp_do_not_contact: boolean;
  dont_call_me: boolean;
  number_of_matches: number;
  dont_call_me_consent: boolean;
}

export interface ReadOnlyAgency {
  id: number;
  /**
   * שם סוכנות
   * @maxLength 50
   */
  name: string;
  realtors: string;
}

export interface RealtorAssetMatches {
  id: number;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  number_of_rooms: string;
  number_of_potential_buyers: number;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  image_url: string;
  agency: MinimalAgency;
  realtor_contacts: RealtorSlim[];
}

export interface RealtorHomeAsset {
  banner: any;
  image_urls: any;
  notes: any;
  id: number;
  number_of_rooms: string;
  role: string;
  updating_status?: string | null;
  /**
   * שטח בנוי
   * @format decimal
   * @pattern ^-?\d{0,4}(?:\.\d{0,1})?$
   */
  asset_built_area?: string | null;
  /**
   * קומה
   * @min 0
   * @max 32767
   */
  floor?: number | null;
  /**
   * מחיר
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  price?: string | null;
  /**
   * שכירות
   * @format decimal
   * @pattern ^-?\d{0,10}(?:\.\d{0,2})?$
   */
  rent_price?: string | null;
  city: City;
  neighborhood: Neighborhood;
  street: Street;
  /**
   * מספר בית
   * @maxLength 8
   */
  address_number?: string | null;
  /**
   * עודכן לאחרונה
   * @format date-time
   */
  last_modified: string;
  image_url: string;
  /** סוג נכס */
  asset_type?: AssetTypeEnum | BlankEnum | NullEnum | null;
  seller: RealtorHomeAssetSeller;
  realtor_contacts: RealtorSlim[];
  realtor_data: any;
  realtors_can_view: RealtorSlim[];
  potential_buyers: PotentialBuyer;
  /** סטטוס נראות */
  visibility_status?: VisibilityStatusEnum;
  advertisement_url?: string;
}

export interface RealtorHomeAssetSeller {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
}

export interface RealtorHomeBuyerEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  notes: EndCustomerNote[];
  /** אל תתקשר אליי */
  dont_call_me?: boolean;
  number_of_matches: number;
  /** לקוח VIP */
  is_vip?: boolean;
  /**
   * תאריך הרשמה
   * @format date
   */
  registration_date: string;
  dont_call_me_consent: boolean;
  /** רמת פעילות לקוח */
  buyer_end_customer_activity_state?:
    | BuyerEndCustomerActivityStateEnum
    | BlankEnum
    | NullEnum
    | null;
}

export interface RealtorHomeNotifications {
  id: number;
  /**
   * סוג התראה
   * @maxLength 24
   */
  notification_type: string;
  /**
   * תוכן
   * @maxLength 300
   */
  content: string;
  /**
   * נוצרה בתאריך
   * @format date-time
   */
  created_at: string;
  /**
   * מזהה ייחודי של נכס/לקוח
   * @min 0
   * @max 2147483647
   */
  referenced_object_id?: number | null;
}

export interface RealtorHomeSellerEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  notes: EndCustomerNote[];
  /** אל תתקשר אליי */
  dont_call_me?: boolean;
  number_of_matches: number;
  /** לקוח VIP */
  is_vip?: boolean;
  /**
   * תאריך הרשמה
   * @format date
   */
  registration_date: string;
  dont_call_me_consent: boolean;
}

export interface RealtorLeadEndCustomer {
  id: number;
  /**
   * שם
   * @maxLength 50
   */
  name?: string | null;
  /**
   * טלפון
   * @maxLength 128
   */
  phone: string;
  /** אל תתקשר אליי */
  dont_call_me?: boolean;
  dont_call_me_consent: boolean;
}

export interface RealtorPatch {
  /**
   * טיקטוק
   * @format uri
   * @maxLength 200
   */
  tiktok?: string | null;
  /**
   * אתר עסקי
   * @format uri
   * @maxLength 200
   */
  webpage?: string | null;
  /**
   * לינקדאין
   * @format uri
   * @maxLength 200
   */
  linkedin?: string | null;
  /** אודות */
  about?: string | null;
  /**
   * כתובת דוא"ל
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * פייסבוק
   * @format uri
   * @maxLength 200
   */
  facebook?: string | null;
  /**
   * אינסטגרם
   * @format uri
   * @maxLength 200
   */
  instagram?: string | null;
  /** הצג נכסים בפרופיל */
  show_asset_numbers?: boolean;
  /** הצג קונים בפרופיל */
  show_buyer_numbers?: boolean;
  /** הצג עסקאות בפרופיל */
  show_deal_numbers?: boolean;
}

export interface RealtorProfile {
  id: number;
  agency: MinimalAgency;
  name: string;
  /**
   * מספר טלפון
   * @maxLength 128
   */
  phone: string;
  /**
   * לינקדאין
   * @format uri
   * @maxLength 200
   */
  linkedin?: string | null;
  activity_areas: City[];
  num_assets: number | null;
  num_buyers: number | null;
  num_deals: number | null;
  /**
   * כתובת דוא"ל
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  virtual_phone: string;
  /**
   * טיקטוק
   * @format uri
   * @maxLength 200
   */
  tiktok?: string | null;
  /**
   * אינסטגרם
   * @format uri
   * @maxLength 200
   */
  instagram?: string | null;
  /**
   * אתר עסקי
   * @format uri
   * @maxLength 200
   */
  webpage?: string | null;
  /** אודות */
  about?: string | null;
  /**
   * פייסבוק
   * @format uri
   * @maxLength 200
   */
  facebook?: string | null;
  image_url: string;
}

export interface RealtorSlim {
  id: number;
  name: string;
  /**
   * מספר טלפון
   * @maxLength 128
   */
  phone: string;
  image_url: string;
  agency: MinimalAgency;
}

export enum TaskStatusEnum {
  "AV" = "פעיל",
  "FD" = "הושלם",
}

export interface Reminder {
  id: number;
  /**
   * מתי לתזכר
   * @format date-time
   */
  reminder_time: string;
}

/**
 * * `IE` - מיידי
 * * `TS` - 3-6 חודשים
 * * `TM` - עד 10 חודשים
 * * `FX` - גמיש
 */
export enum RequestedEvictionRangeEnum {
  IE = "מיידי",
  TS = "3-6 חודשים",
  TM = "עד 10 חודשים",
  FX = "גמיש",
}

export enum RequestedEvictionRangeEnumValidation {
  IE = "IE",
  TS = "TS",
  TM = "TM",
  FX = "FX",
}

export interface SendAdvertiserEmail {
  message: string;
}

/**
 * * `AV` - פעיל
 * * `FD` - הושלם
 */
export enum StatusEnum {
  AV = "פעיל",
  FD = "הושלם",
}

export interface Street {
  id: number;
  /** שם */
  name: string;
}

export interface TokenObtainPair {
  phone: string;
  password: string;
  access: string;
  refresh: string;
}

export interface TokenRefresh {
  access: string;
  refresh: string;
}

/**
 * * `NO` - לא מוצג
 * * `ME` - גלוי לי
 * * `AG` - גלוי לסוכנות
 * * `AL` - גלוי ציבורי
 * * `NG` - במשא ומתן
 * * `IR` - לא רלוונטי
 */
export enum VisibilityStatusEnum {
  NO = "לא מוצג",
  ME = "גלוי לי",
  AG = "גלוי לסוכנות",
  AL = "גלוי ציבורי",
  NG = "במשא ומתן",
  IR = "לא רלוונטי",
}

export enum VisibilityStatusEnumValidation {
  NO = "NO",
  ME = "ME",
  AG = "AG",
  AL = "AL",
  NG = "NG",
  IR = "IR",
}

export enum ShareOptionsEnum {
  AL = "גלוי ציבורי",
  // HD = "מוסתר"
  ME = "גלוי לי",
  AG = "גלוי לסוכנות",
}
// 
export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title No title
 * @version 0.0.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  accessControl = {
    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlAgencyDetailsRetrieve
     * @request GET:/access-control/agency/details/
     * @secure
     */
    accessControlAgencyDetailsRetrieve: (params: RequestParams = {}) =>
      this.request<ReadOnlyAgency, any>({
        path: `/access-control/agency/details/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUserAllBuyersMatchesList
     * @request GET:/access-control/user/all-buyers-matches/
     * @secure
     */
    accessControlUserAllBuyersMatchesList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedRealtorAssetMatchesList, any>({
        path: `/access-control/user/all-buyers-matches/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersList
     * @request GET:/access-control/users/
     * @secure
     */
    accessControlUsersList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedRealtorProfileList, any>({
        path: `/access-control/users/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersRetrieve
     * @request GET:/access-control/users/{id}/
     * @secure
     */
    accessControlUsersRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<RealtorProfile, any>({
        path: `/access-control/users/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersPublicAssetsRetrieve
     * @request GET:/access-control/users/{id}/public_assets/
     * @secure
     */
    accessControlUsersPublicAssetsRetrieve: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PublicAsset, any>({
        path: `/access-control/users/${id}/public_assets/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersAddToRealtorBlacklistCreate
     * @request POST:/access-control/users/add_to_realtor_blacklist/
     * @secure
     */
    accessControlUsersAddToRealtorBlacklistCreate: (
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/access-control/users/add_to_realtor_blacklist/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersFinishedOnboardingCreate
     * @request POST:/access-control/users/finished_onboarding/
     * @secure
     */
    accessControlUsersFinishedOnboardingCreate: (
      data: RealtorProfile,
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/access-control/users/finished_onboarding/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersHomeRetrieve
     * @request GET:/access-control/users/home/
     * @secure
     */
    accessControlUsersHomeRetrieve: (params: RequestParams = {}) =>
      this.request<HomeRealtor, any>({
        path: `/access-control/users/home/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersRemoveFromRealtorBlacklistCreate
     * @request POST:/access-control/users/remove_from_realtor_blacklist/
     * @secure
     */
    accessControlUsersRemoveFromRealtorBlacklistCreate: (
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/access-control/users/remove_from_realtor_blacklist/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersSetImageUpdate
     * @request PUT:/access-control/users/set_image/
     * @secure
     */
    accessControlUsersSetImageUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/access-control/users/set_image/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersUpdateInfoPartialUpdate
     * @request PATCH:/access-control/users/update_info/
     * @secure
     */
    accessControlUsersUpdateInfoPartialUpdate: (
      data: PatchedRealtorPatch,
      params: RequestParams = {}
    ) =>
      this.request<RealtorPatch, any>({
        path: `/access-control/users/update_info/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersWithSameActivityAreasRetrieve
     * @request GET:/access-control/users/with_same_activity_areas/
     * @secure
     */
    accessControlUsersWithSameActivityAreasRetrieve: (
      params: RequestParams = {}
    ) =>
      this.request<RealtorProfile, any>({
        path: `/access-control/users/with_same_activity_areas/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags access-control
     * @name AccessControlUsersWithSameActivityAreasAndActiveCustomersRetrieve
     * @request GET:/access-control/users/with_same_activity_areas_and_active_customers/
     * @secure
     */
    accessControlUsersWithSameActivityAreasAndActiveCustomersRetrieve: (
      params: RequestParams = {}
    ) =>
      this.request<RealtorProfile, any>({
        path: `/access-control/users/with_same_activity_areas_and_active_customers/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  advertisement = {
    /**
     * No description
     *
     * @tags advertisement
     * @name AdvertisementSendEmailToAdvertiserCreate
     * @request POST:/advertisement/{id}/send_email_to_advertiser/
     * @secure
     */
    advertisementSendEmailToAdvertiserCreate: (
      id: number,
      data: SendAdvertiserEmail,
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/advertisement/${id}/send_email_to_advertiser/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  apiTokenAuth = {
    /**
     * @description Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     *
     * @tags api-token-auth
     * @name ApiTokenAuthCreate
     * @request POST:/api-token-auth/
     */
    apiTokenAuthCreate: (data: TokenObtainPair, params: RequestParams = {}) =>
      this.request<TokenObtainPair, any>({
        path: `/api-token-auth/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  apiTokenRefresh = {
    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags api-token-refresh
     * @name ApiTokenRefreshCreate
     * @request POST:/api-token-refresh/
     */
    apiTokenRefreshCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/api-token-refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  api = {
    /**
     * @description Receive a notification that a call was received from service and send it to app
     *
     * @tags api
     * @name ApiCallMadeRetrieve
     * @request GET:/api/call_made/
     * @secure
     */
    apiCallMadeRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/call_made/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Receive a notification that a call was received from service and send it to app
     *
     * @tags api
     * @name ApiCallMadeCreate
     * @request POST:/api/call_made/
     * @secure
     */
    apiCallMadeCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/call_made/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags api
     * @name ApiSchemaRetrieve
     * @request GET:/api/schema/
     * @secure
     */
    apiSchemaRetrieve: (
      query?: {
        format?: "json" | "yaml";
        lang?:
          | "af"
          | "ar"
          | "ar-dz"
          | "ast"
          | "az"
          | "be"
          | "bg"
          | "bn"
          | "br"
          | "bs"
          | "ca"
          | "cs"
          | "cy"
          | "da"
          | "de"
          | "dsb"
          | "el"
          | "en"
          | "en-au"
          | "en-gb"
          | "eo"
          | "es"
          | "es-ar"
          | "es-co"
          | "es-mx"
          | "es-ni"
          | "es-ve"
          | "et"
          | "eu"
          | "fa"
          | "fi"
          | "fr"
          | "fy"
          | "ga"
          | "gd"
          | "gl"
          | "he"
          | "hi"
          | "hr"
          | "hsb"
          | "hu"
          | "hy"
          | "ia"
          | "id"
          | "ig"
          | "io"
          | "is"
          | "it"
          | "ja"
          | "ka"
          | "kab"
          | "kk"
          | "km"
          | "kn"
          | "ko"
          | "ky"
          | "lb"
          | "lt"
          | "lv"
          | "mk"
          | "ml"
          | "mn"
          | "mr"
          | "ms"
          | "my"
          | "nb"
          | "ne"
          | "nl"
          | "nn"
          | "os"
          | "pa"
          | "pl"
          | "pt"
          | "pt-br"
          | "ro"
          | "ru"
          | "sk"
          | "sl"
          | "sq"
          | "sr"
          | "sr-latn"
          | "sv"
          | "sw"
          | "ta"
          | "te"
          | "tg"
          | "th"
          | "tk"
          | "tr"
          | "tt"
          | "udm"
          | "uk"
          | "ur"
          | "uz"
          | "vi"
          | "zh-hans"
          | "zh-hant";
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, any>, any>({
        path: `/api/schema/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  documents = {
    /**
     * No description
     *
     * @tags documents
     * @name DocumentsDontCallMeConsentCreate
     * @request POST:/documents/dont-call-me-consent/
     * @secure
     */
    documentsDontCallMeConsentCreate: (
      data: CreateDontCallMeConsent,
      params: RequestParams = {}
    ) =>
      this.request<CreateDontCallMeConsent, any>({
        path: `/documents/dont-call-me-consent/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags documents
     * @name DocumentsDontCallMeConsentGiveConsentCreate
     * @request POST:/documents/dont-call-me-consent/{id}/give_consent/
     * @secure
     */
    documentsDontCallMeConsentGiveConsentCreate: (
      id: number,
      data: GiveConsentDontCallMeConsent,
      params: RequestParams = {}
    ) =>
      this.request<DontCallMeConsent, any>({
        path: `/documents/dont-call-me-consent/${id}/give_consent/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  gesherTasks = {
    /**
     * No description
     *
     * @tags gesher-tasks
     * @name GesherTasksCreate
     * @request POST:/gesher-tasks/
     * @secure
     */
    gesherTasksCreate: (data: CreateGesherTask, params: RequestParams = {}) =>
      this.request<CreateGesherTask, any>({
        path: `/gesher-tasks/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags notifications
     * @name NotificationsReadRetrieve
     * @request GET:/notifications/{id}/read/
     * @secure
     */
    notificationsReadRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<RealtorHomeNotifications, any>({
        path: `/notifications/${id}/read/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags notifications
     * @name NotificationsMarkAllReadRetrieve
     * @request GET:/notifications/mark_all_read/
     * @secure
     */
    notificationsMarkAllReadRetrieve: (params: RequestParams = {}) =>
      this.request<RealtorHomeNotifications, any>({
        path: `/notifications/mark_all_read/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  realEstate = {
    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetList
     * @request GET:/real-estate/asset/
     * @secure
     */
    realEstateAssetList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
        /**
         * סטטוס נראות
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         *
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         */
        visibility_status?: "AG" | "AL" | "IR" | "ME" | "NG" | "NO";
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAssetViewSetResponseSchemaList, any>({
        path: `/real-estate/asset/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetCreate
     * @request POST:/real-estate/asset/
     * @secure
     */
    realEstateAssetCreate: (data: CreateAsset, params: RequestParams = {}) =>
      this.request<CreateAsset, any>({
        path: `/real-estate/asset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetRetrieve
     * @request GET:/real-estate/asset/{id}/
     * @secure
     */
    realEstateAssetRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<AssetViewSetResponseSchema, any>({
        path: `/real-estate/asset/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetUpdate
     * @request PUT:/real-estate/asset/{id}/
     * @secure
     */
    realEstateAssetUpdate: (
      id: number,
      data: Asset,
      params: RequestParams = {}
    ) =>
      this.request<CreateAsset, any>({
        path: `/real-estate/asset/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetPartialUpdate
     * @request PATCH:/real-estate/asset/{id}/
     * @secure
     */
    realEstateAssetPartialUpdate: (
      id: number,
      data: PatchedListAsset,
      params: RequestParams = {}
    ) =>
      this.request<ListAsset, any>({
        path: `/real-estate/asset/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetDestroy
     * @request DELETE:/real-estate/asset/{id}/
     * @secure
     */
    realEstateAssetDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/real-estate/asset/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetDealMadeCreate
     * @request POST:/real-estate/asset/{id}/deal_made/
     * @secure
     */
    realEstateAssetDealMadeCreate: (
      id: number,
      data: CreateDealSerializerSchema,
      params: RequestParams = {}
    ) =>
      this.request<CreateDeal, any>({
        path: `/real-estate/asset/${id}/deal_made/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetGetMatchingPotentialBuyersRetrieve
     * @request GET:/real-estate/asset/{id}/get_matching_potential_buyers/
     * @secure
     */
    realEstateAssetGetMatchingPotentialBuyersRetrieve: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<PurchasingEndCustomer, any>({
        path: `/real-estate/asset/${id}/get_matching_potential_buyers/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetContactsCreate
     * @request POST:/real-estate/asset/{id}/set_contacts/
     * @secure
     */
    realEstateAssetSetContactsCreate: (
      id: number,
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<ListAsset, any>({
        path: `/real-estate/asset/${id}/set_contacts/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetContactsUpdate
     * @request PUT:/real-estate/asset/{id}/set_contacts/
     * @secure
     */
    realEstateAssetSetContactsUpdate: (
      id: number,
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<ListAsset, any>({
        path: `/real-estate/asset/${id}/set_contacts/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetImagesCreate
     * @request POST:/real-estate/asset/{id}/set_images/
     * @secure
     */
    realEstateAssetSetImagesCreate: (
      id: number,
      data: {
        /**
         * The first image will be set as default
         * @format binary
         */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/real-estate/asset/${id}/set_images/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetImagesUpdate
     * @request PUT:/real-estate/asset/{id}/set_images/
     * @secure
     */
    realEstateAssetSetImagesUpdate: (
      id: number,
      data: {
        /**
         * The first image will be set as default
         * @format binary
         */
        file?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/real-estate/asset/${id}/set_images/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetViewersCreate
     * @request POST:/real-estate/asset/{id}/set_viewers/
     * @secure
     */
    realEstateAssetSetViewersCreate: (
      id: number,
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<ListAsset, any>({
        path: `/real-estate/asset/${id}/set_viewers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetSetViewersUpdate
     * @request PUT:/real-estate/asset/{id}/set_viewers/
     * @secure
     */
    realEstateAssetSetViewersUpdate: (
      id: number,
      data: number[],
      params: RequestParams = {}
    ) =>
      this.request<ListAsset, any>({
        path: `/real-estate/asset/${id}/set_viewers/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetUpdateLastModifiedCreate
     * @request POST:/real-estate/asset/{id}/update_last_modified/
     * @secure
     */
    realEstateAssetUpdateLastModifiedCreate: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<boolean, any>({
        path: `/real-estate/asset/${id}/update_last_modified/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetsNewList
     * @request GET:/real-estate/assets/new/
     * @secure
     */
    realEstateAssetsNewList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
        /**
         * סטטוס נראות
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         *
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         */
        visibility_status?: "AG" | "AL" | "IR" | "ME" | "NG" | "NO";
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedListAssetList, any>({
        path: `/real-estate/assets/new/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateAssetsPriceUpdatedList
     * @request GET:/real-estate/assets/price_updated/
     * @secure
     */
    realEstateAssetsPriceUpdatedList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
        /**
         * סטטוס נראות
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         *
         * * `NO` - לא מוצג
         * * `ME` - גלוי לי
         * * `AG` - גלוי לסוכנות
         * * `AL` - גלוי ציבורי
         * * `NG` - במשא ומתן
         * * `IR` - לא רלוונטי
         */
        visibility_status?: "AG" | "AL" | "IR" | "ME" | "NG" | "NO";
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedListAssetList, any>({
        path: `/real-estate/assets/price_updated/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCitiesList
     * @request GET:/real-estate/cities/
     * @secure
     */
    realEstateCitiesList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedCityList, any>({
        path: `/real-estate/cities/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerCreate
     * @request POST:/real-estate/customer/
     * @secure
     */
    realEstateCustomerCreate: (data: EndCustomer, params: RequestParams = {}) =>
      this.request<EndCustomer, any>({
        path: `/real-estate/customer/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerPhoneCallAboutAssetCreate
     * @request POST:/real-estate/customer/{customer_id}/phone_call_about_asset/{asset_id}/
     * @secure
     */
    realEstateCustomerPhoneCallAboutAssetCreate: (
      assetId: string,
      customerId: string,
      params: RequestParams = {}
    ) =>
      this.request<CreateAssetInteractionSerializerForPhoneCall, any>({
        path: `/real-estate/customer/${customerId}/phone_call_about_asset/${assetId}/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerRetrieve
     * @request GET:/real-estate/customer/{id}/
     * @secure
     */
    realEstateCustomerRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<EndCustomer, any>({
        path: `/real-estate/customer/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerPartialUpdate
     * @request PATCH:/real-estate/customer/{id}/
     * @secure
     */
    realEstateCustomerPartialUpdate: (
      id: number,
      data: PatchedEndCustomer,
      params: RequestParams = {}
    ) =>
      this.request<EndCustomer, any>({
        path: `/real-estate/customer/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerAddAssetCreate
     * @request POST:/real-estate/customer/{id}/add_asset/
     * @secure
     */
    realEstateCustomerAddAssetCreate: (
      id: number,
      data: CreateAsset,
      params: RequestParams = {}
    ) =>
      this.request<CreateAsset, any>({
        path: `/real-estate/customer/${id}/add_asset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerAddNoteCreate
     * @request POST:/real-estate/customer/{id}/add_note/
     * @secure
     */
    realEstateCustomerAddNoteCreate: (
      id: number,
      data: EndCustomerNote,
      params: RequestParams = {}
    ) =>
      this.request<EndCustomerNote, any>({
        path: `/real-estate/customer/${id}/add_note/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerAddPurchaseListingCreate
     * @request POST:/real-estate/customer/{id}/add_purchase_listing/
     * @secure
     */
    realEstateCustomerAddPurchaseListingCreate: (
      id: number,
      data: PurchaseListing,
      params: RequestParams = {}
    ) =>
      this.request<PurchaseListingWithMatchingAssetsAndCustomer, any>({
        path: `/real-estate/customer/${id}/add_purchase_listing/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerArchiveCreate
     * @request POST:/real-estate/customer/{id}/archive/
     * @secure
     */
    realEstateCustomerArchiveCreate: (
      id: number,
      data: EndCustomer,
      params: RequestParams = {}
    ) =>
      this.request<EndCustomer, any>({
        path: `/real-estate/customer/${id}/archive/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerUnarchiveCreate
     * @request POST:/real-estate/customer/{id}/unarchive/
     * @secure
     */
    realEstateCustomerUnarchiveCreate: (
      id: number,
      data: EndCustomer,
      params: RequestParams = {}
    ) =>
      this.request<EndCustomer, any>({
        path: `/real-estate/customer/${id}/unarchive/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateCustomerPhoneCheckRetrieve
     * @request GET:/real-estate/customer/phone_check/{phone_number}/
     * @secure
     */
    realEstateCustomerPhoneCheckRetrieve: (
      phoneNumber: string,
      params: RequestParams = {}
    ) =>
      this.request<PhoneCheckEndCustomer, any>({
        path: `/real-estate/customer/phone_check/${phoneNumber}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateNeighborhoodsList
     * @request GET:/real-estate/neighborhoods/
     * @secure
     */
    realEstateNeighborhoodsList: (
      query?: {
        city?: number;
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedNeighborhoodList, any>({
        path: `/real-estate/neighborhoods/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePublicAssetRetrieve
     * @request GET:/real-estate/public-asset/{id}/
     * @secure
     */
    realEstatePublicAssetRetrieve: (
      id: number,
      query?: {
        customer_status?: "FA" | "IM" | "IN";
      },
      params: RequestParams = {}
    ) =>
      this.request<PublicAsset, any>({
        path: `/real-estate/public-asset/${id}/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingUpdate
     * @request PUT:/real-estate/purchase_listing/{id}/
     * @secure
     */
    realEstatePurchaseListingUpdate: (
      id: number,
      data: PurchaseListingUpdate,
      params: RequestParams = {}
    ) =>
      this.request<PurchaseListingUpdate, any>({
        path: `/real-estate/purchase_listing/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingPartialUpdate
     * @request PATCH:/real-estate/purchase_listing/{id}/
     * @secure
     */
    realEstatePurchaseListingPartialUpdate: (
      id: number,
      data: PatchedPurchaseListingUpdate,
      params: RequestParams = {}
    ) =>
      this.request<PurchaseListingUpdate, any>({
        path: `/real-estate/purchase_listing/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingDestroy
     * @request DELETE:/real-estate/purchase_listing/{id}/
     * @secure
     */
    realEstatePurchaseListingDestroy: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/real-estate/purchase_listing/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingArchiveCreate
     * @request POST:/real-estate/purchase_listing/{id}/archive/
     * @secure
     */
    realEstatePurchaseListingArchiveCreate: (
      id: number,
      data: any,
      params: RequestParams = {}
    ) =>
      this.request<PurchaseListingResponse, any>({
        path: `/real-estate/purchase_listing/${id}/archive/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingGetMatchesRetrieve
     * @request GET:/real-estate/purchase_listing/{id}/get_matches/
     * @secure
     */
    realEstatePurchaseListingGetMatchesRetrieve: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<AnnotatedAsset, any>({
        path: `/real-estate/purchase_listing/${id}/get_matches/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstatePurchaseListingSearchForMatchingAssetsCreate
     * @request POST:/real-estate/purchase_listing/search_for_matching_assets/
     * @secure
     */
    realEstatePurchaseListingSearchForMatchingAssetsCreate: (
      data: PurchaseListing,
      params: RequestParams = {}
    ) =>
      this.request<AnnotatedAsset, any>({
        path: `/real-estate/purchase_listing/search_for_matching_assets/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags real-estate
     * @name RealEstateStreetsList
     * @request GET:/real-estate/streets/
     * @secure
     */
    realEstateStreetsList: (
      query?: {
        city?: number;
        /** A page number within the paginated result set. */
        page?: number;
        /** A search term. */
        search?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedStreetList, any>({
        path: `/real-estate/streets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tasks = {
    /**
     * No description
     *
     * @tags tasks
     * @name TasksList
     * @request GET:/tasks/
     * @secure
     */
    tasksList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedDisplayTaskList, any>({
        path: `/tasks/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksCreate
     * @request POST:/tasks/
     * @secure
     */
    tasksCreate: (data: CreateTask, params: RequestParams = {}) =>
      this.request<CreateTask, any>({
        path: `/tasks/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksRetrieve
     * @request GET:/tasks/{id}/
     * @secure
     */
    tasksRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<DisplayTask, any>({
        path: `/tasks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksPartialUpdate
     * @request PATCH:/tasks/{id}/
     * @secure
     */
    tasksPartialUpdate: (
      id: number,
      data: PatchedCreateTask,
      params: RequestParams = {}
    ) =>
      this.request<CreateTask, any>({
        path: `/tasks/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksDestroy
     * @request DELETE:/tasks/{id}/
     * @secure
     */
    tasksDestroy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tasks/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksArchiveCreate
     * @request POST:/tasks/{id}/archive/
     * @secure
     */
    tasksArchiveCreate: (
      id: number,
      data: DisplayTask,
      params: RequestParams = {}
    ) =>
      this.request<DisplayTask, any>({
        path: `/tasks/${id}/archive/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name TasksMarkAsCompletedCreate
     * @request POST:/tasks/{id}/mark_as_completed/
     * @secure
     */
    tasksMarkAsCompletedCreate: (id: number, params: RequestParams = {}) =>
      this.request<DisplayTask, any>({
        path: `/tasks/${id}/mark_as_completed/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

export * from "./internalTypes"