import { newActionSuccessModalData, newActionSuccessModalStatus, setNewActionSuccessModalStatus } from "../../Store/Modals"
import { CloseModalIcon } from "./icons/CloseModal"
const NewActionSuccessModal = () => {
    return (
        <div
            class="c-modal action-modal"
            classList={{'open': newActionSuccessModalStatus()}}
        >
            <div
                class="c-modal--overlay"
                onClick={() => setNewActionSuccessModalStatus(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--action">
                    <div class="c-modal--head">
                        <button
                            class="btn--close"
                            type="button"
                            onClick={() => setNewActionSuccessModalStatus(false)}
                        >
                            <CloseModalIcon />
                        </button>
                        <p class="st-title">
                            {newActionSuccessModalData.title}
                        </p>
                    </div>
                    <div class="c-modal--success">
                        <p class="st-text">
                            {newActionSuccessModalData.text}
                        </p>
                    </div>
                    <div class="c-modal--actions">
                        <button
                            class="btn btn--blue"
                            type="submit"
                            onClick={() => {
                                setNewActionSuccessModalStatus(false)
                            }}
                        >
                            סיום
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewActionSuccessModal
