import { createEffect, Setter } from "solid-js"

interface IProps {
    setter?: Setter<number | string>;
}
const countSidebarWidth = () => {
    let sidebarWidth = 0
    const sidebar:HTMLElement | null = document.querySelector('aside.sidebar')
    if (sidebar) {
        sidebarWidth = sidebar.offsetWidth
        // createEffect(() => {
        //     props.setter(sidebarWidth)
        // }, [sidebar?.offsetWidth])
    }
    return sidebarWidth
}
export default countSidebarWidth