import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from "@capacitor/core";

export const logEvent = async (name: string, params?: {}) => {
  await FirebaseAnalytics.logEvent({
    name,
    params: params ? params : {},
  });
};

export const changeTitle = async (screenName: string) => {
  if (Capacitor.isNativePlatform()) {
    await FirebaseAnalytics.setScreenName({
      screenName,
      nameOverride: screenName,
    });
  }
  window.document.title = screenName;
};
