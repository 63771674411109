import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import PhoneInput from "../../Components/PhoneInput";
import { createEffect, onMount, Show } from "solid-js";
import Select from "../../Components/Select";
import Picker from "../../Components/Picker";
import { FlexRow } from "../../Components/Flex";
import { handleForm } from "./AddClient";
import {
  AssetTypeEnum,
  BuyerEndCustomerStatusEnum,
  EvictionRangeEnum,
} from "../../types";
import generateRange from "../../utilities/generateRange";
import CheckBox from "../../Components/CheckBox";
import { AddIcon } from "../AddAsset/Icons";
import MultiSelect from "../../Components/MultiSelect";
import { pricify, unpricify } from "../AddAsset/AssetInfo";
import Radio from "../../Components/Radio";
import AutoComplete from "../../Components/AutoComplete";
import { BuyerFormProps } from "./BuyerFormFields";
import NewSelect from "../../Components/NewSelect";

export const BuyerDesktopFields = (props: BuyerFormProps) => {
  const {
    fieldErrorHandler,
    customer,
    setCustomer,
    asset,
    setAsset,
    cityName,
    setCityName,
    cityQuery,
    newAsset,
    setNewAsset,
    neighborhoodName,
    setNeighborhoodName,
    currentNeighborhoodQuery,
    streetName,
    setStreetName,
    cities,
    streets,
    setReport,
    neighborhoodQuery,
    elevatorMode,
    additionalAttributes,
    setAdditionalAttributes,
  } = props;

  const inputStyle = "lg:m-0 w-[180px]";

  const shouldHigherForm = () => asset.is_current_asset_sold === "no";
  return (
    <div
      class="flex flex-wrap flex-col gap-4"
      classList={{
        "min-h-[1200px]": shouldHigherForm(),
        "lg:h-[800px]": !shouldHigherForm(),
        "lg:h-[400px]": shouldHigherForm(),
        "xl:h-[500px]": !shouldHigherForm(),
        "xl:min-h-[700px]": shouldHigherForm(),
      }}
    >
      <TextInput
        disabled={customer.id}
        name="name"
        error={fieldErrorHandler}
        label="שם מלא של הקונה"
        onChange={(e) => setCustomer({ name: e.currentTarget.value })}
        required
        value={customer.name}
        class={inputStyle}
      />
      {/* <TextInput
        disabled={location.state?.phone}
        error={fieldErrorHandler}
        onChange={(e) => setCustomer({ phone: e.currentTarget.value })}
        required
        name="phone"
        type="tel"
        label="טלפון"
        value={customer.phone}
        class={inputStyle}
      /> */}
      <PhoneInput
        name="phone"
        label="טלפון"
        onChange={(value:any) => setCustomer({ phone: value })}
        error={fieldErrorHandler}
        value={customer.phone}
        class={inputStyle}
        required={true}
      />
      <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={BuyerEndCustomerStatusEnum}
        label="סוג לקוח"
        value={asset.buyer_end_customer_status}
        name="buyer_end_customer_status"
        class={inputStyle}
        error={fieldErrorHandler}
        required
      />
      <Show when={asset.buyer_end_customer_status === "IM"}>
        <Select
          class={inputStyle}
          label="האם הנכס נמכר"
          name="is_current_asset_sold"
          value={asset.is_current_asset_sold}
          options={{ vip: "כן - לקוח vip", no: "לא" }}
          onChange={(name, value) => setAsset({ [name]: value })}
        />
        <Show when={asset.is_current_asset_sold === "no"}>
          {/* <Select
            options={EvictionRangeEnum}
            label="זמן פינוי מבוקש"
            name="eviction_range"
            class="w-11/12"
            value={newAsset.eviction_range}
            onChange={(name, value) => {
              setNewAsset({ [name]: value });
            }}
          /> */}
          <AutoComplete
            error={fieldErrorHandler}
            class={inputStyle}
            label="עיר נוכחית"
            name="current_city"
            value={cityName()}
            options={cityQuery.data}
            onSearch={(value) => {
              setCityName(value);
            }}
            onChange={(key, value) => {
              setCityName(value);
              setNewAsset({ current_city: key });
            }}
          />
          <AutoComplete
            error={fieldErrorHandler}
            city={cityName()}
            missingNeighborhood
            class={inputStyle}
            label="שכונה נוכחית"
            name="current_neighborhood"
            value={neighborhoodName()}
            options={currentNeighborhoodQuery.data}
            onSearch={(value) => {
              setNeighborhoodName(value);
            }}
            onChange={(key, value) => {
              setNeighborhoodName(value);
              setNewAsset({ neighborhood: key });
            }}
          />
          <AutoComplete
            error={fieldErrorHandler}
            class={inputStyle}
            name="current_asset_street"
            label="רחוב נוכחי"
            value={streetName()}
            options={streets}
            onSearch={(value) => {
              console.log(value);
              setStreetName(value);
            }}
            onChange={(key, value) => {
              setStreetName(value);
              setNewAsset({ current_asset_street: key });
            }}
          />
          <TextInput
            class={inputStyle}
            onChange={(e) => handleForm(e, setNewAsset)}
            name="current_asset_house_number"
            label="מספר "
            value={newAsset.current_asset_house_number}
            error={fieldErrorHandler}
          />
          <Select
            halfs
            name="number_of_rooms"
            onChange={(name, option) => setNewAsset({ [name]: String(option) })}
            label="חדרים"
            class={inputStyle}
            options={generateRange(10)}
            value={newAsset.number_of_rooms}
          />
          <TextInput
            onChange={(e) => {
              e.currentTarget.value = unpricify(e.currentTarget.value);
              handleForm(e, setNewAsset);
            }}
            name="price"
            label="מחיר משוער"
            value={pricify(newAsset.price)}
            class={inputStyle}
            error={fieldErrorHandler}
          />
          <div class="w-3/12 mb-6">
            <span class="text-xs">האם התחילו לפרסם את הנכס?</span>
            <div class="flex gap-8 p-2 pb-3">
              {" "}
              <Radio
                onChange={() => {
                  setNewAsset("is_asset_advertised", true);
                }}
                value={newAsset.is_asset_advertised}
                label="כן"
              />
              <Radio
                onChange={() => {
                  setNewAsset("is_asset_advertised", false);
                }}
                value={!newAsset.is_asset_advertised}
                label="לא"
              />
            </div>
          </div>
        </Show>
      </Show>
      <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={cities}
        label="בחר עיר"
        error={fieldErrorHandler}
        required
        value={asset?.city}
        name="city"
        class={inputStyle}
      />
      <MultiSelect
        class={inputStyle}
        header="בחר שכונה"
        error={fieldErrorHandler}
        customButton={
          <Button
            name="report"
            onClick={() => {
              setReport(true);
            }}
            short
            class="gap-2 md:text-xs"
          >
            לא מצאת את השכונה? דווח לנו <AddIcon class=" stroke-blue" />
          </Button>
        }
        onChange={(key, value: boolean) =>
          setAsset("neighborhoods", { [key]: value })
        }
        options={neighborhoodQuery.data}
        label="בחר שכונה"
        store={asset}
        required
        value={asset.neighborhoods}
        name="neighborhoods"
        showReport
      />
      <MultiSelect
        onChange={(name, value) => {
          setAsset('asset_types_list', {[name]: value})
      }}
        options={AssetTypeEnum}
        label="סוג נכס "
        required
        value={asset.asset_types_list}
        name="asset_types_list"
        error={fieldErrorHandler}
        class={inputStyle}
      />
      {/* <Select
        onChange={(name, value) => setAsset({ [name]: value })}
        options={AssetTypeEnum}
        label="סוג נכס "
        required
        value={asset.asset_type}
        name="asset_type"
        error={fieldErrorHandler}
        class={inputStyle}
      /> */}
      <Show when={additionalAttributes()}>
        <Show when={asset && asset.asset_type_list && !Object.keys(asset.asset_types_list).filter(key => asset.asset_types_list[key] && (key === "TF" || key === "PH")).length}>
        {/* <Show when={!asset && !asset.asset_type_list && !asset.asset_type_list.includes(['TF', 'PH'])}> */}
          <FlexRow class={inputStyle + " gap-4"}>
            <Select
              onChange={(name, value) => setAsset({ [name]: value })}
              options={generateRange(30, -1)}
              label="מקומה"
              value={asset.from_floor}
              name="from_floor"
              class="w-6/12 md:m-0 md:w-6/12"
            />
            <Select
              onChange={(name, value) => setAsset({ [name]: value })}
              options={generateRange(30, -1)}
              label="עד קומה"
              value={asset.to_floor}
              name="to_floor"
              class="w-6/12 md:m-0 md:w-6/12"
            />
          </FlexRow>
        </Show>
      </Show>

      <Show when={additionalAttributes()}>
        <Show when={asset && asset.asset_types_list && Object.keys(asset.asset_types_list).filter(key => asset.asset_types_list[key] && (key === "TF" || key === "PH")).length < 1}>
        {/* <Show when={!asset && !asset.asset_type_list && !asset.asset_type_list.includes(['TF', 'PH'])}> */}
          <FlexRow class={inputStyle + " flex-wrap gap-4 lg:mt-5 lg:mb-2"}>
            <CheckBox
              value={asset.not_first_floor}
              setValue={(value) => setAsset({ not_first_floor: value })}
              label={"לא קומה ראשונה"}
              textSize="text-sm"
            />{" "}
            <CheckBox
              value={asset.not_last_floor}
              setValue={(value) => setAsset({ not_last_floor: value })}
              label="לא קומה אחרונה"
              textSize="text-sm"
            />{" "}
          </FlexRow>
        </Show>
      </Show>
      <TextInput
        onChange={(e) => handleForm(e, setAsset)}
        label="שטח בנוי במ״ר"
        value={asset.asset_size}
        name="asset_size"
        class={inputStyle}
        error={fieldErrorHandler}
      />
      <Select
        options={EvictionRangeEnum}
        onChange={(name, value) => setAsset({ [name]: value })}
        label="מועד פינוי"
        value={asset.requested_eviction_range}
        name="requested_eviction_range"
        class={inputStyle}
      />
      <TextInput
        onChange={(e) => {
          e.target.value = unpricify(e.currentTarget.value);
          handleForm(e, setAsset);
        }}
        name="budget"
        label="תקציב הלקוח"
        error={fieldErrorHandler}
        required
        value={pricify(asset.budget)}
        class={inputStyle}
      />

      <div class={inputStyle}>
        <label class="w-full text-sm text-darkGrey">מעלית</label>
        <Picker
          class="md:w-full"
          name="elevator_mode"
          active={elevatorMode.mode}
          options={{
            hide: "חובה",
            "from floor": "חובה מקומה",
            show: "לא חובה",
          }}
          handleChange={(key) => {
            elevatorMode.mode = key;
          }}
        />
      </div>

      <Show when={elevatorMode.mode === "from floor"}>
        {" "}
        <Select
          onChange={(name, value) => setAsset({ [name]: Number(value) })}
          options={generateRange(4)}
          label="מעלית מקומה"
          value={asset.elevator_from_floor}
          name="elevator_from_floor"
          class={inputStyle}
        />
      </Show>

      <div class={inputStyle} classList={{ flex: true, "gap-4": true }}>
        <Select
          onChange={(name, value) => {
            setAsset({ [name]: value });
          }}
          options={generateRange(8, 0, true)}
          label="ממס׳ חדרים"
          halfs
          value={asset?.number_of_rooms || 0}
          name="number_of_rooms"
          class="w-6/12 md:m-0"
          error={fieldErrorHandler}
          required
        />
        <Select
          halfs
          onChange={(name, value) => setAsset({ [name]: value })}
          options={generateRange(8, 0, true)}
          label="עד מס׳ חדרים"
          value={asset?.number_of_rooms_max || 0}
          name="number_of_rooms_max"
          class="w-6/12 md:m-0"
        />
      </div>
      <Show when={asset && asset.asset_types_list && Object.keys(asset.asset_types_list).filter(key => asset.asset_types_list[key] && (key === "TF" || key === "PH" || key === "CE")).length}>
      {/* <Show when={asset && asset.asset_type_list && asset.asset_type_list.includes(['TF', 'PH', 'CE'])}> */}
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח מגרש"}
          error={fieldErrorHandler}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class={inputStyle}
        />
      </Show>
      <Show when={asset && asset.asset_types_list && Object.keys(asset.asset_types_list).filter(key => asset.asset_types_list[key] && (key === "RT")).length}>
      {/* <Show when={asset && asset.asset_type_list && asset.asset_type_list.includes(['RT'])}> */}
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח הגג "}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class={inputStyle}
          error={fieldErrorHandler}
        />
      </Show>
      <Show when={asset && asset.asset_types_list && Object.keys(asset.asset_types_list).filter(key => asset.asset_types_list[key] && (key === "GT")).length}>
      {/* <Show when={asset && asset.asset_type_list && asset.asset_type_list.includes(['GT'])}> */}
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          label={"שטח הגינה "}
          value={asset.asset_outside_size}
          name="asset_outside_size"
          class={inputStyle}
          error={fieldErrorHandler}
        />
      </Show>

      <Spacer height="32px" />
    </div>
  );
};
