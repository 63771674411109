import axios from "axios"
import { setToastr } from "../../globalState"
import { boolean } from "zod"
import { setShowOnBoarding } from "../../Store/OnBoarding"

export const updateOnBoardingStatus = async (bool: boolean) => {
    const request = await axios.patch('/access-control/users/update_profile/', {
        needs_onboarding: bool
    })
    .then(resp => {
        setShowOnBoarding(false)
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}