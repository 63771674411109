import { Setter } from "solid-js";
import { setNewReportModalAssetId, setNewReportModalStatus } from "../../Store/Modals";
import { showAsset } from "../../api/Assets/showStatus";

interface IProps {
    show: boolean;
    setShowStatus: Setter<boolean>;
    id: string | number;
}

const HideAssetCard = (props: IProps) => {
    return (
        <div
            class="hide_asset_card"
            classList={{'open': props.show}}
        >
            <div class="hide_asset_card--head">
                <p class="st-title">
                    המודעה הוסתרה
                </p>
                <button
                    class="btn--close"
                    type="button"
                    onClick={async (e) => {
                        e.preventDefault()
                        await showAsset(props.id)
                        props.setShowStatus(false)
                    }}
                >
                    ביטול
                </button>
            </div>
            <div class="hide_asset_card--info">
                <p class="st-text">
                    המודעה הוסתרה ולא תוצג לך שוב. הסתרת מודעות משפרת את חוויית השימוש שלך, ובאפשרותך גם לדווח על המודעה:
                </p>
            </div>
            <div
                class="hide_asset_card--report"
                onClick={() => {
                    setNewReportModalStatus(true)
                    setNewReportModalAssetId(props.id)
                }}
            >
                <span class="st-icon">
                    <ReportIcon />
                </span>
                <span class="st-info">
                    <span class="st-title">
                        דיווח נוסף על המודעה
                    </span>
                    <span class="st-text">
                        הדיווחים אנונימיים ועוזרים לשמור על מאגר נכסים מדויק ועדכני
                    </span>
                </span>
                <span class="st-arrow">
                    <ArrowIcon />
                </span>
            </div>
        </div>
    )
}
export default HideAssetCard

const ArrowIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 5.92969L6.92969 12L13 18.0703L14.5 16.5703L9.92969 12L14.5 7.42969L13 5.92969Z" fill="#234F68"/>
</svg>
)
const ReportIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9993 1.0293C11.4361 1.0293 10.8732 1.29174 10.5579 1.81641L1.76105 16.4512C1.11282 17.5292 1.9422 19 3.2005 19H20.7962C22.0541 19 22.8858 17.5292 22.2376 16.4512L13.4407 1.81641C13.1255 1.29174 12.5625 1.0293 11.9993 1.0293ZM11.9993 3.29883L20.2357 17H3.763L11.9993 3.29883ZM10.9993 7V12H12.9993V7H10.9993ZM10.9993 14V16H12.9993V14H10.9993Z" fill="#234F68"/>
</svg>
)