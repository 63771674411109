import { For, Index, createSignal, onCleanup, onMount } from "solid-js";
import Button from "./Button";
import Spacer from "./Spacer";
import dashboard from "../Components/Navbar/dashboard.svg";
import assets from "../Components/Navbar/assets.svg";
import tasks from "../Components/Navbar/tasks.svg";
import customers from "../Components/Navbar/customers.svg";
import { Link, useLocation, useNavigate } from "@solidjs/router";
import { isKeyboard, isSmall } from "../Pages/Home/isSmall";
import { ActiveGreenIcon } from "../Icons/ActiveGreenIcon";
import { mobileNavbarVisible, user } from "../globalState";
import ConnectTeamIcon from './Navbar/connectteam.svg'

export const BottomNavbar = () => {
  const location = useLocation();

  let lastScrollTop = 0; // holds the scrolling position
  const [show, setShow] = createSignal(true); // holds the opacity value

  const handleScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      // downscroll
      setShow(false); // fade out
    } else {
      // upscroll
      setShow(true); // fade in
    }
    lastScrollTop = st <= 0 ? 0 : st; // update the scroll position
  };

  // listens for scroll event
  window.addEventListener("scroll", handleScroll, false);

  return (
    <div
      classList={{
        hidden: isKeyboard() || !mobileNavbarVisible(),
        "translate-y-44": !show()
      }}
      class="fixed md:hidden z-[100] flex bg-white rounded-r-md rounded-l-md w-screen bottom-0 justify-around p-2 pt-8 shadow-blackShadow rounded-tl-3xl transition-all duration-500"
    >
      <div class="absolute transition-all w-11/12 gap-4 justify-around top-[-18px] flex">
        {" "}
        <Button icon short color="green">
          <Link activeClass="" href="/addClient/buyer">
            לקוח חדש
          </Link>
        </Button>
        <Button icon short color="green">
          <Link activeClass="" href="/addAsset">
            {" "}
            נכס חדש
          </Link>
        </Button>
        {/* <Button
          onClick={() => {
            navigate("/documents");
          }}
          icon
          short
          color="green"
        >
          מסמך חדש
        </Button> */}
      </div>

      <Index
        each={[
          {
            icon: dashboard,
            text: "דף הבית",
            active: location.pathname === "/dashboard",
            link: "/dashboard",
          },
          {
            icon: customers,
            text: "לקוחות",
            active: location.pathname === "/customers",
            link: "/customers",
          },
          {
            icon: assets,
            text: "נכסים",
            active: location.pathname === "/assets",
            link: "/assets?type=my assets",
          },
          {
            icon: ConnectTeamIcon,
            text: "ConnecTeam",
            active: location.pathname === "/connect-team",
            link: "/connect-team",
          },
          {
            icon: tasks,
            text: "משימות",
            active: location.pathname === "/tasks",
            link: "/tasks",
          },
          // {
          //   icon: configs,
          //   text: "הגדרות",
          //   active: location.pathname === "/settings",
          //   link: "/settings",
          // },
          // {
          //   icon: documents,
          //   text: "מסמכים",
          //   active: location.pathname === "/documents",
          //   link: "/documents",
          // },
        ]}
      >
        {(button) => {
          return (
            <button class="text-center bg-white border-none">
              <Link
                activeClass="bg-white"
                href={button().link}
                style="font-size: 12px"
              >
                <img src={button().icon} alt="icon" class="m-auto w-7 h-7" />
                <Spacer height="6px" />
                {button().text}
                <div
                  class="transition-all duration-300"
                  classList={{
                    "translate-y-0": button().active,
                    "translate-y-5": !button().active,
                  }}
                >
                  <ActiveGreenIcon />
                </div>
              </Link>
            </button>
          );
        }}
      </Index>
    </div>
  );
};
