import { Show } from "solid-js";
import Button from "../../Components/Button";
import Picker from "../../Components/Picker";
import TextInput from "../../Components/TextInput";
import CheckBox from "../../Components/CheckBox";
import Select from "../../Components/Select";
import MultiSelect from "../../Components/MultiSelect";
import { AddIcon } from "../AddAsset/Icons";
import { AssetTypeEnum, EvictionRangeEnum } from "../../types";
import generateRange from "../../utilities/generateRange";
import { handleForm } from "./AddClient";
import { pricify, unpricify } from "../AddAsset/AssetInfo";
import { createSignal } from "solid-js";
import { EditIcon, Pencil, PencilIcon } from "../../Icons";
import axios from "axios";

const FIELD_NAMES = {
  CITY: "city",
  NEIGHBOURHOOD: "neighborhoods",
  ASSET_TYPE: "asset_type",
  ASSET_TYPES_LIST: "asset_types_list",
  MIN_ROOMS: "number_of_rooms",
  MAX_ROOMS: "number_of_rooms_max",
  ELEVATOR_FROM_FLOOR: "elevator_from_floor",
  ASSET_SIZE: "asset_size",
  ASSET_OUTSIDE_SIZE: "asset_outside_size",
  FROM_FLOOR: "from_floor",
  TO_FLOOR: "to_floor",
  BUDGET: "budget",
  EVICTION_RANGE: "requested_eviction_range",
  PICKER: "picker",
  CHECKBOX: "checkbox",
};

const FieldWithEditButton = (props: {
  children: any;
  editField: any;
  setEditField: any;
  fieldName: any;
  fieldType: any;
  class: any;
}) => {
  return (
    <div class={props.class}>
      <div class={"relative w-full h-full"}>
        <Show when={props.editField() !== props.fieldName}>
          <div
            class="absolute left-5 top-10 cursor-pointer z-10"
            onClick={() => {
              props.setEditField(props.fieldName);

              if (props.fieldType === "select") {
                const field = document.getElementById(props.fieldName);
                if (field) {
                  field.click();
                }
              } else {
                const field = document.getElementsByName(props.fieldName)[0];
                if (field) {
                  field.focus();
                }
              }
            }}
          >
            <Pencil class="" active={true} />
          </div>
        </Show>
        {props.children}
      </div>
    </div>
  );
};

const CustomerFormDesktop = (props: {
  asset: any;
  setAsset: any;
  cities: any;
  setReport: any;
  neighborhoodQuery: any;
  searchAsset: any;
  elevatorMode: any;
  success: any;
  setSuccess: any;
  setPreferencesModal: any;
  setCustomerModal: any;
  defaultForm: any;
  newPurchaselist: any;
  submit: any;
  errors: any;
  setOpenedModal: any;
  showDelete: any;
}) => {
  const {
    asset,
    setAsset,
    cities,
    setReport,
    neighborhoodQuery,
    searchAsset,
    elevatorMode,
    success,
    setSuccess,
    setPreferencesModal,
    setCustomerModal,
    defaultForm,
    newPurchaselist,
    submit,
    errors,
    setOpenedModal,
    showDelete
  } = props;
  const [editField, setEditField] = createSignal<string | null>(null);

  const inputStyle = "md:m-0 w-[30%]  min-w-[150px] max-w-[240px]";
  
  



  return (
    <div class="pr-4 -mt-4 flex flex-col flex-wrap h-[75vh] min-h-[800px]">
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldName={FIELD_NAMES.CITY}
        fieldType={"select"}
      >
        <Select
          onChange={(name, value) => {
            if (asset.neighborhoods) {
              setAsset({ neighborhoods: {} });
            }
            setAsset({ [name]: value });
          }}
          options={cities()}
          label="עיר"
          nullable
          error={asset.errors.city}
          errorSetter={setAsset}
          value={asset.city}
          name="city"
          disabled={editField() !== FIELD_NAMES.CITY}
        />
      </FieldWithEditButton>
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldType="select"
        fieldName={FIELD_NAMES.NEIGHBOURHOOD}
      >
        <MultiSelect
          header="שכונה"
          disabled={editField() !== FIELD_NAMES.NEIGHBOURHOOD}
          customButton={
            <Button
              onClick={() => {
                setReport(true);
              }}
              short
              class="gap-2"
            >
              לא מצאת את השכונה? דווח לנו{" "}
              <AddIcon class="fill-white stroke-blue" />
            </Button>
          }
          onChange={(key, value: boolean) => {
            setAsset("neighborhoods", { [key]: value });
          }}
          options={neighborhoodQuery.data}
          label="בחר שכונה"
          errorSetter={setAsset}
          store={asset}
          value={asset.neighborhoods}
          error={asset.errors.neighborhoods}
          name="neighborhoods"
          showReport
        />
      </FieldWithEditButton>
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldType={"select"}
        fieldName={FIELD_NAMES.ASSET_TYPES_LIST}
      >
        <MultiSelect
          onChange={(name, value) => {
            setAsset('asset_types_list', {[name]: value})
          }}
          options={AssetTypeEnum}
          label="סוג נכס "
          required
          value={asset.asset_types_list}
          name="asset_types_list"
          // errorSetter={setAsset}
          // error={asset.errors.asset_types_list}
          class="w-full"
          disabled={editField() !== FIELD_NAMES.ASSET_TYPES_LIST}
        />
        {/* <Select
          onChange={(name, value) => setAsset({ [name]: value })}
          disabled={editField() !== FIELD_NAMES.ASSET_TYPE}
          options={AssetTypeEnum}
          label="סוג נכס"
          errorSetter={setAsset}
          nullable
          error={asset.errors.asset_type}
          value={asset.asset_type}
          name="asset_type"
        /> */}
      </FieldWithEditButton>
      {/* <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldType={"select"}
        fieldName={FIELD_NAMES.ASSET_TYPE}
      >
        <MultiSelect
          onChange={(name, value) => setAsset('asset_type', { [name]: value })}
          disabled={editField() !== FIELD_NAMES.ASSET_TYPE}
          options={AssetTypeEnum}
          label="סוג נכס"
          singular={false}
          store={asset}
          errorSetter={setAsset}
          error={asset.errors.asset_type}
          value={asset.asset_type}
          name="asset_type"
        />
      </FieldWithEditButton> */}
      <div class={inputStyle + " flex gap-1 justify-between"}>
        <FieldWithEditButton
          editField={editField}
          setEditField={setEditField}
          fieldType={"select"}
          fieldName={FIELD_NAMES.MIN_ROOMS}
          class="w-6/12"
        >
          <Select
            class="w-full"
            disabled={editField() !== FIELD_NAMES.MIN_ROOMS}
            onChange={(name, value) => {
              setAsset({ [name]: value });
            }}
            options={generateRange(8, 0, true)}
            label="ממס׳ חדרים"
            halfs
            error={asset.errors.number_of_rooms}
            value={asset.number_of_rooms}
            name="number_of_rooms"
            errorSetter={setAsset}
            nullable
          />
        </FieldWithEditButton>
        <FieldWithEditButton
          class="w-6/12"
          editField={editField}
          setEditField={setEditField}
          fieldType={"select"}
          fieldName={FIELD_NAMES.MAX_ROOMS}
        >
          <Select
            class="w-full"
            halfs
            disabled={editField() !== FIELD_NAMES.MAX_ROOMS}
            onChange={(name, value) => setAsset({ [name]: value })}
            options={generateRange(8, 0, true)}
            nullable
            label="עד מס׳ חדרים"
            error={asset.errors.number_of_rooms_max}
            value={asset.number_of_rooms_max}
            name="number_of_rooms_max"
            errorSetter={setAsset}
          />
        </FieldWithEditButton>
      </div>

      <Show
        when={asset.should_have_elevator && asset.elevator_from_floor !== null}
      >
        <FieldWithEditButton
          fieldType={"select"}
          class={inputStyle}
          editField={editField}
          setEditField={setEditField}
          fieldName={FIELD_NAMES.ELEVATOR_FROM_FLOOR}
        >
          <Select
            onChange={(name, value) => setAsset({ [name]: Number(value) })}
            disabled={editField() !== FIELD_NAMES.ELEVATOR_FROM_FLOOR}
            options={generateRange(4)}
            label="מעלית מקומה"
            nullable
            value={asset.elevator_from_floor}
            name="elevator_from_floor"
          />
        </FieldWithEditButton>
      </Show>
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldName={FIELD_NAMES.ASSET_SIZE}
        fieldType="text"
      >
        <TextInput
          onChange={(e) => handleForm(e, setAsset)}
          disabled={editField() !== FIELD_NAMES.ASSET_SIZE}
          label="שטח בנוי במ״ר"
          type="tel"
          value={Number(asset.asset_size)}
          name="asset_size"
          class="md:m-0"
        />
      </FieldWithEditButton>
      <Show when={["TF", "PH", "CE"].includes(asset.asset_type)}>
        <FieldWithEditButton
          editField={editField}
          class={inputStyle}
          setEditField={setEditField}
          fieldType={"text"}
          fieldName={FIELD_NAMES.ASSET_OUTSIDE_SIZE}
        >
          <TextInput
            onChange={(e) => handleForm(e, setAsset)}
            disabled={editField() !== FIELD_NAMES.ASSET_OUTSIDE_SIZE}
            label={"שטח מגרש"}
            value={asset.asset_outside_size}
            name="asset_outside_size"
            class="md:m-0"
          />
        </FieldWithEditButton>
      </Show>
      <Show when={["RT"].includes(asset.asset_type)}>
        <FieldWithEditButton
          class={inputStyle}
          editField={editField}
          fieldType={"text"}
          setEditField={setEditField}
          fieldName={FIELD_NAMES.ASSET_OUTSIDE_SIZE}
        >
          <TextInput
            onChange={(e) => handleForm(e, setAsset)}
            label={"שטח הגג "}
            value={asset.asset_outside_size}
            disabled={editField() !== FIELD_NAMES.ASSET_OUTSIDE_SIZE}
            name="asset_outside_size"
            class="md:m-0"
          />
        </FieldWithEditButton>
      </Show>
      <Show when={["GT"].includes(asset.asset_type)}>
        <FieldWithEditButton
          editField={editField}
          class={inputStyle}
          fieldType={"text"}
          setEditField={setEditField}
          fieldName={FIELD_NAMES.ASSET_OUTSIDE_SIZE}
        >
          <TextInput
            onChange={(e) => handleForm(e, setAsset)}
            disabled={editField() !== FIELD_NAMES.ASSET_OUTSIDE_SIZE}
            label={"שטח הגינה "}
            value={Number(asset.asset_outside_size)}
            name="asset_outside_size"
            class="md:m-0"
          />
        </FieldWithEditButton>
      </Show>

      <Show when={!["TF", "PH"].includes(asset.asset_type)}>
        <FieldWithEditButton
          editField={editField}
          fieldType={"select"}
          class={inputStyle}
          setEditField={setEditField}
          fieldName={FIELD_NAMES.FROM_FLOOR}
        >
          <Select
            onChange={(name, value) => setAsset({ [name]: value })}
            disabled={editField() !== FIELD_NAMES.FROM_FLOOR}
            options={generateRange(30, -1)}
            label="מקומה"
            value={asset.from_floor}
            name="from_floor"
            showGroundFloor
            nullable
          />
        </FieldWithEditButton>
        <FieldWithEditButton
          editField={editField}
          setEditField={setEditField}
          fieldType={"select"}
          class={inputStyle}
          fieldName={FIELD_NAMES.TO_FLOOR}
        >
          <Select
            onChange={(name, value) => setAsset({ [name]: value })}
            disabled={editField() !== FIELD_NAMES.TO_FLOOR}
            options={generateRange(30, -1)}
            label="עד קומה"
            nullable
            value={asset.to_floor}
            name="to_floor"
          />
        </FieldWithEditButton>
        <div class={inputStyle + " pt-7 pb-2"}>
          <CheckBox
            value={asset.not_first_floor}
            setValue={(value) => {
              setEditField(FIELD_NAMES.CHECKBOX);
              setAsset({ not_first_floor: value });
            }}
            label={"לא קומה ראשונה"}
          />{" "}
          <CheckBox
            value={asset.not_last_floor}
            setValue={(value) => {
              setEditField(FIELD_NAMES.CHECKBOX);
              setAsset({ not_last_floor: value });
            }}
            label="לא קומה אחרונה"
          />{" "}
        </div>
      </Show>
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldName={FIELD_NAMES.BUDGET}
      >
        <TextInput
          disabled={editField() !== FIELD_NAMES.BUDGET}
          onChange={(e) => {
            e.currentTarget.value = unpricify(e.currentTarget.value);
            handleForm(e, setAsset);
          }}
          name="budget"
          errorSetter={setAsset}
          label="תקציב"
          error={asset.errors.budget}
          value={pricify(asset.budget)}
          class="md:m-0"
        />
      </FieldWithEditButton>
      <FieldWithEditButton
        class={inputStyle}
        editField={editField}
        setEditField={setEditField}
        fieldType={"select"}
        fieldName={FIELD_NAMES.EVICTION_RANGE}
      >
        <Select
          options={EvictionRangeEnum}
          disabled={editField() !== FIELD_NAMES.EVICTION_RANGE}
          onChange={(name, value) => setAsset({ [name]: value })}
          label="מועד פינוי"
          errorSetter={setAsset}
          nullable
          value={asset.requested_eviction_range}
          error={asset.errors.requested_eviction_range}
          name="requested_eviction_range"
        />
      </FieldWithEditButton>

      <section class={inputStyle}>
        <div>
          <label class="w-full">חניה בטאבו</label>
          <Picker
            class="w-full"
            name="should_have_parking_in_registry"
            active={asset.should_have_parking_in_registry ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              setAsset({
                should_have_parking_in_registry: key === "show" ? true : false,
              });
            }}
          />
        </div>
        <div>
          <label class="w-full"> ממ״ד</label>
          <Picker
            class="w-full"
            name="should_have_in_house_shelter"
            active={asset.should_have_in_house_shelter ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              setAsset({
                should_have_in_house_shelter: key === "show" ? true : false,
              });
            }}
          />
        </div>
        <div>
          <label class="w-full">מרפסת שמש</label>
          <Picker
            class="w-full"
            name="should_have_sun_terrace"
            active={asset.should_have_sun_terrace ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              setAsset({
                should_have_sun_terrace: key === "show" ? true : false,
              });
            }}
          />
        </div>
        <div>
          <label class="w-full">מחסן</label>
          <Picker
            class="w-full"
            name="should_have_storage_space"
            active={asset.should_have_storage_space ? "show" : "hide"}
            options={{ show: "חובה", hide: "לא חובה" }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              setAsset({
                should_have_storage_space: key === "show" ? true : false,
              });
            }}
          />
        </div>
        <div>
          <label class="w-full">הצג מבנים מסוכנים</label>
          <Picker
            class="w-full"
            name="should_show_dangerous_buildings"
            active={asset.should_show_dangerous_buildings ? "show" : "hide"}
            options={{ show: "הצג", hide: "הסתר" }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              setAsset({
                should_show_dangerous_buildings: key === "show" ? true : false,
              });
            }}
          />
        </div>
        <div>
          <label class="w-2/12">מעלית</label>
          <Picker
            class="w-full"
            name="should_have_elevator"
            active={elevatorMode(
              asset.should_have_elevator,
              asset.elevator_from_floor
            )}
            options={{
              must: "חובה",
              needed_from_floor: "חובה מקומה",
              not_needed: "לא חובה",
            }}
            handleChange={(key) => {
              setEditField(FIELD_NAMES.PICKER);
              if (key === "not_needed") {
                setAsset({
                  should_have_elevator: false,
                  elevator_from_floor: null,
                });
              }
              if (key === "must") {
                setAsset({
                  should_have_elevator: true,
                  elevator_from_floor: null,
                });
              }
              if (key === "needed_from_floor") {
                setAsset({
                  should_have_elevator: true,
                  elevator_from_floor: 1,
                });
              }
            }}
          />
        </div>
        <Show when={!props.searchAsset}>
          <div>
            <div class="flex flex-col gap-2 mt-2">
              <Show when={props.showDelete && !newPurchaselist()}>
                <Button
                  border
                  fullWidth
                  onClick={() => {
                    setOpenedModal('delete_approval')
                  }}
                >
                  מחק את העיר
                </Button>
              </Show>
              <Button
                border
                fullWidth
                onClick={() => {
                  setPreferencesModal(true);
                }}
              >
                הצג נכסים רלוונטיים
              </Button>
              <Button
                disabled={success()}
                fullWidth
                color="green"
                onClick={async () => {
                  setEditField(null);
                  await submit();
                  if (errors()) {
                    return;
                  }
                  setSuccess(true);
                  setTimeout(() => {
                    setSuccess(false);
                    setAsset(defaultForm);
                  }, 5000);
                }}
              >
                {success()
                  ? "העדפות חיפוש עודכנו בהצלחה!"
                  : newPurchaselist()
                  ? "הוסף"
                  : "עדכן"}
              </Button>
            </div>
          </div>
        </Show>
      </section>

      <Show when={searchAsset}>
        <section class=" left-4 bottom-8 w-48 gap-4 flex flex-col">
          <Button
            border
            short
            fullWidth
            name="add client"
            onClick={() => {
              setCustomerModal(true);
            }}
          >
            <p class="flex gap-2 justify-center">
              <span>הוסף לקוח</span>
              <AddIcon class="mr-4" white />
            </p>
          </Button>
          <Button
            fullWidth
            short
            border
            onClick={() => {
              setPreferencesModal(true);
            }}
          >
            הצג נכסים רלוונטיים
          </Button>
        </section>
      </Show>
    </div>
  );
};

export default CustomerFormDesktop;
