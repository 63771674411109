import {
  Component,
  createEffect,
  createSignal,
  onMount,
  Show,
} from "solid-js";

import logo from "./Icons/logo.svg";
import Spacer from "./Components/Spacer";
import { BottomNavbar } from "./Components/BottomNavbar";
import { loggedIn, user } from "./globalState";

import { useLocation, useNavigate } from "@solidjs/router";
import { Capacitor } from "@capacitor/core";
import { SafeArea } from "capacitor-plugin-safe-area";
import { createMutable } from "solid-js/store";
import { SideBarDesktop } from "./SideBarDesktop";
import AppRoutes from "./AppRoutes";
import ToastrComponent from "./Components/Toastr/Toastr";
import './styles/sass/main.sass'
import posthog from "posthog-js";
import ConnectTeamOnBoarding from "./Pages/ConnectTeam/OnBoarding";
import AllModalsContainer from "./Components/Modals/AllModals";
import { SafeAreaDiv } from "./api/SafeAreaDiv";
import { initializeNotifications } from "./Hooks/initializeNotifications";
import { userAuth } from "./Store/Auth";
import { NewSidebar } from "./Components/Sidebar/Sidebar";
import { App as Application } from "@capacitor/app";
import { setupUrlSchemeHandler } from "./Hooks/setupUrlSchemeHandler";
declare global {
  const appVersion: string;
}
// App.js

var link:any = document.querySelector("link[rel~='icon']");
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.head.appendChild(link);
}
link.href = logo;

export const [callModal, setCallModal] = createSignal();

const App: Component = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  
  setupUrlSchemeHandler(Application)
  
  

  onMount(() => {
    initPostHog()
    enableZoomOnWeb();
    createSafeAreas();
  });
  createEffect(() => {
    if (userAuth() !== null) {
      initializeNotifications()
    }
  })




  const initPostHog = () => {
    if (import.meta.env.MODE !== 'development') {
      posthog.init(import.meta.env.VITE_POSTHOG_KEY)
    }
  }

  return (
    <div
      class="wrapper"
      id="mainview"
    >
      <div class="hidden lg:block fixed inset-0 h-2 bg-blue z-30 rounded-b-2xl"></div>
      <SafeAreaDiv position={"top"} />
      <div class="wrapper--container">
        <Show when={loggedIn() === true && userAuth() !== null}>
          <NewSidebar />
        </Show>
        <AppRoutes />
      </div>
      {/* <main class="lg:flex lg:w-screen">
        <SideBarDesktop />
        <AppRoutes />
      </main> */}

      <Show when={(user.name && !location.pathname.includes("/public")) && !location.pathname.includes("/public-agent") && !location.pathname.includes("/import")}>
        <Spacer height="120px" class="md:hidden" />
        <Show when={loggedIn() === true && userAuth() !== null}>
          <BottomNavbar />
        </Show>
      </Show>
      <SafeAreaDiv position={"bottom"} />
      <ToastrComponent />
      <ConnectTeamOnBoarding />
      <AllModalsContainer />
    </div>
  );
};

export default App;
const createSafeAreas = () =>
  SafeArea.getSafeAreaInsets().then(({ insets }) => {
    if (Capacitor.getPlatform() === "ios") {
      screenState.bottom = Math.round(insets.bottom / 2);
      screenState.top = Math.round(insets.top * 0.9);
    } else {
      screenState.bottom = 0;
      screenState.top = 0;
    }
  });

export const screenState = createMutable({ bottom: 0, top: 0 });



const enableZoomOnWeb = () => {
  if (Capacitor.getPlatform() === "web") {
    document
      .querySelector('meta[name="viewport"]')
      ?.setAttribute(
        "content",
        "viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.5"
      );
  }
};





