import { createEffect, createSignal, For, onCleanup, onMount, Setter, Show } from "solid-js";
import './css/Select.sass'
interface IProps {
    value: any;
    options: Array<{name: string, value: string | number}>;
    setValue: Setter<string | number> | Function;
}

const NewSelect = (props: IProps) => {
    const [open, setOpen] = createSignal<boolean>(false)
    const [value, setValue] = createSignal<string | null>(null)
    createEffect(() => {
        if (props.value && props.options.length) {
            const item = props.options.find(i => i.value == props.value)
            if (item) {
                setValue(item.name)
            }
        }
    }, [props.value])
    let container:any;
    const clickOutside = (event:any) => {
        const isClickInside = container.contains(event.target)
        if (!isClickInside) {
            setOpen(false)
        }
    }
    onMount(() => {
        document.addEventListener('click', clickOutside)
    })
    onCleanup(() => {
        document.removeEventListener('click', clickOutside)
    })
    return (
        <div
            class="select--container"
            classList={{'open': open()}}
            ref={container}
        >
            <div
                class="select--value"
                onClick={() => setOpen(!open())}
            >
                <span class="st-value">
                    {value()}
                </span>
                <span class="st-icon">
                    <ChevronDown />
                </span>
            </div>
            <div class="select--dropdown">
                <For each={props.options}>
                    {(item:any) => (
                        <span
                            class="select--item"
                            onClick={() => {
                                setOpen(false)
                                props.setValue(item.value)
                            }}
                        >
                            {item.name}
                            <Show when={props.value === item.value}>
                                <span class="icon">
                                    <CheckedIcon />
                                </span>
                            </Show>
                        </span>
                    )}
                </For>
            </div>
        </div>
    )
}
export default NewSelect
const ChevronDown = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0007 14.071L7.42969 9.5L5.92969 11L12.0007 17.071L18.0717 11L16.5717 9.5L12.0007 14.071Z" fill="#234F68"/>
</svg>
)

const CheckedIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3281 4.66699L5.99479 12.0003L3.32812 9.33366" stroke="#234F68" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)