import axios from "axios";
import {
  getAuth,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { HomeRealtor } from "../types";
import { setLoggedIn, setUser } from "../globalState";
import { Navigator } from "@solidjs/router";
import { getMessaging, getToken } from "firebase/messaging";
import { captureException } from "@sentry/capacitor";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { logEvent } from "../analytics";

let confirm:any;
export const submitPhone = async ({ phone } : any) => {
  logEvent('button_click', {
    click_type: 'הבא',
    button_type: 'login'
  })
  try {
    const auth = getAuth();

    const confirmation = await signInWithPhoneNumber(
      auth,
      phone,
      window.recaptchaVerifier
    );
    confirm = confirmation.verificationId;
    logEvent('login_begin', {
      phone_number: phone
    })
  } catch (err) {
    return { error: true };
  }
};

export const submitSMS = async ({
  smsCode,
  navigate,
  phone
}: {
  smsCode: string;
  navigate: Navigator;
  phone: string;
}) => {
  let creds:any;
  logEvent('login', {
    phone_number: phone
  })
  try {
    const auth = getAuth();
    const credential = PhoneAuthProvider.credential(confirm, smsCode);
    creds = await signInWithCredential(auth, credential).catch((i) => {
      return false;
    });
    if (!creds) {
      return { error: true };
    }
  } catch (err) {
    return { error: true };
  }

  if (creds) {
    //get user init data
    try {
      axios.defaults.headers.common.Authorization = creds.user.accessToken;
      const user = await axios.get("/access-control/users/home/?login=true");
      const userData: HomeRealtor = user.data;
      setUser(userData);
      navigate("/dashboard", { replace: true });
    } catch(err:any) {
      console.log(err)
      if (err.response.status === 403) {
        return {
          error: true,
          token: creds.user.accessToken
        }
      } else {
        console.log(err)
        return {
          error: true,
          errorCode: err.response.status
        }
      }
    }
  }
};

export const submitSMSRegistration = async ({smsCode, navigate, phone} : {smsCode: string; navigate: Navigator, phone: string}) => {
  let creds: any;
  logEvent('login', {
    phone_number: phone
  })
  try {
    const auth = getAuth();
    const credential = PhoneAuthProvider.credential(confirm, smsCode);
    creds = await signInWithCredential(auth, credential).catch((i) => {
      return false;
    })
    if (!creds) {
      return {error: true};
    }
  } catch(err) {
    // console.log(err)
    return {error: true};
  }
  // console.log(creds)
  if (creds) {
    const accessToken = creds.user.accessToken;
    try {
      const user = await axios.get('/access-control/users/home/?login=true', {
        headers: {
          Authorization: accessToken
        }
      })
      const userData: HomeRealtor = user.data
      setUser(userData);
      axios.defaults.headers.common.Authorization = accessToken;
      navigate("/dashboard", { replace: true });
      return {
        user_exist: true,
        error: false,
        accessToken: accessToken
      }
    } catch(err:any) {
      if (err.response.status === 403) {
        return {
          user_exist: false,
          accessToken: accessToken,
          error: false
        }
      } else {
        return {
          user_exist: false,
          accessToken: 'false',
          error: true,
          errorCode: err.response.status
        }
      }
    }
  }
}

export const submitSMSNative = async ({ smsCode, navigate, verification }: any) => {
  const credential = PhoneAuthProvider.credential(verification, smsCode);
  const auth = getAuth();
  const result:any = await signInWithCredential(auth, credential).catch((err) => {
    return { error: true };
  });
  if (result.error) {
    return { error: true };
  }
  if (result.user) {
    axios.defaults.headers.common.Authorization = result.user.accessToken;
    const user = await axios.get("/access-control/users/home/");
    const userData: HomeRealtor = user.data;
    setUser(userData);
    setLoggedIn(true)
    // navigate("/dashboard", { replace: true });
  }
};
