import { createSignal } from "solid-js"
import { datePickerModalStatus, setDatePickerModalStatus } from "../../Store/Modals"
import { CloseModalIcon } from "./icons/CloseModal"
import DatePicker, { PickerValue } from '@rnwonder/solid-date-picker';
import Calendar from "@rnwonder/solid-date-picker/calendar";

export const DatePickerModal = () => {
    const date_now = new Date()
    const [date, setDate] = createSignal<PickerValue>({
        value: {
            selected: `${date_now.getFullYear()}-${date_now.getMonth() + 1}-${date_now.getDate() - 1}T23:00:00.000Z`
        },
        label: ''
    })
    console.log(date())

    return (
        <div
            class="c-modal"
            classList={{'open': datePickerModalStatus()}}
        >
            <div
                class="c-modal--overlay"
                onClick={() => setDatePickerModalStatus(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--action">
                    <div class="c-modal--head">
                        <button
                            class="btn--close"
                            type="button"
                            onClick={() => setDatePickerModalStatus(false)}
                        >
                            <CloseModalIcon />
                        </button>
                        <p class="st-title">
                            בחירת תאריך ספציפי
                        </p>
                    </div>
                    <div class="c-modal--datepicker">
                    <Calendar
                        onChange={(value) => {
                            console.log('onChange', value)
                        }}
                        onValueChange={(value) => {
                            console.log('onValueChange', value)
                        }}
                    />
                        {/* <DatePicker
                            value={date}
                            setValue={setDate}
                        /> */}
                    </div>
                    <div class="c-modal--actions">
                        <button
                            class="btn btn--blue"
                            type="submit"
                            onClick={() => {
                                console.log('test')
                            }}
                        >
                            סיום
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}