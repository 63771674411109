
import { Slider, SliderProvider } from 'solid-slider'
import './Sass/OnBoarding.sass'
import { createEffect, createSignal, For, Show } from 'solid-js'

import { setShowOnBoarding, showOnBoarding } from '../../Store/OnBoarding'
import { updateOnBoardingStatus } from '../../api/ConnectTeam/OnBoardings'

const ConnectTeamOnBoarding = () => {
    const navigate = useNavigate()
    const [activeSlide, setActiveSlide] = createSignal(0)
    const [slider, setSlider]:any = createSignal()
    const [checkbox, setCheckbox] = createSignal(false)

    const sliderOptions = {
        loop: false,
        drag: false,
        created: (e:any) => {
            setSlider(e)
        },
        slideChanged: (e:any) => {
            setActiveSlide(e.track.details.abs)
        }
    }
    createEffect(() => {
        const body = document.querySelector('body') || null
        if (body) {
            if (showOnBoarding()) {
                body.classList.add('overflow')
            } else {
                body.classList.remove('overflow')
            }
        }
    })
    return (
        <div
            class="c-modal connect-onboarding"
            classList={{'open': showOnBoarding()}}
        >
            <div
                class="c-modal--overlay"
                onClick={() => setShowOnBoarding(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--content">
                    <div class="c-modal--close">
                        <button 
                            class="btn--close"
                            type="button"
                            onClick={() => setShowOnBoarding(false)}
                        >
                            <CloseIcon />   
                        </button>
                    </div>
                    <div class="modal--onboarding">
                        <SliderProvider>
                            <Slider options={sliderOptions}>
                                <For each={SliderList}>
                                    {(item) => (
                                        <div class="modal--onboarding__slider-item">
                                            <div class="modal--onboarding__image">
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div class="modal--onboarding__info">
                                                <p class="st-title">
                                                    {item.title}
                                                </p>
                                                <p class="st-info">
                                                    {item.text}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </For>
                            </Slider>
                            <div class="modal--onboarding__dots">
                                <For each={SliderList}>
                                    {(item, index) => (
                                        <span
                                            class="dot"
                                            classList={{'active': activeSlide() == index()}}
                                            onClick={async () => {
                                                await slider().moveToIdx(index())
                                            }}
                                        ></span>
                                    )}
                                </For>
                            </div>
                            <div class="modal--onboarding__actions">
                                <div class="checkbox--container">
                                    <input
                                        type="checkbox"
                                        class="input--checkbox"
                                        checked={checkbox()}
                                        onChange={(e:any) => {
                                            setCheckbox(e.currentTarget.checked)
                                        }}
                                    />
                                    <span class="checkbox-icon">
                                        <CheckIcon />
                                    </span>
                                    <span class="checkbox-title">
                                        אל תציג שוב
                                    </span>
                                </div>
                                <Show when={activeSlide() !== 0}>
                                    <button
                                        class="btn--prev"
                                        type="button"
                                        onClick={() => {
                                            slider().prev()
                                        }}
                                    >
                                        הקודם
                                    </button>
                                </Show>
                                <Show when={activeSlide() !== SliderList.length - 1}>
                                    <button
                                        class="btn--next"
                                        type="button"
                                        onClick={() => {
                                            slider().next()  
                                        }}
                                    >
                                        הבא
                                    </button>
                                </Show>
                                <Show when={activeSlide() === SliderList.length - 1}>
                                    <button
                                        class="btn--submit"
                                        type="button"
                                        onClick={() => {
                                            updateOnBoardingStatus(!checkbox())
                                            navigate(`/a/${user.uuid}`)
                                        }}
                                    >
                                        לפרופיל שלי
                                    </button>
                                </Show>
                            </div>
                        </SliderProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConnectTeamOnBoarding

import Image1 from './images/image-1.png'
import Image2 from './images/image-2.png'
import Image3 from './images/image-3.png'
import Image4 from './images/image-4.png'
import Image5 from './images/image-5.png'
import { useNavigate } from '@solidjs/router'
import { user } from '../../globalState'
const SliderList = [
    {
        title: 'חדש בגשר: ConnecTeam!',
        text: 'הכירו את המרחב החדש שלנו לניהול קשרים עסקיים ושיתופי פעולה. כאן תוכלו לקחת שליטה מלאה על הקשרים המקצועיים שלכם ולהפוך אותם להזדמנויות עסקיות. בואו נתחיל!',
        image: Image1,
    },
    {
        title: 'מה זה ConnecTeam?',
        text: 'ConnecTeam היא הפלטפורמה שלכם לבניית רשת עסקית חזקה. כאן תוכלו לנהל את הקשרים המקצועיים שלכם, לשתף מידע על נכסים ולמצוא הזדמנויות לשיתופי פעולה - הכל במקום אחד.',
        image: Image2
    },
    {
        title: 'איך זה עובד?',
        text: 'בחרו את אנשי הקשר העסקיים שלכם (קונקטים) והחליטו איזה מידע לשתף איתם. נהלו בקלות את הפרטיות של הנכסים והלקוחות שלכם. גלו הזדמנויות חדשות דרך הרשת המקצועית שתבנו כאן.',
        image: Image3
    },
    {
        title: 'הרחיבו את מעגל הקונקטים',
        text: 'ככל שהרשת שלכם גדולה יותר, כך גדלים הסיכויים להצלחה. הוסיפו קונקטים חדשים, צרו שיתופי פעולה מפתיעים, וגלו הזדמנויות שלא חשבתם עליהן. ConnecTeam מאפשר לכם להגדיל את מעגל ההשפעה שלכם.',
        image: Image4
    },
    {
        title: 'צרו פרופיל מרשים ובלתי נשכח!',
        text: 'הפרופיל שלכם הוא חלון הראווה המקצועי שלכם. צרו פרופיל אטרקטיבי שמשקף את הייחודיות שלכם. שתפו את ההישגים, המומחיות והחזון שלכם. זו ההזדמנות שלכם ליצור רושם ראשוני בלתי נשכח ולבנות מוניטין חזק בתחום!',
        image: Image5
    }
]


const CloseIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.13802 2.19531L2.19531 3.13802L7.05729 8L2.19531 12.862L3.13802 13.8047L8 8.94271L12.862 13.8047L13.8047 12.862L8.94271 8L13.8047 3.13802L12.862 2.19531L8 7.05729L3.13802 2.19531Z" fill="#234F68"/>
</svg>
)

const CheckIcon = () => (
<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4701 0.861278C11.7305 1.12163 11.7305 1.54374 11.4701 1.80409L4.13677 9.13742C3.87642 9.39777 3.45431 9.39777 3.19396 9.13742L0.527293 6.47075C0.266944 6.2104 0.266944 5.78829 0.527293 5.52794C0.787643 5.2676 1.20975 5.2676 1.4701 5.52794L3.66536 7.72321L10.5273 0.861278C10.7876 0.600928 11.2098 0.600928 11.4701 0.861278Z" fill="white"/>
</svg>
)