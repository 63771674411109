import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";

export const [newReportModalStatus, setNewReportModalStatus] = createSignal(false)
export const [newReportModalAssetId, setNewReportModalAssetId] = createSignal<null | string | number>(null)

export const [newActionSuccessModalStatus, setNewActionSuccessModalStatus] = createSignal(false)
export const [newActionSuccessModalData, setNewActionSuccessModalData] = createStore({
    title: '',
    text: ''
})


export const [newConfirmModalStatus, setNewConfirmModalStatus] = createSignal(false)
export const [newConfirmModalData, setNewConfirmModalData] = createStore({
    title: '',
    text: '',
    submitText: '',
    action: async () => void(0)
})



export const [scheduleMeetingModalStatus, setScheduleMeetingModalStatus] = createSignal(false)


export const [datePickerModalStatus, setDatePickerModalStatus] = createSignal(false)