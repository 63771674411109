import { For, Show, createEffect, createSignal, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { submitPhone, submitSMS, submitSMSNative } from "../../api/login";
import Button from "../../Components/Button";
import { FlexCol } from "../../Components/Flex";
import Heading, { Colors } from "../../Components/Heading";
import TextInput from "../../Components/TextInput";
import logo from "../../Icons/logo.svg";

import house from "./loginImage.png";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { Link, useNavigate } from "@solidjs/router";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
import { validate } from "./validate";
import bubble from "./bubble.png";
import { captureException, captureMessage } from "@sentry/capacitor";
import backgroundDesktop from "./BackgroundDesktop.png";
import CodeInput from "../../Components/CodeInput";
import { loggedIn, user } from "../../globalState";
import { isKeyboard, isMobileWithKeyboard, lessThan700 } from "../Home/isSmall";
import phoneValidation from "../../helpers/PhoneValidation";
import { userAuth } from "../../Store/Auth";

export const [unknownUser, setUnknowUser] = createSignal(false);
const Login = () => {
  onMount(() => {
    FirebaseAuthentication.addListener("phoneCodeSent", (e) => {
      setVerificationId(e.verificationId);
      setStep("2fa");
    });
    FirebaseAuthentication.addListener(
      "phoneVerificationCompleted",
      async (event) => {
        console.log(event.result.user);
      }
    );
    FirebaseAuthentication.addListener(
      "phoneVerificationFailed",
      async (event) => {
        setError("בעיה באימות המכשיר - נא לפנות לתמיכה");
        captureMessage("phoneVerificationFailed");
      }
    );
  });
  const [form, setForm] = createStore({
    phone: "",
    smsCode: "",
    "smsCode-1": "",
    "smsCode-2": "",
    "smsCode-3": "",
    "smsCode-4": "",
    "smsCode-5": "",
    "smsCode-6": "",
    errors: { phone: false, smsCode: false },
  });
  const [error, setError] = createSignal("");
  const [step, setStep] = createSignal("phone");
  createEffect((prevEffect) => {
    if (step() === "2fa") {
      const element = document.getElementById("smsCode-1");

      if (element) {
        element.focus();
      }
    }
  });
  const navigate = useNavigate();
  const [verification, setVerificationId] = createSignal();
  const codeRefs = [null, null, null, null, null, null];

  createEffect(() => {
    if (userAuth() !== null) {
      if (loggedIn() === true) {
        console.log('login redirect')
        navigate("/dashboard");
      }
    }
  });
  return (
    <FlexCol class="pt-8 md:pt-0">
      <div class="p-4">
        <img
          src={backgroundDesktop}
          class="md:block z-[-1]  fixed left-0 top-14 md:top-0 bottom-0"
        />

        <div class="hidden md:block fixed right-0 top-0 bottom-0 bg-white w-6/12"></div>
        <form
          class="w-full h-full md:w-6/12 z-50 right-0 top-1/4 flex flex-col md:fixed justify-center md:justify-start items-center gap-4"
          onsubmit={(e) => e.preventDefault()}
        >
          <img class="m-0" src={logo} width="48px" />
          <Show when={step() === "2fa"}>
            <Heading class="text-xl" bold>
              הזן את קוד האימות{" "}
            </Heading>
          </Show>

          <img
            class="m-2 md:fixed w-8/12 md:w-80 md:left-[12%] md:top-1/3"
            src={house}
          />
          <Show when={step() === "phone"}>
            <Heading class="text-xl" bold>
              התחברות.
            </Heading>
          </Show>
          <Show
            when={step() === "phone"}
            fallback={
              <Heading class="text-sm w-8/12" color={Colors.secondary}>
                קוד האימות נשלח אליך ברגעים אלה דרך <strong> הודעת sms </strong>{" "}
                למספר - {form.phone.slice(7, 11)}
                ****-
                {form.phone.slice(0, 3)}
              </Heading>
            }
          >
            <Heading class="text-sm" color={Colors.secondary}>
              ברוך הבא לגשר מתווכים <br /> הזן את פרטיך על מנת להתחבר למערכת
            </Heading>
          </Show>
          <Show when={step() === "phone"}>
            <TextInput
              numeric
              value={form.phone}
              class="w-8/12 md:mt-8 md:w-5/12 m-0 mb-4"
              type="number"
              placeholder="הזן מספר טלפון"
              name="phone"
              iconComponent={<UsernameIcon />}
              label="מספר טלפון"
              error={form.errors.phone}
              onChange={(e) => {
                if (!Number(e.target.value)) {
                  e.preventDefault();
                  return;
                }
                setForm({ [e.currentTarget.name]: e.currentTarget.value });
              }}
              onBlur={(e) => {
                validations.phone(
                  e.currentTarget.value,
                  setForm,
                  e.currentTarget.name
                );
              }}
            />
            <div class="text-error text-center h-4">{error() || " "}</div>

            <Show when={!isMobileWithKeyboard()}>
              <Button
                color="green"
                class={`w-8/12 md:w-48 md:m-0 font-bold ${
                  !lessThan700() && "fixed"
                } bottom-12 shortButton`}
                id="sign-in-button"
                onClick={async () => {
                  if (form.errors.phone) {
                    return;
                  }
                  const phone = "+972" + form.phone.slice(1);
                  const auth = getAuth();
                  if (Capacitor.getPlatform() === "web") {
                    // auth.settings.appVerificationDisabledForTesting = true;
                    window.recaptchaVerifier = new RecaptchaVerifier(
                      "sign-in-button",
                      {
                        size: "invisible",
                        callback: (response: any) => {
                          // reCAPTCHA solved, allow signInWithPhoneNumber.
                        },
                      },
                      auth
                    );
                    // const phone = "+972" + form.phone.slice(1);
                    // const phone = "+" + form.phone;
                    const phone = phoneValidation(form.phone);
                    const error = await submitPhone({ phone });
                    console.log(error);
                    if (error) {
                      setError("שגיאה בהזנת טלפון");
                      return;
                    }
                    setStep("2fa");
                  } else {
                    const res =
                      await FirebaseAuthentication.signInWithPhoneNumber({
                        phoneNumber: phone,
                      });
                  }
                }}
              >
                {" "}
                הבא
              </Button>
            </Show>
          </Show>
          <Show when={step() === "2fa"}>
            <div
              class="flex flex-row-reverse md:gap-6"
              onpaste={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const clipboardData = e.clipboardData || window.clipboardData;
                const pastedData = clipboardData.getData("Text");
                [...pastedData].forEach((char, index) => {
                  setForm({
                    ["smsCode-" + (index + 1)]: char,
                  });
                });
              }}
            >
              <For each={["1", "2", "3", "4", "5", "6"]}>
                {(item) => (
                  <CodeInput
                    onkeydown={(e) => {
                      if (e.key === "Meta" || e.key === "v") {
                        return;
                      }

                      if (e.key === "Backspace") {
                        codeRefs[item].value = "";
                        const element = document.getElementById(
                          "smsCode-" + (Number(item) - 1)
                        );

                        if (element) {
                          element.focus();
                        }
                      }
                      if (e.key !== "0" && !Number(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      if (e.currentTarget.value.length === 6) {
                        [...e.currentTarget.value].forEach((char, index) => {
                          setForm({
                            ["smsCode-" + (index + 1)]: char,
                          });
                        });
                        const element = document.getElementById("smsCode-6");

                        if (element) {
                          element.focus();
                        }
                        return;
                      }

                      setForm({
                        ["smsCode-" + item]: e.currentTarget.value,
                      });
                      const element = document.getElementById(
                        "smsCode-" + (Number(item) + 1)
                      );

                      if (element) {
                        element.focus();
                      }
                    }}
                    value={form["smsCode-" + item]}
                    class="w-[15%] md:w-14 px-0 py-0 md:m-0 mb-4"
                    name={"smsCode-" + item}
                    ref={codeRefs[item]}
                    error={form.errors.smsCode}
                    onBlur={(e) => {
                      if (!e.currentTarget.value) {
                        setForm("errors", { smsCode: "אין קוד" });
                      } else {
                        setForm("errors", { smsCode: null });
                      }
                    }}
                  />
                )}
              </For>
            </div>
            <div class="text-error text-center h-4">
              {unknownUser() && "משתמש לא רשום במערכת"} {error() || " "}
            </div>

            <Show when={!isMobileWithKeyboard()}>
              <Button
                color="green"
                id="submit"
                disabled={form["smsCode-6"] !== 0 && !form["smsCode-6"]}
                class={`w-8/12 md:w-48 md:m-0 font-bold ${
                  !lessThan700() && "fixed"
                } bottom-12`}
                onClick={async () => {
                  if (form.errors.smsCode) {
                    return;
                  }
                  let code = `${form["smsCode-1"]}${form["smsCode-2"]}${form["smsCode-3"]}${form["smsCode-4"]}${form["smsCode-5"]}${form["smsCode-6"]}`;

                  if (Capacitor.getPlatform() === "web") {
                    const res = await submitSMS({
                      smsCode: code,
                      navigate,
                      phone: form.phone
                    });
                    // off registration for production START
                    if (res?.error && res?.token) {
                      navigate('/registration', {
                        state: {
                          step: 'user_data',
                          phone: phoneValidation(form.phone),
                          token: res.token
                        }
                      })
                    } else if (res?.error && res?.errorCode == 418) {
                      setError('אתה לא רשאי להתחבר לגשר- לפרטים נוספים, אנא פנה לתמיכה')
                      return;
                    } else if (res?.error) {
                      setError("שגיאה בהזנת קוד");
                      return;
                    }
                    // off registration for production END
                  } else {
                    const res = await submitSMSNative({
                      smsCode: code,
                      navigate,
                      verification: verification(),
                    });
                    console.log({ res });
                    if (res?.error) {
                      setError("שגיאה בהזנת קוד");
                      return;
                    }
                  }
                }}
              >
                התחברות
              </Button>
            </Show>
          </Show>
          <Show when={step() === "phone"}>
            <span class="text-darkGrey text-center">
              בלחיצה על כפתור הבא את/ה מסכימ/ה עם{" "}
              <Link href="/terms" class="underline text-blue">
                התקנון
              </Link>
            </span>
          </Show>
        </form>
      </div>
    </FlexCol>
  );
};

export default Login;

const validations = {
  phone: (input, setter, name) => {
    const phoneRegex = /^[0][5|7|3|4|9|2|6|8][0-9]{0,1}[-]{0,1}[0-9]{7}$/;
    validate({
      condition: !input || !phoneRegex.test(input),
      setter,
      name,
      description: "טלפון לא תקין",
    });
  },
};

const UsernameIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3017_22175)">
      <path
        d="M3.4375 4.125C2.30658 4.125 1.375 5.05658 1.375 6.1875V15.8125C1.375 16.9434 2.30658 17.875 3.4375 17.875H11.2162C11.8314 20.2393 13.9849 22 16.5349 22C19.5599 22 22.0349 19.525 22.0349 16.5C22.0349 15.0973 21.4974 13.8165 20.625 12.8423V6.1875C20.625 5.05658 19.6934 4.125 18.5625 4.125H3.4375ZM3.4375 5.5H18.5625C18.951 5.5 19.25 5.79904 19.25 6.1875V11.7264C18.8239 11.4824 18.3618 11.2966 17.875 11.1732V10.3125H12.375V11.6875H13.8829C12.1885 12.6275 11.0349 14.4349 11.0349 16.5H3.4375C3.04904 16.5 2.75 16.201 2.75 15.8125V6.1875C2.75 5.79904 3.04904 5.5 3.4375 5.5ZM7.5625 6.875C6.05183 6.875 4.8125 8.11433 4.8125 9.625C4.8125 10.417 5.16365 11.121 5.70544 11.6244C4.77711 12.2416 4.125 13.2457 4.125 14.4375H5.5C5.5 13.2904 6.41542 12.375 7.5625 12.375C8.70958 12.375 9.625 13.2904 9.625 14.4375H11C11 13.2457 10.3479 12.2416 9.41956 11.6244C9.96135 11.121 10.3125 10.417 10.3125 9.625C10.3125 8.11433 9.07317 6.875 7.5625 6.875ZM12.375 7.5625V8.9375H17.875V7.5625H12.375ZM7.5625 8.25C8.33021 8.25 8.9375 8.85729 8.9375 9.625C8.9375 10.3927 8.33021 11 7.5625 11C6.79479 11 6.1875 10.3927 6.1875 9.625C6.1875 8.85729 6.79479 8.25 7.5625 8.25ZM16.5349 12.375C18.8037 12.375 20.6599 14.2312 20.6599 16.5C20.6599 18.7688 18.8037 20.625 16.5349 20.625C14.2662 20.625 12.4099 18.7688 12.4099 16.5C12.4099 14.2312 14.2662 12.375 16.5349 12.375ZM18.5974 14.6443L16.3281 16.9122L14.7463 15.3318L13.7849 16.2932L16.3281 18.8378L19.5588 15.6057L18.5974 14.6443Z"
        fill="#234F68"
      />
    </g>
    <defs>
      <clipPath id="clip0_3017_22175">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
