import logo from "../../Icons/logo.svg";

import { user } from "../../globalState";
import { A, Link, useLocation, useNavigate, useParams } from "@solidjs/router";
import { screenState } from "../../App";
import { Match, Show, Switch, createEffect, createMemo, createSignal } from "solid-js";
import { isSmall } from "./isSmall";
import NotificationBell from "../../Icons/NotificationBell";
import { UserIcon } from "../AddClient/BuyerForm";
import { Capacitor } from "@capacitor/core";
import { NotificationsPage } from "../../NotificationsPage";
import AssetsSearch from "../Assets/Search/Main";
import { useMatch } from "@solidjs/router";
import './Sass/Nav.sass'
import { SettingsIcon } from "../../Components/Sidebar/icons/Icons";
interface IProps {
  classList?: string;
}

export function Nav(props: IProps) {
  // const matchAssets = useMatch(() => 'assets')
  // const matchSearch = useMatch(() => 'search-assets')
  const location = useLocation()
  const [fallback, setFallback] = createSignal(false);
  const [desktopNotifications, setNotifications] = createSignal(false);
  const [showSearchOnAssetsPage, setShowSearchOnAssetsPage] = createSignal(false)
  // createEffect(() => {
  //   if (location.search.includes('all%20assets')) {
  //     setShowSearchOnAssetsPage(true)
  //   } else {
  //     setShowSearchOnAssetsPage(false)
  //   }
  // })
  return (
    <nav
      class={`flex lg:bg-white justify-between px-4 lg:px-0 gap-8 relative items-center
        ${Capacitor.getPlatform() === "ios" ? "mt-12" : "pt-4"}
        ${props.classList}
      `}
    >
      <Show when={desktopNotifications()}>
        <NotificationsPage
          component
          closeComponent={() => setNotifications(false)}
        />
      </Show>
      <Show when={isSmall()}>
        <Link
          href={"/notifications"}
          aria-label="go to notifications"
          class="border-none relative pt-[6px] scale-95"
        >
          <NotificationBell />
          <div
            class="rounded-full flex items-center justify-center bg-green text-center w-[18px] h-[18px] absolute -top-0 -right-1  text-xs text-white"
            classList={{ "opacity-0": !user.notifications?.length }}
          >
            <strong>{user.notifications?.length}</strong>
          </div>
        </Link>

        <Link
          aria-label="go to home"
          activeClass=""
          class="pt-1 border-none"
          style={{
            position: 'absolute',
            top: '50%',
            // top: 'calc(50% + env(safe-area-inset-top))',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          href="/dashboard"
        >
          <img src={logo} width="25px" alt="gesher logo" />
        </Link>
        <A
          aria-label="go to settings"
          href={`/settings`}
          class="border-none relative"
          style={{
            height: '24px',
            width: '24px',
            'margin-right': 'auto',
            display: 'flex',
            "align-items": 'center',
            'justify-content': 'center'
          }}
        >
          <SettingsIcon />
        </A>
        <Link
          activeClass=""
          aria-label="go to profile"
          href={`/a/${user.uuid}`}
          class="border-none relative"
        >
          <Show
            when={!fallback() && user.image_url}
            fallback={<UserIcon height="33" width="33" />}
          >
            <img
              onerror={() => {
                setFallback(true);
              }}
              alt="user profile picture"
              src={user.image_url}
              width="44px"
              class="rounded-full h-8 w-8"
            />
          </Show>
        </Link>
      </Show>
      <Show when={!isSmall()}>
        <div class="flex justify-end gap-4 w-full items-center pl-2 pb-6">
          <Show when={location.pathname === '/public-profile'}>
              <div class="header--profile_back">
                <A href={`/a/${user.uuid}`} class="st-link">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 4.5L11.25 9L6.75 13.5" stroke="#234F68" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </A>
                <span class="st-title">
                  הפרופיל שלי
                </span>
              </div>
          </Show>
          <AssetsSearch />
          <button
            aria-label="go to notifictications"
            class="border-none relative"
            onclick={() => {
              setNotifications(true);
            }}
          >
            <NotificationBell />
            <div class="rounded-full bg-green w-5 h-5 absolute -top-2 -right-2 text-xs text-white">
              <div class="relative w-5 h-5">
                <p class="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 text-white">
                  <strong>{user.notifications?.length}</strong>
                </p>
              </div>
            </div>
          </button>
          <Link
            aria-label="go to profile page"
            activeClass=""
            href={`/a/${user.uuid}`}
            class="rounded-full relative h-8 w-8"
          >
            <img
              onerror={() => {
                setFallback(true);
              }}
              classList={{ "opacity-0": fallback() || !user.image_url }}
              src={user.image_url}
              alt="תמונת משתמש"
              class="rounded-full h-8 w-8"
            />
          </Link>
        </div>
      </Show>
    </nav>
  );
}


const settingsIcon = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.66602 2.83594L9.17578 5.35938C8.35161 5.67019 7.59479 6.10587 6.93164 6.65039L4.50781 5.81445L2.17188 9.85742L4.11328 11.5449C4.03865 12.0031 4 12.4278 4 12.8359C4 13.2447 4.03981 13.6686 4.11328 14.127V14.1289L2.17188 15.8164L4.50781 19.8574L6.92969 19.0234C7.5929 19.5683 8.35143 20.0015 9.17578 20.3125L9.66602 22.8359H14.334L14.8242 20.3125C15.6489 20.0015 16.4049 19.5665 17.0684 19.0215L19.4922 19.8574L21.8262 15.8164L19.8867 14.127C19.9614 13.6687 20 13.2441 20 12.8359C20 12.4284 19.9611 12.0043 19.8867 11.5469V11.5449L21.8281 9.85547L19.4922 5.81445L17.0703 6.64844C16.4071 6.10362 15.6486 5.67037 14.8242 5.35938L14.334 2.83594H9.66602ZM11.3145 4.83594H12.6855L13.0742 6.83594L14.1172 7.23047C14.7459 7.46741 15.3107 7.79269 15.8008 8.19531L16.6641 8.90234L18.5859 8.24219L19.2715 9.42773L17.7363 10.7637L17.9121 11.8633V11.8652C17.9733 12.2402 18 12.5547 18 12.8359C18 13.1172 17.9733 13.4317 17.9121 13.8066L17.7344 14.9062L19.2695 16.2422L18.584 17.4297L16.6641 16.7676L15.7988 17.4766C15.3087 17.8792 14.7459 18.2045 14.1172 18.4414H14.1152L13.0723 18.8359L12.6836 20.8359H11.3145L10.9258 18.8359L9.88281 18.4414C9.25415 18.2045 8.68933 17.8792 8.19922 17.4766L7.33594 16.7695L5.41406 17.4297L4.72852 16.2441L6.26562 14.9062L6.08789 13.8105V13.8086C6.02762 13.432 6 13.1166 6 12.8359C6 12.5547 6.02674 12.2402 6.08789 11.8652L6.26562 10.7656L4.72852 9.42969L5.41406 8.24219L7.33594 8.9043L8.19922 8.19531C8.68933 7.79269 9.25415 7.46741 9.88281 7.23047L10.9258 6.83594L11.3145 4.83594ZM12 8.83594C9.80348 8.83594 8 10.6394 8 12.8359C8 15.0325 9.80348 16.8359 12 16.8359C14.1965 16.8359 16 15.0325 16 12.8359C16 10.6394 14.1965 8.83594 12 8.83594ZM12 10.8359C13.1115 10.8359 14 11.7245 14 12.8359C14 13.9474 13.1115 14.8359 12 14.8359C10.8885 14.8359 10 13.9474 10 12.8359C10 11.7245 10.8885 10.8359 12 10.8359Z" fill="#0071B1"/>
</svg>
)