import axios from "axios"
import { setToastr } from "../../globalState"
import { newReportModalAssetId } from "../../Store/Modals"


export const hideAsset = async (id: string | number) => {
    const request = await axios.post('/access-control/users/hide_asset/', {
        asset_id: id
    })
        .catch(err => {
            setToastr({
                status: 'error',
                show: true,
                text: 'Server error'
            })
        })
    return request
}

export const showAsset = async (id: string | number) => {
    const request = await axios.post('/access-control/users/unhide_asset/', {
        asset_id: id
    })
        .catch(err => {
            setToastr({
                status: 'error',
                show: true,
                text: 'Server error'
            })
        })
    return request
}

export const reportAsset = async (reason: string | null, content: string) => {
    const asset_id = newReportModalAssetId()
    if (!asset_id) return false
    const request = await axios.post('/gesher-tasks/', {
        asset: asset_id,
        reason: reason,
        content: content
    })
        .catch(err => {
            setToastr({
                status: 'error',
                show: true,
                text: 'Server error'
            })
        })
    return request
}