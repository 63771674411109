import axios from "axios"
import { createSignal } from "solid-js"

export const checkWhatsupStatus = async () => {
    const request = await axios.get('whatsapp/client/')
        .then(resp => {
            if (resp.status == 200) {
                setWhatsupStatus(resp.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
}
interface IWhatsupStatus {
    state: string;
    count_dispatches_in_this_month: number;
    limit_count_dispatches_per_month: number;
}
export const [whatsupStatus, setWhatsupStatus] = createSignal<IWhatsupStatus>()

export const checkGoogleConnectionStatus = async () => {
    const request = await axios.get('/auth/s/google/status/')
        .then(resp => {
            if (resp.status == 200 && resp.data) {
                setGoogleStatus(resp.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
}
interface IGoogleStatus {
    is_google_connected: boolean;
    primary_account_id: null | string | number;
    primary_location_id: null | string | number;
}
export const [googleStatus, setGoogleStatus] = createSignal<IGoogleStatus>({
    is_google_connected: false,
    primary_account_id: null,
    primary_location_id: null
})

