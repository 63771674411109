import { createSignal, For } from "solid-js"
import { newReportModalStatus, setNewActionSuccessModalData, setNewActionSuccessModalStatus, setNewReportModalAssetId, setNewReportModalStatus } from "../../Store/Modals"
import { CloseModalIcon } from "./icons/CloseModal"
import { createStore } from "solid-js/store"
import { reportAsset } from "../../api/Assets/showStatus"

const NewReportModal = () => {

    const [form, setForm] = createStore({
        radio: null,
        text: ''
    })

    const [loading, setLoading] = createSignal(false)

    return (
        <div
            class="c-modal action-modal"
            classList={{'open': newReportModalStatus()}}
        >
            <div
                class="c-modal--overlay"
                onClick={() => {
                    setNewReportModalStatus(false)
                    setNewReportModalAssetId(null)
                }}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--action">
                    <div class="c-modal--head">
                        <button
                            class="btn--close"
                            type="button"
                            onClick={() => {
                                setNewReportModalStatus(false)
                                setNewReportModalAssetId(null)
                            }}
                        >
                            <CloseModalIcon />
                        </button>
                        <p class="st-title">
                            דיווח על המודעה
                        </p>
                        <button
                            class="btn--action"
                            type="button"
                            onClick={() => {}}
                        >
                            ביטול
                        </button>
                    </div>
                    <div class="c-modal--form">
                        <form>
                            <div class="modal--checkboxes">
                                <For each={RadioValues}>
                                    {(item:any) => (
                                        <div class="single-modal-radio">
                                            <input
                                                name="report-radio"
                                                type="radio" value={item.value}
                                                class="input-radio"
                                                onChange={() => {
                                                    setForm({'radio': item.value})
                                                }}
                                            />
                                            <span class="radio-value">
                                                {item.text}
                                            </span>
                                        </div>
                                    )}
                                </For>
                            </div>
                            <div class="single-modal-input">
                                <textarea
                                    placeholder="פרטים נוספים (אופציונאלי)"
                                    value={form.text}
                                    class="textarea"
                                    onkeyup={(e) => {
                                        setForm({text: e.currentTarget.value})
                                    }}
                                ></textarea>
                            </div>
                        </form>
                    </div>
                    <div class="c-modal--actions">
                        <button
                            class="btn btn--blue"
                            disabled={form.radio == null || loading()}
                            onClick={async () => {
                                setNewActionSuccessModalData({
                                    title: 'הדיווח נשלח בהצלחה!',
                                    text: 'תודה שהודעת לנו. אנו מעריכים את עזרתך בשמירה על מאגר נכסים מדויק ועדכני. הדיווח שלך יסייע לנו לבדוק ולטפל בבעיה בהקדם.'
                                })
                                setLoading(true)
                                await reportAsset(form.radio, form.text)
                                setNewReportModalStatus(false)
                                setNewReportModalAssetId(null)
                                setNewActionSuccessModalStatus(true)
                                setForm({
                                    radio: null,
                                    text: ''
                                })
                            }}
                        >
                            שליחת דיווח
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewReportModal



const RadioValues = [
    {
        value: 'Not relevant',
        text: 'הנכס לא רלוונטי'
    },
    {
        value: 'No collaboration',
        text: 'המתווך אינו מעונין בשיתופי פעולה'
    },
    {
        value: 'No exclusive',
        text: 'הנכס לא בבלעדיות'
    },
    {
        value: 'Duplicate',
        text: 'המודעה מופיעה פעמיים'
    }
];

