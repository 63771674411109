import { useNavigate } from '@solidjs/router';


export const setupUrlSchemeHandler = (App:any) => {
    const navigate = useNavigate();
    App.addListener('appUrlOpen', (event:any) => {
      if (event.url) {
        try {
          const path = event.url.replace('gesher://', '');
          navigate(path)
        } catch (error) {
          console.log('Error parsing URL:' + error); 
        }
      }
    });
  }