import Button from "../../Components/Button";
import Spacer from "../../Components/Spacer";
import TextInput from "../../Components/TextInput";
import { Match, Show, Switch, createEffect, createSignal } from "solid-js";
import Select from "../../Components/Select";
import {
  createMutable,
  createStore,
  SetStoreFunction,
  unwrap,
} from "solid-js/store";
import Picker from "../../Components/Picker";
import { FlexRow, FlexCol } from "../../Components/Flex";
import {
  Asset,
  AssetTypeEnum,
  BuyerEndCustomerStatusEnum,
  EvictionRangeEnum,
  PurchasingEndCustomer,
} from "../../types";
import generateRange from "../../utilities/generateRange";
import CheckBox from "../../Components/CheckBox";
import { setToastr, user } from "../../globalState";
import axios from "axios";
import { AddIcon } from "../AddAsset/Icons";
import { useLocation, useNavigate } from "@solidjs/router";
import getNeighborhoodsByName from "../../api/getNeighborhoodsByName";
import MultiSelect from "../../Components/MultiSelect";

import { ErrorBar } from "../AddAsset/ErrorBar";
import { pricify, unpricify } from "../AddAsset/AssetInfo";
import { GenericModal } from "../Assets/GenericModal";
import Radio from "../../Components/Radio";
import AutoComplete from "../../Components/AutoComplete";
import { getCitiesByName } from "../../api/getCitiesByName";
import getStreetsByName from "../../api/getStreetsByName";
import { getUserDetails } from "../../api/getUserDetails";
import { MissingNeighborhoodReportModal } from "./EditForm";
import { changeTitle, logEvent } from "../../analytics";
import { isSmall } from "../Home/isSmall";
import { z } from "zod";
import {
  basicEndCustomerSchema,
  purchaseListingSchema,
  purchasingEndCustomerSchema,
} from "../../zodSchemas";
import BuyerFormFields from "./BuyerFormFields";
import { Spinner } from "../../Components/LoadingScreen/Spinner";
import { Capacitor } from "@capacitor/core";
import ImportPhoneContact from "../../Components/Contacts/ImportContact";


export function BuyerForm() {
  changeTitle("הוספת נכס");
  type elevatorMode = "must" | "not must" | "from floor";

  const elevatorMode = createMutable<{ mode: elevatorMode }>({
    mode: "not must",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [additionalAttributes, setAdditionalAttributes] = createSignal(
    isSmall() ? false : true
  );
  const cities = user.activity_areas.reduce((acc, city) => {
    return { ...acc, [city.id]: city.name };
  }, {});
  const [report, setReport] = createSignal(false);
  const [streetName, setStreetName] = createSignal("");
  const [cityName, setCityName] = createSignal("");
  const [neighborhoodName, setNeighborhoodName] = createSignal("");
  const [loading, setLoading] = createSignal(false);
  const [newAsset, setNewAsset] = createStore({
    is_asset_advertised: false,
    current_city: null,
    price: 0,
    number_of_rooms: 0,
  });
  const currentNeighborhoodQuery = getNeighborhoodsByName(
    neighborhoodName,
    () => newAsset.current_city
  );
  const cityQuery = getCitiesByName(cityName);

  const customerSchema = purchasingEndCustomerSchema.partial();
  type TcustomerSchema = z.infer<typeof customerSchema>;
  const [customer, setCustomer] = createStore<TcustomerSchema>({
    name: "",
    phone: "",
    id: 0,
  });

  const errorsArray = createMutable<{ key: string | number; msg: string }[]>(
    []
  );

  const purchaseSchema = purchaseListingSchema.partial();
  type TpurchaseSchema = z.infer<typeof purchaseSchema>;

  const [asset, setAsset] = createStore<TpurchaseSchema>({});

  const [streets, setStreets] = createStore({});
  createEffect(() => {
    const data = getStreetsByName(streetName, () => newAsset.current_city);
    setStreets(data.data);
  });

  populateFieldsFromNavigation(location, setCustomer);

  createEffect(() => {
    if (!asset?.to_floor || !asset.from_floor) {
      return;
    }
    if (Number(asset.to_floor) < Number(asset.from_floor)) {
      setAsset({ to_floor: null });
    }
  });

  const [alreadyExistsModal, setAlreadyExistsModal] = createSignal(null);
  checkPhoneNumber(customer, setCustomer, setAlreadyExistsModal);

  const neighborhoodQuery = getNeighborhoodsByName(
    () => null,
    () => asset?.city
  );

  const submit = async () => {
    errorsArray.length = 0;

    const data = { ...asset };
    data.from_floor = Number(data.from_floor) || null;
    data.to_floor = Number(data.to_floor) || null;
    data.number_of_rooms = data.number_of_rooms?.toString();
    data.budget = unpricify(data.budget) || "0";
    data.should_have_elevator =
      elevatorMode.mode === "from floor" || elevatorMode.mode === "must";

    const validateCustomer = purchasingEndCustomerSchema.safeParse({
      ...customer,
      buyer_end_customer_status: asset.buyer_end_customer_status,
      phone: customer.phone.replaceAll("-", ""),
    });

    if (!validateCustomer.success) {
      setErrors(true);
      validateCustomer.error.errors.forEach((err) => {
        errorsArray.push({ key: err.path[0], msg: err.message });
      });
    }
    const validateListing = purchaseListingSchema.safeParse({ ...data });
    if (!validateListing.success) {
      setErrors(true);

      validateListing.error.errors.forEach((err) => {
        errorsArray.push({ key: err.path[0], msg: err.message });
      });
    }
    if (errorsArray.length) {
      window.scrollTo({ behavior: "smooth", top: 0 });
      return;
    }

    setErrors(false);

    let userId = customer.id;
    data.neighborhoods = Object.keys(data.neighborhoods).filter(
      (key) => data.neighborhoods[key]
    );

    if (!data.neighborhoods.length) {
      setErrors(true);
      errorsArray.push({ key: "neighborhoods", msg: "יש להזין שכונות" });
      window.scrollTo({ behavior: "smooth", top: 0 });

      return;
    }
    if (!userId) {
      const user = await axios.post("/real-estate/customer/", {
        name: customer.name,
        phone: customer.phone,
        buyer_end_customer_status: asset.buyer_end_customer_status,
        buyer_end_customer_activity_state: "AV",
        is_vip:
          asset.is_current_asset_sold === "vip" &&
          asset.buyer_end_customer_status === "IM",
      })
        .catch((err:any) => {
          if (err.response && err.response.data) {
            for (const key in err.response.data) {
              let message = err.response.data[key]
              if (key === 'phone') {
                message = 'מספר הטלפון שהזנת לא קיים ברשתות הסלולר'
              }
              errorsArray.push({
                key: key,
                msg: message
              })
            }
            setErrors(true)
            setLoading(false)
            return false
          }
        })
      userId = user.data.id;
    }
    if (userId) {
      await axios.patch(`/real-estate/customer/${userId}/`, {
        name: customer.name,
      });
    }
    let asset_types_list = []
    if (data.asset_types_list) {
      for (let key in data.asset_types_list) {
        if (data.asset_types_list[key] === true) {
          asset_types_list.push(key)
        }
      }
    }
    data.asset_types_list = asset_types_list
    await axios.post(
      `/real-estate/customer/${userId}/add_purchase_listing/`,
      data
    );

    if (newAsset.current_city) {
      await axios.post(`/real-estate/customer/${userId}/add_asset/`, {
        city: newAsset.current_city,
        visibility_status: "ME",
        price: newAsset.price,
        street: newAsset.current_asset_street,
        neighborhood: newAsset.neighborhood,
        number_of_rooms: newAsset.number_of_rooms,
        address_number: newAsset.current_asset_house_number,
        notes: newAsset.is_asset_advertised
          ? "נכס התחיל פרסום"
          : "נכס טרם התחיל פרסום",
      });
    }

    await getUserDetails();
    return userId;
  };

  const [errors, setErrors] = createSignal(false);

  const fieldErrorHandler = (name: string) =>
    errorsArray.some((i) => {
      return i.key === name;
    });

  createEffect(() => {
    console.log(streetName());
  });

  


  return (
    <div
      class="lg:flex relative py-4 lg:pt-10 lg:justify-between 2xl:justify-around lg:w-full lg:p-4 h-full overflow-auto"
    >
      <div class="md:absolute top-0 w-full">
        <ErrorBar hidden={!errors()} errors={errorsArray} form={asset} />
      </div>
      <Show when={report()}>
        <MissingNeighborhoodReportModal
          onClose={() => setReport(false)}
          city={cities[asset.city]}
        />
      </Show>
      <Show when={alreadyExistsModal()}>
        <AlreadyExistsModal
          hide={() => setAlreadyExistsModal(false)}
          asset={asset}
          setAsset={setAsset}
          preventCancel
          customer={customer}
        />
      </Show>
      <ImportPhoneContact
        setter={setCustomer}
        nameKey="name"
        phoneKey="phone"
      />

      <BuyerFormFields
        fieldErrorHandler={fieldErrorHandler}
        customer={customer}
        setCustomer={setCustomer}
        asset={asset}
        setAsset={setAsset}
        cityName={cityName}
        setCityName={setCityName}
        cityQuery={cityQuery}
        newAsset={newAsset}
        setNewAsset={setNewAsset}
        neighborhoodName={neighborhoodName}
        setNeighborhoodName={setNeighborhoodName}
        currentNeighborhoodQuery={currentNeighborhoodQuery}
        streetName={streetName}
        setStreetName={setStreetName}
        streets={streets}
        cities={cities}
        setReport={setReport}
        neighborhoodQuery={neighborhoodQuery}
        elevatorMode={elevatorMode}
        additionalAttributes={additionalAttributes}
        setAdditionalAttributes={setAdditionalAttributes}
      />
      <Show when={!isSmall()}>
        <div class="flex flex-col max-w-xs w-[35%] gap-4">
          <FlexRow justify="between" class="w-11/12">
            <label class="w-6/12">חניה בטאבו</label>
            <Picker
              name="should_have_parking_in_registry"
              active={asset.should_have_parking_in_registry ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_parking_in_registry:
                    key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow justify="between" class="w-11/12">
            <label class="w-6/12"> ממ״ד</label>
            <Picker
              name="should_have_in_house_shelter"
              active={asset.should_have_in_house_shelter ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_in_house_shelter: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow justify="between" class="w-11/12">
            <label class="w-6/12">מרפסת שמש</label>
            <Picker
              name="should_have_sun_terrace"
              active={asset.should_have_sun_terrace ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_sun_terrace: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow justify="between" class="w-11/12">
            <label class="w-6/12">מבנים מסוכנים</label>
            <Picker
              name="should_show_dangerous_buildings"
              active={asset.should_show_dangerous_buildings ? "show" : "hide"}
              options={{ show: "הצג", hide: "אל תציג" }}
              handleChange={(key) => {
                setAsset({
                  should_show_dangerous_buildings:
                    key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>
          <FlexRow justify="between" class="w-11/12">
            <label class="w-6/12">מחסן</label>
            <Picker
              name="should_have_storage_space"
              active={asset.should_have_storage_space ? "show" : "hide"}
              options={{ show: "חובה", hide: "לא חובה" }}
              handleChange={(key) => {
                setAsset({
                  should_have_storage_space: key === "show" ? true : false,
                });
              }}
            />
          </FlexRow>

          <div class="flex flex-col justify-between w-full gap-2">
            <Button
              fullWidth
              border={true}
              name="הוסף עיר נוספת"
              disabled={loading()}
              class="lg:m-0 lg:w-full"
              onClick={async () => {
                setLoading(true);
                const userId = await submit();
                if (errors()) {
                  setLoading(false);
                  return;
                }
                navigate("/customer/" + userId, {
                  state: { newEntry: true },
                  replace: true,
                });
              }}
            >
              הוסף עיר נוספת
              <AddIcon
                class="mr-4 border border-blue rounded-full scale-75"
                fillColor="white"
                pathColor="#234F68"
              />
            </Button>
            <Button
              disabled={loading()}
              name="שליחה"
              class="lg:w-full lg:m-0"
              color="green"
              onClick={async () => {
                setLoading(true);
                const userId = await submit();
                setLoading(false);
                if (errors()) {
                  return;
                }
                logEvent("form_submit", {
                  budget: asset.budget,
                  vip: asset && asset.is_current_asset_sold && asset.is_current_asset_sold ? 'Yes' : 'No',
                  customer_type: BuyerEndCustomerStatusEnum[asset.buyer_end_customer_status],
                });
                navigate("/customer/" + userId, {
                  replace: true,
                });
              }}
            >
              {" "}
              שמור והצג התאמות
            </Button>
            <Show when={loading()}>
              <div class="flex justify-center pt-2">
                <Spinner fill="darkBlue" />
              </div>{" "}
            </Show>
          </div>
        </div>
      </Show>
      <Show when={isSmall()}>
        <Button
          border
          disabled={loading()}
          name="הוסף עיר נוספת"
          onClick={async () => {
            setLoading(true);
            const userId = await submit();
            if (errors()) {
              setLoading(false);
              return;
            }
            navigate("/customer/" + userId, {
              state: { newEntry: true },
              replace: true,
            });
          }}
        >
          הוסף עיר נוספת
          <AddIcon class="mr-4" white />
        </Button>
        <Spacer class="lg:hidden" />
        <div class="w-5/12 m-auto">
          <Button
            name="אישור"
            disabled={loading()}
            short
            fullWidth
            color="green"
            onClick={async () => {
              setLoading(true);
              const userId = await submit();
              console.log(asset)
              if (errors()) {
                setLoading(false);
                return;
              }
              logEvent("form_submit", {
                // budget: asset.budget,
                customer_id: userId,
                customer_status: '',
                customer_type: BuyerEndCustomerStatusEnum[asset.buyer_end_customer_status],
                vip: asset && asset.is_current_asset_sold && asset.is_current_asset_sold ? 'Yes' : 'No'
              });
              navigate("/customer/" + userId, {
                replace: true,
              });
            }}
          >
            {" "}
            שמור והצג התאמות
          </Button>
          <Show when={loading()}>
            <div class="flex justify-center pt-2">
              <Spinner fill="darkBlue" />
            </div>{" "}
          </Show>
        </div>
      </Show>
      <Spacer class="lg:hidden" height="48px" />
    </div>
  );
}

const AlreadyExistsModal = (props: {
  id: number;
  hide: () => void;
  customer: PurchasingEndCustomer;
}) => {
  const navigate = useNavigate();
  return (
    <GenericModal
      icon={UserIcon}
      alt="customer exists"
      header="לקוח כבר קיים במערכת"
      subHeader="ניתן לערוך נתונים בדף הלקוח"
      options={[
        {
          color: "green",
          handler: async () => {
            if (props.customer.is_dormant) {
              await axios.post(
                `/real-estate/customer/${props.customer.id}/make_active/`
              );
            } else {
              await axios.post(
                `/real-estate/customer/${props.customer.id}/unarchive/`
              );
            }
            navigate("/customer/" + props.customer.id);
          },
          label: "הצג כרטיס לקוח",
        },
        {
          color: "green",
          handler: async () => {
            if (props.customer.is_dormant) {
              await axios.post(
                `/real-estate/customer/${props.customer.id}/make_active/`
              );
            } else {
              await axios.post(
                `/real-estate/customer/${props.customer.id}/unarchive/`
              );
            }
            props.hide();
            window.scrollTo({ behavior: "smooth", top: 0 });
          },
          label: "המשך בהוספת העדפות חיפוש",
        },
      ]}
    />
  );
};

export const UserIcon = (props: { width?: string; height?: string }) => (
  <svg
    width={props.width || "100"}
    height={props.height || "100"}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 87.5C68.1218 87.5 82.8125 71.4102 82.8125 51.5625C82.8125 31.7148 68.1218 15.625 50 15.625C31.8782 15.625 17.1875 31.7148 17.1875 51.5625C17.1875 71.4102 31.8782 87.5 50 87.5Z"
      fill="#8BC83F"
    />
    <path
      d="M19.5312 58.4102C23.4145 58.4102 26.5625 55.2622 26.5625 51.3789C26.5625 47.4957 23.4145 44.3477 19.5312 44.3477C15.648 44.3477 12.5 47.4957 12.5 51.3789C12.5 55.2622 15.648 58.4102 19.5312 58.4102Z"
      fill="#8BC83F"
    />
    <path
      d="M80.4688 58.4102C84.352 58.4102 87.5 55.2622 87.5 51.3789C87.5 47.4957 84.352 44.3477 80.4688 44.3477C76.5855 44.3477 73.4375 47.4957 73.4375 51.3789C73.4375 55.2622 76.5855 58.4102 80.4688 58.4102Z"
      fill="#8BC83F"
    />
    <path
      opacity="0.3"
      d="M50 100C66.396 100 79.6875 97.9013 79.6875 95.3125C79.6875 92.7237 66.396 90.625 50 90.625C33.604 90.625 20.3125 92.7237 20.3125 95.3125C20.3125 97.9013 33.604 100 50 100Z"
      fill="black"
    />
    <path
      d="M28.1846 31.2508C28.1846 31.2508 39.8268 34.3758 50.0127 34.3758C60.1986 34.3758 71.8408 31.2508 71.8408 31.2508V32.8352C71.8408 39.2945 76.1002 44.5633 82.1158 44.5602H82.1877C82.1939 34.2508 79.383 23.132 71.1658 15.8914C58.9846 5.15859 41.0439 5.15859 28.8611 15.8914C20.6439 23.132 17.8096 36.7305 17.8096 44.543C17.8455 44.543 17.8814 44.543 17.9174 44.543C23.9299 44.543 28.1861 39.3086 28.1861 32.8523V31.2508H28.1846Z"
      fill="#234F68"
    />
    <path
      opacity="0.15"
      d="M71.4062 66.0906C70.3093 68.1312 68.9703 70.0218 67.4328 71.7077C65.1156 74.2468 63.9515 75.2281 61.1562 76.7999C56.0999 79.6437 56.3374 85.4906 56.8906 86.6796C62.8671 85.2921 68.5562 82.0609 73.1999 76.9734C77.0109 72.7984 79.6734 67.8499 81.2156 62.5921C77.5703 61.1077 73.3171 62.5406 71.4062 66.0906Z"
      fill="black"
    />
    <path
      opacity="0.3"
      d="M34.0252 21.7535C38.4877 17.8207 44.1658 15.655 50.0111 15.655C53.9908 15.655 57.2393 12.6691 57.7252 8.82067C47.8314 6.33786 37.0471 8.68005 28.8596 15.8941C24.1502 20.0441 21.2111 26.28 19.5596 32.3191C27.1596 32.8504 29.3518 28.2988 29.4908 28.0363C30.8549 25.4488 32.3002 23.2738 34.0252 21.7535Z"
      fill="white"
    />
    <path
      d="M28.1016 25.5672C29.2984 23.4406 30.7437 21.5188 32.475 19.9953C33.8031 18.825 35.2297 17.7969 36.7328 16.9219"
      stroke="white"
      stroke-width="4.6875"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export function checkPhoneNumber(
  customer: { phone?: string },
  setCustomer: SetStoreFunction<{ id?: number; name?: string; phone?: string }>,
  setAlreadyExistsModal?
) {
  createEffect(async () => {
    if (
      (customer.phone[1] === "5" && customer.phone.length === 10) ||
      (customer.phone[1] !== "5" && customer.phone.length === 9)
    ) {
      const res = await axios.get(
        `/real-estate/customer/phone_check/${customer.phone}/`
      );

      if (!res.data?.id) {
        return;
      }

      if (res.data.is_archived) {
        await axios.post(`/real-estate/customer/${res.data?.id}/unarchive/`);
        await axios.patch(`/real-estate/customer/${res.data?.id}/`, {
          name: "",
        });
        setCustomer({ id: res.data.id });
        setCustomer({ phone: res.data.phone });
        return true;
      }

      setCustomer({ name: res.data.name });
      setCustomer({ id: res.data.id });
      setCustomer({ phone: res.data.phone });
      if (setAlreadyExistsModal) {
        setAlreadyExistsModal(res.data);
      }
    }
  });
}

function populateFieldsFromNavigation(
  location,
  setCustomer: SetStoreFunction<{ id: number; name: string; phone: string }>
) {
  createEffect(() => {
    if (!location.state) {
      return;
    }
    if (location.state.name) {
      setCustomer({ name: location.state.name });
    }
    if (location.state.id) {
      setCustomer({ id: location.state.id });
    }
    if (location.state.phone) {
      setCustomer({ phone: location.state.phone });
    }
  });
}



