import { Setter, Show, createEffect, createSignal } from "solid-js";
import './css/Textarea.sass'
interface IProps {
    countable?: boolean;
    label?: string;
    placeholder?:string;
    required?: boolean;
    height?: string;
    onChange: Setter<string>;
    name?: string;
    maxCount?: number;
    value?: string;
}

const NewTextarea = (props: IProps) => {
    const [count, setCount] = createSignal(0);
    const [countError, setCountError] = createSignal(false)
    if (props.countable && props.maxCount)
        createEffect(() => {
            if (props.maxCount && count() > props.maxCount) {
                setCountError(true)
            } else {
                setCountError(false)
            }
        })
    return (
        <div
            class="single-textarea"
        >
            <Show when={props.label}>
                <label class="textarea-title">
                    {props.label}
                </label>
            </Show>
            <textarea
                name={props.name}
                placeholder={props.placeholder}
                required={props.required}
                style={props.height ? props.height + 'px' : ''}
                onKeyUp={(e:any) => {
                    props.onChange(e)
                    setCount(e.currentTarget.value.length)
                }}
                class={`new-textarea ${
                    countError() ? 'error' : ''
                }`}
                value={props.value ? props.value : ''}
            ></textarea>
            <Show when={props.countable}>
                <span
                    class={countError() ? 'error textarea-count' : 'textarea-count'}
                >
                    {count()}/{props.maxCount}
                </span>
            </Show>
        </div>
    )
}

export default NewTextarea