import { A } from "@solidjs/router"
import logo from './icons/logo.svg'
import {AddUserIcon, AddAssetIcon, SearchAssetIcon, DashboardIcon, ConnectTeamIcon, CustomersIcon, AssetsIcon, TasksIcon, TransactionsIcon, SettingsIcon, EmptyAvatar} from './icons/Icons'
import { user } from "../../globalState"
import { onCleanup, onMount, Show } from "solid-js"
import { getNewConnectsCount } from "../../api/ConnectTeam/ConnectPage"
import { newRequestsCount } from "../../Store/ConnectTeam"
export const NewSidebar = () => {
    let connectTeamInterval:any;
    onMount(() => {
        getNewConnectsCount()
        connectTeamInterval = setInterval(getNewConnectsCount, 30000) // 30 seconds
    })
    onCleanup(() => {
        if (connectTeamInterval) {
            clearInterval(connectTeamInterval)
        }
    })


    return (
        <aside class="sidebar">
            <div class="sidebar--container">
                <div class="sidebar--logo">
                    <A href="/dashboard">
                        <img src={logo} alt="Gesher Hiburim" />
                    </A>
                </div>
                <div class="sidebar--actions">
                    <A
                        class="btn"
                        href="/addClient/buyer"
                    >
                        <span class="icon">
                            <AddUserIcon />
                        </span>
                        הוספת לקוח
                    </A>
                    <A
                        class="btn"
                        href="/addAsset"
                    >
                        <span class="icon">
                            <AddAssetIcon />
                        </span>
                        הוספת נכס
                    </A>
                    <A
                        class="btn btn--blue"
                        href="/searchAsset"
                    >
                        <span class="icon">
                            <SearchAssetIcon />
                        </span>
                        חיפוש נכסים מהיר
                    </A>
                </div>
                <div class="sidebar--nav">
                    <nav>
                        <ul>
                            <li>
                                <A
                                    class="link-item"
                                    href="/dashboard"
                                >
                                    <span class="icon">
                                        <DashboardIcon />
                                    </span>
                                    מבט כולל
                                </A>
                            </li>
                            <li>
                                <A
                                    class="link-item"
                                    href="/connect-team"
                                >
                                    <span class="icon">
                                        <ConnectTeamIcon />
                                    </span>
                                    ConnnecTeam
                                    <Show when={newRequestsCount() > 0}>
                                        <span class="st-count">
                                            {newRequestsCount()}
                                        </span>
                                    </Show>
                                </A>
                            </li>
                            <li>
                                <A
                                    class="link-item"
                                    href="/customers"
                                >
                                    <span class="icon">
                                        <CustomersIcon />
                                    </span>
                                    לקוחות
                                </A>
                            </li>
                            <li>
                                <A
                                    class="link-item"
                                    href="/assets"
                                >
                                    <span class="icon">
                                        <AssetsIcon />
                                    </span>
                                    נכסים
                                </A>
                            </li>
                            <li>
                                <A
                                    class="link-item"
                                    href="/tasks"
                                >
                                    <span class="icon">
                                        <TasksIcon />
                                    </span>
                                    משימות
                                </A>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="sidebar--nav bottom">
                    <nav>
                        <ul>
                            <li>
                                <A
                                    class="link-item"
                                    href="/deals"
                                >
                                    <span class="icon">
                                        <TransactionsIcon />
                                    </span>
                                    העסקאות שלי
                                </A>
                            </li>
                            <li>
                                <A
                                    class="link-item"
                                    href={`/settings`}
                                >
                                    <span class="icon">
                                        <SettingsIcon />
                                    </span>
                                    הגדרות
                                </A>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="sidebar--user">
                    <A
                        href={`/a/${user.uuid}`}
                        class="st-link"
                    >
                        <span class="st-img">
                            <Show
                                when={user.image_url}
                                fallback={<EmptyAvatar/>}
                            >
                                <img src={user.image_url} alt="" />
                            </Show>
                        </span>
                        {user.name}
                    </A>
                </div>
            </div>
        </aside>
    )
}
