import { createStore } from "solid-js/store"
import { scheduleMeetingModalStatus, setDatePickerModalStatus, setScheduleMeetingModalStatus } from "../../Store/Modals"
import { whatsupLimitMessages, whatsupSentMessages } from "../../Store/Whatsup"
import NewTextarea from "../Textarea"
import { createEffect, createSignal, Show } from "solid-js"
import NewSelect from "../NewSelect"
import TimePicker from "@rnwonder/solid-date-picker/timePicker";
import { TimeValue } from "@kobalte/core/dist/types/calendar/types"
import '@rnwonder/solid-date-picker/dist/style.css'
import './../css/DateTimePicker.sass'

const ScheduleMeetingModal = () => {
    const [date, setDate] = createSignal<string | number>('today')
    const [time, setTime] = createSignal<number | string>('right_now')
    const date_now = new Date();
    const [customTime, setCustomTime] = createSignal<any>({
        value: {
            hour: date_now.getHours(),
            minutes: date_now.getMinutes()
        },
        label: `${date_now.getHours()}:${date_now.getMinutes()}`
    })
    const [form, setForm]:any = createStore({
        text: '',
    })

    createEffect(() => {
        if (date() === 'custom') {
            setDatePickerModalStatus(true)
        }
    }, [date()])


    return (
        <div
            class='c-modal'
            classList={{'open': scheduleMeetingModalStatus()}}
        >
            <div
                class="c-modal--overlay"
                onClick={(e) => setScheduleMeetingModalStatus(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--content schedule-meeting">
                    <div class="c-modal--close">
                        <button
                            class="btn--close"
                            onClick={(e) => setScheduleMeetingModalStatus(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div class="c-modal--title">
                        <span class="st-icon">
                            <ScheduleIcon />
                        </span>
                        <h3 class="st-title">
                            שליחת הנכס רוטשילד 45, פלורנטין, תל אביב יפו ללקוחות
                        </h3>
                        <p class="st-text">
                            בחרת לשתף את הנכס עם 2 לקוחות
                        </p>
                    </div>
                    <div class="c-modal--form">
                        <NewTextarea
                            required={true}
                            label="הוספת הודעה אישית"
                            placeholder="כאן ניתן להקליד הודעה שתצורף לקישור לנכס"
                            height="68"
                            countable={true}
                            maxCount={100}
                            value={form.text}
                            onChange={(e:any) => {
                                setForm({text: e.currentTarget.value})
                            }}
                        />
                        <div class="single-input">
                            <p class="input-title">
                                תזמון הודעה
                            </p>
                            <div
                                class="schedule-meet--container"
                                classList={{'grid-3': time() === 'custom'}}
                            >
                                <NewSelect
                                    value={date()}
                                    setValue={setDate}
                                    options={dateOptions()}
                                />
                                <NewSelect
                                    value={time()}
                                    setValue={setTime}
                                    options={timeOptions}
                                />
                                <Show when={time() === 'custom'}>
                                    <TimePicker
                                        value={customTime}
                                        setValue={setCustomTime}
                                        inputClass="modal-input"
                                        shouldCloseOnSelect
                                    />
                                </Show>
                            </div>
                        </div>
                        <div class="info--container">
                            <p class="info-text">
                                לאחר שליחת הודעה זו יישארו לך {whatsupLimitMessages() - whatsupSentMessages() - 1} שליחות נוספות 
                            </p>
                            <div class="submit--container">
                                <button
                                    class="btn--submit"
                                    style="margin-top: 0"
                                    onClick={(e:any) => {
                                        // submit()
                                    }}
                                >
                                    שליחה
                                    <span class="icon">
                                        <SubmitIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleMeetingModal


const dateOptions = () => {
    const today = new Date();
    const tomorrow:any = new Date(new Date().setDate(today.getDate() + 1))
    let arr:any = [
        {
            name: `היום (יום ה׳ - ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()})`,
            value: 'today'
        },
        {
            name: `יום העסקים הבא(יום ו׳ - )- ${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()})`,
            value: 'tomorrow'
        },
        {
            name: `תאריך ספציפי`,
            value: 'custom'
        }
    ]
    return arr
}
const timeOptions = [
    {
        name: 'ברגע זה',
        value: 'right_now'
    },
    {
        name: 'בוקר (8:00)',
        value: '08:00',
    },
    {
        name: 'צהריים (12:00)',
        value: '12:00',
    },
    {
        name: 'ערב (18:00)',
        value: '18:00',
    },
    {
        name: 'זמן מותאם אישית',
        value: 'custom',
    }
]






const SubmitIcon = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0111 1.66602C5.42273 1.66602 1.68754 5.39799 1.68587 9.98633C1.68504 11.453 2.06919 12.8848 2.79753 14.1465L1.66797 18.3327L6.02832 17.3024C7.24415 17.9657 8.61285 18.3139 10.0062 18.3148H10.0094C14.5969 18.3148 18.3305 14.582 18.333 9.99447C18.3347 7.7703 17.4698 5.67913 15.8981 4.10579C14.3264 2.53329 12.2377 1.66685 10.0111 1.66602ZM10.0094 3.33268C11.7894 3.33352 13.4622 4.02751 14.7197 5.28418C15.9772 6.54251 16.668 8.21451 16.6663 9.99284C16.6647 13.6628 13.6795 16.6481 10.0078 16.6481C8.89698 16.6473 7.79664 16.3684 6.82747 15.8392L6.26595 15.5332L5.64583 15.6797L4.00521 16.0671L4.4056 14.5794L4.58626 13.9121L4.24121 13.3132C3.65954 12.3065 3.35171 11.1555 3.35254 9.98633C3.35421 6.318 6.34027 3.33268 10.0094 3.33268ZM7.0651 6.14518C6.92594 6.14518 6.70092 6.19727 6.51009 6.4056C6.31926 6.6131 5.78092 7.11566 5.78092 8.139C5.78092 9.16233 6.52637 10.1515 6.63053 10.2907C6.73387 10.429 8.06943 12.5954 10.1836 13.4287C11.9403 14.1212 12.297 13.9845 12.6787 13.9495C13.0604 13.9154 13.91 13.4474 14.0833 12.9616C14.2567 12.4758 14.2571 12.0578 14.2054 11.972C14.1537 11.8853 14.015 11.8337 13.8066 11.7295C13.5991 11.6253 12.5766 11.1232 12.3857 11.054C12.1949 10.9849 12.0553 10.9499 11.917 11.1582C11.7787 11.3665 11.3811 11.8337 11.2594 11.972C11.1378 12.1112 11.0169 12.1299 10.8086 12.0257C10.6003 11.9207 9.93042 11.7005 9.13542 10.9922C8.51708 10.4414 8.09986 9.76172 7.97819 9.55339C7.85736 9.34589 7.9668 9.23191 8.07096 9.12858C8.1643 9.03525 8.27767 8.88566 8.38184 8.764C8.48517 8.64233 8.521 8.55565 8.59017 8.41732C8.65934 8.27898 8.62393 8.1569 8.57227 8.05273C8.5206 7.94857 8.11599 6.92143 7.93099 6.50977C7.77516 6.16393 7.61057 6.1559 7.46224 6.15007C7.34141 6.14507 7.20344 6.14518 7.0651 6.14518Z" fill="white"/>
</svg>
)

const CloseIcon = () => (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0C8.06833 0 0 8.06833 0 18C0 27.9317 8.06833 36 18 36C27.9317 36 36 27.9317 36 18C36 8.06833 27.9317 0 18 0ZM18 1.56522C27.0857 1.56522 34.4348 8.91428 34.4348 18C34.4348 27.0857 27.0857 34.4348 18 34.4348C8.91428 34.4348 1.56522 27.0857 1.56522 18C1.56522 8.91428 8.91428 1.56522 18 1.56522ZM24.2532 10.9458C24.0468 10.9507 23.8507 11.037 23.7075 11.1858L18 16.8933L12.2925 11.1858C12.2195 11.1108 12.1323 11.0512 12.036 11.0105C11.9396 10.9699 11.8361 10.9489 11.7315 10.9489C11.5758 10.9489 11.4237 10.9954 11.2945 11.0823C11.1654 11.1693 11.0651 11.2928 11.0065 11.437C10.9479 11.5813 10.9337 11.7397 10.9656 11.8921C10.9976 12.0445 11.0742 12.1839 11.1858 12.2925L16.8933 18L11.1858 23.7075C11.1107 23.7797 11.0507 23.866 11.0094 23.9616C10.9681 24.0572 10.9463 24.16 10.9452 24.2642C10.9442 24.3683 10.9639 24.4716 11.0032 24.568C11.0426 24.6644 11.1008 24.7519 11.1744 24.8256C11.248 24.8992 11.3356 24.9574 11.432 24.9968C11.5284 25.0361 11.6317 25.0558 11.7358 25.0548C11.84 25.0537 11.9428 25.0319 12.0384 24.9906C12.134 24.9493 12.2203 24.8893 12.2925 24.8142L18 19.1067L23.7075 24.8142C23.7797 24.8893 23.866 24.9493 23.9616 24.9906C24.0572 25.0319 24.16 25.0537 24.2642 25.0548C24.3683 25.0558 24.4716 25.0361 24.568 24.9968C24.6644 24.9574 24.7519 24.8992 24.8256 24.8256C24.8992 24.7519 24.9574 24.6644 24.9968 24.568C25.0361 24.4716 25.0558 24.3683 25.0548 24.2642C25.0537 24.16 25.0319 24.0572 24.9906 23.9616C24.9493 23.866 24.8893 23.7797 24.8142 23.7075L19.1067 18L24.8142 12.2925C24.9279 12.1832 25.0059 12.0421 25.038 11.8877C25.0701 11.7333 25.0548 11.5729 24.9942 11.4273C24.9336 11.2818 24.8304 11.1579 24.6982 11.072C24.5659 10.9861 24.4109 10.9421 24.2532 10.9458Z" fill="white"/>
</svg>
)

const ScheduleIcon = () => (
<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M50 100C73.2995 100 92.1875 97.9013 92.1875 95.3125C92.1875 92.7237 73.2995 90.625 50 90.625C26.7005 90.625 7.8125 92.7237 7.8125 95.3125C7.8125 97.9013 26.7005 100 50 100Z" fill="black"/>
<path d="M55.5727 81.25H13.1758C11.0102 81.25 9.25391 79.475 9.25391 77.2844V72.2828C9.25391 58.25 20.4992 46.875 34.3742 46.875C48.2492 46.875 59.4945 58.25 59.4945 72.2828V77.2844C59.4945 79.475 57.7383 81.25 55.5727 81.25Z" fill="#234F68"/>
<path opacity="0.15" d="M59.4953 77.2852V72.282C59.4953 64.9195 56.3797 58.3086 51.4328 53.668C48.4625 56.0039 47.5297 60.1992 49.4328 63.5992C50.9047 66.2289 51.6828 69.2305 51.6828 72.282V73.4383H39.0625C34.7469 73.4383 31.25 76.9367 31.25 81.2508H55.5734C57.7391 81.2508 59.4953 79.4758 59.4953 77.2852Z" fill="black"/>
<path d="M34.375 43.75C43.0044 43.75 50 36.7544 50 28.125C50 19.4956 43.0044 12.5 34.375 12.5C25.7456 12.5 18.75 19.4956 18.75 28.125C18.75 36.7544 25.7456 43.75 34.375 43.75Z" fill="#8BC83F"/>
<path opacity="0.3" d="M34.375 12.5C25.7453 12.5 18.75 19.4953 18.75 28.125C18.75 30.8609 19.4563 33.4281 20.6922 35.6656C24.0609 34.7969 26.5625 31.7656 26.5625 28.125C26.5625 23.8172 30.0672 20.3125 34.375 20.3125C38.0156 20.3125 41.0484 17.8109 41.9156 14.4422C39.6781 13.2063 37.1109 12.5 34.375 12.5Z" fill="white"/>
<path d="M85.9375 39.0625H67.1875L60.6219 47.5047C59.1938 49.3406 56.25 48.3313 56.25 46.0047V18.75C56.25 15.2984 59.0484 12.5 62.5 12.5H85.9375C89.3891 12.5 92.1875 15.2984 92.1875 18.75V32.8125C92.1875 36.2641 89.3891 39.0625 85.9375 39.0625Z" fill="#234F68"/>
<path d="M24.7109 25C25.6266 22.1734 27.75 19.8875 30.4703 18.75" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)