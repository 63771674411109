import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { createQuery } from "@tanstack/solid-query";
import axios from "axios";
import { setLoggedIn, setUser } from "../globalState";
import { getAuth } from "firebase/auth";
import { unwrap } from "solid-js/store";
import { setUnknowUser } from "../Pages/Login/Login";
import { userActiveAssets, userBuyers, userLeads, userSellers } from "../Store/User";
import posthog from "posthog-js";
const useGetUserDetails = () =>
  createQuery({
    queryKey: () => {
      return ["get user details"];
    },
    queryFn: getUserDetails,
    staleTime: 60000,
  });

export default useGetUserDetails;

export const getUserDetails = async () => {
  const token = axios.defaults.headers.common.Authorization;
  if (!token) {
    return false;
  }
  const auth = getAuth();
  const newToken = await auth.currentUser?.getIdToken();
  if (newToken) {
    axios.defaults.headers.common.Authorization = newToken;
  }

  if (token && !window.location.pathname.includes("/registration")) {
    const userReq = await axios
      .get("/access-control/users/home/")
      .catch((err) => {
        if (
          (err && err.response && err.response?.status === 403) &&
          window.location.pathname.includes("/login")
        ) {
          auth.signOut();
          setUnknowUser(true);
          return;
        } else if (
          (err && err.response && err.response?.status === 403) &&
          window.location.pathname.includes("/registration")
        ) {
          setUnknowUser(true);
          auth.signOut();
          console.log('Registration user 403')
          return;
        }
        if (err && err.response && err.response?.status === 403)  {
          auth.signOut();
        }
      });
    if (!userReq) {
      return;
    }
    let userData:any = userReq.data;
    delete userData.active_assets
    delete userData.buyers
    delete userData.sellers
    delete userData.leads
    
    setUser({active_assets: userActiveAssets})
    setUser({buyers: userBuyers})
    setUser({sellers: userSellers})
    setUser({leads: userLeads})
    
    Object.keys(userData).forEach((key) => {
      setUser({ [key]: userData[key] });
    });

    const sorted = unwrap(userData).notifications.sort((a:any, b:any) => {
      return a.id < b.id ? 1 : -1;
    });
    setUser({
      notifications: sorted,
    });
    setLoggedIn(true);
    if (import.meta.env.MODE !== 'development') {
      posthog.identify(
        userData.id,
        {
          name: userData.name,
          phone: userData.phone
        }
      )
    }

    if (Capacitor.getPlatform() !== "web") {
      await FirebaseMessaging.requestPermissions();
      await FirebaseMessaging.getToken();
      await FirebaseMessaging.subscribeToTopic({ topic: userData.phone });
    }

    return true;
  }
  return false;
};
