


import { newConfirmModalData, newConfirmModalStatus, setNewConfirmModalStatus } from "../../Store/Modals"
import { CloseModalIcon } from "./icons/CloseModal"
const NewConfirmModal = () => {
    return (
        <div
            class="c-modal action-modal"
            classList={{'open': newConfirmModalStatus()}}
        >
            <div
                class="c-modal--overlay"
                onClick={() => setNewConfirmModalStatus(false)}
            ></div>
            <div class="c-modal--container">
                <div class="c-modal--action">
                    <div class="c-modal--head">
                        <button
                            class="btn--close"
                            type="button"
                            onClick={() => setNewConfirmModalStatus(false)}
                        >
                            <CloseModalIcon />
                        </button>
                        <p class="st-title">
                            {newConfirmModalData.title}
                        </p>
                    </div>
                    <div class="c-modal--success">
                        <p class="st-text">
                            {newConfirmModalData.text}
                        </p>
                    </div>
                    <div class="c-modal--actions">
                        <button
                            class="btn btn--red"
                            type="submit"
                            onClick={async () => {
                                await newConfirmModalData.action()
                                setNewConfirmModalStatus(false)
                            }}
                        >
                            סיום
                        </button>
                        <button
                            class="btn btn--grey"
                            type="button"
                            onClick={() => {
                                setNewConfirmModalStatus(false)
                            }}
                        >
                            ביטול פעולה
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewConfirmModal
