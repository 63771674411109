import NewActionSuccessModal from "./ActionSuccessModal"
import NewConfirmModal from "./ConfirmModal"
import { DatePickerModal } from "./DatePicker"
import NewReportModal from "./NewReportModal"
import ScheduleMeetingModal from "./ScheduleMeeting"


const AllModalsContainer = () => {
    return (
        <>
            <NewReportModal/>
            <NewActionSuccessModal />
            <ScheduleMeetingModal />
            <DatePickerModal />
            <NewConfirmModal />
        </>
    )
}
export default AllModalsContainer