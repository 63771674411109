// Generated by ts-to-zod
import { z } from "zod";
import {
  AirConditioningStateEnum,
  AssetTypeEnum,
  BlankEnum,
  NullEnum,
  AssetConditionEnum,
  EvictionRangeEnum,
  AssetFacingEnum,
  VisibilityStatusEnum,
  BuyerEndCustomerActivityStateEnum,
  BuyerEndCustomerStatusEnum,
  DealTypeEnum,
  StatusEnum,
  NotificationTypes,
  LeadSourceEnum,
  RequestedEvictionRangeEnum,
  TaskStatusEnum,
  ShareOptionsEnum,
  RequestedEvictionRangeEnumValidation,
  BuyerEndCustomerStatusValidation,
  AssetFacingEnumValidation,
  VisibilityStatusEnumValidation,
  AssetConditionEnumValidation,
  EvictionRangeEnumValidation,
} from "./types";

export const phoneRegex =
  /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;

export const airConditioningStateEnumSchema = z.nativeEnum(
  AirConditioningStateEnum
);

export const citySchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const neighborhoodSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const streetSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const assetTypeEnumSchema = z.nativeEnum(AssetTypeEnum);

export const blankEnumSchema = z.nativeEnum(BlankEnum);

export const nullEnumSchema = z.nativeEnum(NullEnum);

export const assetConditionEnumSchema = z.nativeEnum(
  AssetConditionEnumValidation
);

export const evictionRangeEnumSchema = z.nativeEnum(
  EvictionRangeEnumValidation
);

export const assetFacingEnumSchema = z.nativeEnum(AssetFacingEnumValidation);

export const visibilityStatusEnumSchema = z.nativeEnum(
  VisibilityStatusEnumValidation
);

export const assetLevelSchema = z.object({
  id: z.number(),
  built_area: z.string().regex(/^-?\d{0,4}(?:\.\d{0,1})?$/),
  outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
});

export const assetSeparateUnitSchema = z.object({
  id: z.number(),
  unit_size: z.string().regex(/^-?\d{0,4}(?:\.\d{0,1})?$/),
  is_currently_rented: z.boolean().optional(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
});

export const bannerSchema = z.object({
  id: z.number(),
  client: z.string().max(50),
  image_url: z.string(),
  advertiser_email: z.string().max(254).email().optional().nullable(),
});

export const basicEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().nonempty("חובה להזין שם לקוח"),
  phone: z
    .string()
    .regex(/^[0][5][0|2|3|8|4|5|9]{1}[-]{0,1}[0-9]{7}$/, "טלפון לא תקין"),
});

export const buyerEndCustomerActivityStateEnumSchema = z.nativeEnum(
  BuyerEndCustomerActivityStateEnum
);

export const buyerEndCustomerStatusEnumSchema = z.nativeEnum(
  BuyerEndCustomerStatusValidation
);

export const createAssetSchema = z.object({
  id: z.number().optional(),

  phone: z.union([
    z.string().regex(/^$/, "נא להזין מספר טלפון תקין"),

    z.string().regex(phoneRegex, "נא להזין מספר טלפון תקין"),
  ]),
  general_description: z.string().optional().nullable(),
  city: z.number({
    required_error: "חובה להזין עיר",
    invalid_type_error: "חובה להזין עיר",
  }),
  neighborhood: z.number({
    required_error: "חובה להזין שכונה",
    invalid_type_error: "חובה להזין שכונה",
  }),
  street: z.number({
    required_error: "חובה להזין שם רחוב",
    invalid_type_error: "חובה להזין רחוב",
  }),
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z.string({
    required_error: "חובה להזין סוג נכס",
    invalid_type_error: "חובה להזין סוג נכס",
  }),
  number_of_rooms: z.string({
    required_error: "חובה להזין מס׳ חדרים",
    invalid_type_error: "חובה להזין מס׳ חדרים",
  }),
  floor: z.union([z.number().optional().nullable(), z.string()]),
  highest_floor: z.union([z.number().optional().nullable(), z.string()]),
  asset_condition: z
    .union([
      nullEnumSchema.nullish(),
      assetConditionEnumSchema.nullish(),
      blankEnumSchema.nullish(),
    ])
    .transform((res, ctx) => {
      if (!res) {
        ctx.addIssue({ message: "חובה להזין מצב נכס", code: "custom" });
      }
    }),
  asset_built_area: z
    .string({ invalid_type_error: "חובה להזין שטח נכס" })
    .min(1, {
      message: "חובה להזין שטח נכס",
    }),

  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z.union([z.string(), z.number()]),
  rent_price: z.number().optional().nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.date().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.union([z.number().optional().nullable(), z.string()]),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.union([z.number().optional().nullable(), z.string()]),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.union([
    z.number().optional().nullable(),
    z.string(),
  ]),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  is_sold: z.boolean().optional(),
  // asset_levels: z.optional(z.array(assetLevelSchema)),
  separate_units: z.array(assetSeparateUnitSchema).optional(),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  roof_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  construction_year: z.number().optional().nullable(),
  is_rented: z.boolean().optional(),
  toilets: z.union([z.number().optional().nullable(), z.string()]),
  is_for_demolition: z.boolean().optional(),
});

export const createAssetInteractionSerializerForPhoneCallSchema = z.object({
  id: z.number(),
  asset: z.number(),
  customer: z.number(),
});

export const dealTypeEnumSchema = z.nativeEnum(DealTypeEnum);

export const createDealSerializerSchemaSchema = z.object({
  id: z.number(),
  selling_customer: z.number().optional().nullable(),
  selling_customer_realtor: z.number(),
  purchasing_customer: z.number(),
  purchasing_customer_realtor: z.number(),
  payed_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  deal_date: z.string(),
  deal_type: dealTypeEnumSchema,
});

export const createDontCallMeConsentSchema = z.object({
  id: z.number(),
  realtor: z.number().nullable(),
  customer: z.number().nullable(),
});

export const createGesherTaskSchema = z.object({
  content: z.string().optional().nullable(),
  reported_realtor: z.number().optional().nullable(),
  asset: z.number().optional().nullable(),
});

export const statusEnumSchema = z.nativeEnum(StatusEnum);

export const reminderSchema = z.object({
  id: z.number(),
  reminder_time: z.string(),
});

export const minimalRealtorSchema = z.object({
  id: z.number(),
  name: z.string(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  image_url: z.string(),
});

export const notificationTypesSchema = z.nativeEnum(NotificationTypes);

export const dontCallMeConsentSchema = z.object({
  id: z.number(),
  consent_date: z.string().optional().nullable(),
  is_signed: z.boolean().optional(),
  customer: z.number().nullable(),
  realtor: z.number().nullable(),
});

export const leadSourceEnumSchema = z.nativeEnum(LeadSourceEnum);

export const endCustomerNoteSchema = z.object({
  id: z.number(),
  content: z.string(),
  created_at: z.string(),
});

export const phoneCallSchema = z.object({
  id: z.number(),
  customer: z.number(),
  realtor: z.number(),
  call_id: z.string().max(20),
  call_date: z.string(),
  call_duration: z.string().regex(/^-?\d{0,5}(?:\.\d{0,0})?$/),
  call_url: z.string().max(200).optional().nullable(),
  call_file_url: z.string(),
  call_file_is_downloaded: z.boolean(),
});

export const giveConsentDontCallMeConsentSchema = z.object({
  customer_id: z.number(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
});

export const minimalAgencySchema = z.object({
  id: z.number(),
  name: z.string().max(50),
});

export const realtorLeadEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  dont_call_me: z.boolean().optional(),
  dont_call_me_consent: z.boolean(),
});

export const realtorHomeBuyerEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  notes: z.array(endCustomerNoteSchema),
  dont_call_me: z.boolean().optional(),
  number_of_matches: z.number(),
  is_vip: z.boolean().optional(),
  registration_date: z.string(),
  dont_call_me_consent: z.boolean(),
  buyer_end_customer_activity_state: z
    .union([
      buyerEndCustomerActivityStateEnumSchema,
      blankEnumSchema,
      nullEnumSchema,
    ])
    .optional()
    .nullable(),
});

export const realtorHomeSellerEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  notes: z.array(endCustomerNoteSchema),
  dont_call_me: z.boolean().optional(),
  number_of_matches: z.number(),
  is_vip: z.boolean().optional(),
  registration_date: z.string(),
  dont_call_me_consent: z.boolean(),
});

export const realtorHomeNotificationsSchema = z.object({
  id: z.number(),
  notification_type: z.string().max(24),
  content: z.string().max(300),
  created_at: z.string(),
  referenced_object_id: z.number().optional().nullable(),
});

export const paginatedCityListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(citySchema).optional(),
});

export const paginatedNeighborhoodListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(neighborhoodSchema).optional(),
});

export const realtorProfileSchema = z.object({
  id: z.number(),
  agency: minimalAgencySchema,
  name: z.string(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  linkedin: z.string().max(200).optional().nullable(),
  activity_areas: z.array(citySchema),
  num_assets: z.number().nullable(),
  num_buyers: z.number().nullable(),
  num_deals: z.number().nullable(),
  email: z.string().max(254).email().optional().nullable(),
  virtual_phone: z.string(),
  tiktok: z.string().max(200).optional().nullable(),
  instagram: z.string().max(200).optional().nullable(),
  webpage: z.string().max(200).optional().nullable(),
  about: z.string().optional().nullable(),
  facebook: z.string().max(200).optional().nullable(),
  image_url: z.string(),
});

export const paginatedStreetListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(streetSchema).optional(),
});

export const patchedCreateTaskSchema = z.object({
  id: z.number().optional(),
  title: z.string().max(50).optional(),
  notes: z.string().optional().nullable(),
  customers: z.array(z.number()).optional(),
  other_realtors: z.array(z.number().nullable()).optional(),
  asset: z.number().optional().nullable(),
  status: statusEnumSchema.optional(),
  created_at: z.string().optional(),
  start_time: z.string().optional().nullable(),
  end_time: z.string().optional().nullable(),
  reminders: z.array(reminderSchema).optional(),
});

export const requestedEvictionRangeEnumSchema = z.nativeEnum(
  RequestedEvictionRangeEnumValidation
);

export const patchedRealtorPatchSchema = z.object({
  tiktok: z.string().max(200).optional().nullable(),
  webpage: z.string().max(200).optional().nullable(),
  linkedin: z.string().max(200).optional().nullable(),
  about: z.string().optional().nullable(),
  email: z.string().max(254).email().optional().nullable(),
  facebook: z.string().max(200).optional().nullable(),
  instagram: z.string().max(200).optional().nullable(),
  show_asset_numbers: z.boolean().optional(),
  show_buyer_numbers: z.boolean().optional(),
  show_deal_numbers: z.boolean().optional(),
});

export const phoneCheckEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  is_archived: z.boolean().optional(),
  buyer_end_customer_activity_state: z
    .union([
      buyerEndCustomerActivityStateEnumSchema,
      blankEnumSchema,
      nullEnumSchema,
    ])
    .optional()
    .nullable(),
});

export const potentialBuyerNoteSchema = z.object({
  id: z.number(),
  content: z.string(),
  created_at: z.string(),
});

export const purchaseListingSchema = z.object({
  id: z.number().optional(),
  city: z.string({ required_error: "חובה להזין עיר" }),
  neighborhoods: z.record(z.string(), z.boolean(), {
    required_error: "חובה להזין שכונה",
  }),
  budget: z
    .string({
      required_error: "חובה להזין מחיר נכס",
      invalid_type_error: "חובה להזין מחיר נכס",
    })
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/, "חובה להזין מחיר נכס תקני"),
  asset_type: z.string({
    invalid_type_error: "חובה להזין סוג נכס",
    required_error: "חובה להזין סוג נכס",
  }).optional(),
  asset_types_list: z.record(z.boolean()),
  requested_eviction_range: z
    .union([requestedEvictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z
    .string({
      required_error: "חובה להזין מס׳ חדרים",
    })
    .regex(/^-?\d{0,2}(?:\.\d{0,1})?$/, "מספר לא תקין"),
  from_floor: z.number().optional().nullable(),
  to_floor: z.number().optional().nullable(),
  not_first_floor: z.boolean().optional(),
  not_last_floor: z.boolean().optional(),
  asset_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  asset_outside_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  should_have_elevator: z.boolean().optional(),
  elevator_from_floor: z.number().optional().nullable(),
  should_show_dangerous_buildings: z.boolean().optional(),
  should_have_parking_in_registry: z.boolean().optional(),
  should_have_sun_terrace: z.boolean().optional(),
  should_have_storage_space: z.boolean().optional(),
  requested_eviction_date: z.string().optional().nullable(),
  should_have_in_house_shelter: z.boolean().optional(),
});

export const purchaseListingUpdateSchema = z.object({
  id: z.number(),
  city: z.number(),
  neighborhoods: z.array(z.number()).optional(),
  budget: z.string().regex(/^-?\d{0,10}(?:\.\d{0,2})?$/),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  requested_eviction_range: z
    .union([requestedEvictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string().regex(/^-?\d{0,2}(?:\.\d{0,1})?$/),
  from_floor: z.number().optional().nullable(),
  to_floor: z.number().optional().nullable(),
  not_first_floor: z.boolean().optional(),
  not_last_floor: z.boolean().optional(),
  asset_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  asset_outside_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  should_have_elevator: z.boolean().optional(),
  elevator_from_floor: z.number().optional().nullable(),
  should_show_dangerous_buildings: z.boolean().optional(),
  should_have_parking_in_registry: z.boolean().optional(),
  should_have_sun_terrace: z.boolean().optional(),
  should_have_storage_space: z.boolean().optional(),
  requested_eviction_date: z.string().optional().nullable(),
  should_have_in_house_shelter: z.boolean().optional(),
});

export const purchasingEndCustomerSchema = z.object({
  id: z.number(),
  name: z.string().nonempty("חובה להזין שם לקוח"),
  phone: z
    .string()
    .regex(/^\+[1-9]{1}[0-9]{3,14}$/, "מספר הטלפון שהזנת לא תקין"),

  buyer_end_customer_status: z
    .union([
      buyerEndCustomerStatusEnumSchema,
      blankEnumSchema,
      nullEnumSchema.nullish(),
    ])
    .transform((res, ctx) => {
      if (!res) {
        ctx.addIssue({ message: "חובה להזין מצב נכס", code: "custom" });
      }
    }),
  buyer_end_customer_activity_state: z
    .union([
      buyerEndCustomerActivityStateEnumSchema,
      blankEnumSchema,
      nullEnumSchema,
    ])
    .optional()
    .nullable(),
  is_vip: z.boolean().optional(),
});

export const readOnlyAgencySchema = z.object({
  id: z.number(),
  name: z.string().max(50),
  realtors: z.string(),
});

export const realtorHomeAssetSellerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
});

export const realtorPatchSchema = z.object({
  tiktok: z.string().max(200).optional().nullable(),
  webpage: z.string().max(200).optional().nullable(),
  linkedin: z.string().max(200).optional().nullable(),
  about: z.string().optional().nullable(),
  email: z.string().max(254).email().optional().nullable(),
  facebook: z.string().max(200).optional().nullable(),
  instagram: z.string().max(200).optional().nullable(),
  show_asset_numbers: z.boolean().optional(),
  show_buyer_numbers: z.boolean().optional(),
  show_deal_numbers: z.boolean().optional(),
});

export const taskStatusEnumSchema = z.nativeEnum(TaskStatusEnum);

export const sendAdvertiserEmailSchema = z.object({
  message: z.string(),
});

export const tokenObtainPairSchema = z.object({
  phone: z.string(),
  password: z.string(),
  access: z.string(),
  refresh: z.string(),
});

export const tokenRefreshSchema = z.object({
  access: z.string(),
  refresh: z.string(),
});

export const shareOptionsEnumSchema = z.nativeEnum(ShareOptionsEnum);

export const realtorSlimSchema = z.object({
  id: z.number(),
  name: z.string(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  image_url: z.string(),
  agency: minimalAgencySchema,
});

export const potentialBuyerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  agency: z.number(),
  buyer_end_customer_status: z
    .union([buyerEndCustomerStatusEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  notes: z.array(potentialBuyerNoteSchema),
});

export const assetViewSetResponseSchemaSchema = z.object({
  id: z.number(),
  seller: z.number().optional().nullable(),
  realtor: z.number(),
  general_description: z.string().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  number_of_rooms: z.string(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.union([z.number().optional().nullable(), z.string()]),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema).optional(),
  separate_units: z.array(assetSeparateUnitSchema),
  image_urls: z.array(z.string()),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  realtor_contacts: z.array(realtorSlimSchema),
  realtors_can_view: z.array(realtorSlimSchema),
  potential_buyers: z.array(potentialBuyerSchema),
});

export const createDealSchema = z.object({
  id: z.number(),
  selling_customer: z.number().optional().nullable(),
  selling_customer_realtor: z.number(),
  purchasing_customer: z.number(),
  purchasing_customer_realtor: z.number(),
  payed_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  deal_date: z.string(),
  asset: z.number(),
  deal_type: dealTypeEnumSchema,
});

export const createTaskSchema = z.object({
  id: z.number(),
  title: z.string().max(50),
  notes: z.string().optional().nullable(),
  customers: z.array(z.number()).optional(),
  other_realtors: z.array(z.number().nullable()).optional(),
  asset: z.number().optional().nullable(),
  status: statusEnumSchema.optional(),
  created_at: z.string(),
  start_time: z.string().optional().nullable(),
  end_time: z.string().optional().nullable(),
  reminders: z.array(reminderSchema).optional(),
});

export const purchaseListingResponseSchema = z.object({
  id: z.number(),
  city: citySchema,
  neighborhoods: z.array(neighborhoodSchema),
  budget: z.string().regex(/^-?\d{0,10}(?:\.\d{0,2})?$/),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  requested_eviction_range: z
    .union([requestedEvictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string().regex(/^-?\d{0,2}(?:\.\d{0,1})?$/),
  from_floor: z.number().optional().nullable(),
  to_floor: z.number().optional().nullable(),
  not_first_floor: z.boolean().optional(),
  not_last_floor: z.boolean().optional(),
  asset_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  asset_outside_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  should_have_elevator: z.boolean().optional(),
  elevator_from_floor: z.number().optional().nullable(),
  should_show_dangerous_buildings: z.boolean().optional(),
  should_have_parking_in_registry: z.boolean().optional(),
  should_have_sun_terrace: z.boolean().optional(),
  should_have_storage_space: z.boolean().optional(),
  requested_eviction_date: z.string().optional().nullable(),
  should_have_in_house_shelter: z.boolean().optional(),
});

export const realtorHomeAssetSchema = z.object({
  banner: z.any(),
  image_urls: z.any(),
  notes: z.any(),
  id: z.number(),
  number_of_rooms: z.string(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  floor: z.number().optional().nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  last_modified: z.string(),
  image_url: z.string(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  seller: realtorHomeAssetSellerSchema,
  realtor_contacts: z.array(realtorSlimSchema),
  realtors_can_view: z.array(realtorSlimSchema),
  potential_buyers: potentialBuyerSchema,
  visibility_status: visibilityStatusEnumSchema.optional(),
});

export const homeRealtorSchema = z.object({
  id: z.number(),
  agency: minimalAgencySchema,
  name: z.string(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  linkedin: z.string().max(200).optional().nullable(),
  activity_areas: z.array(citySchema),
  leads: z.array(realtorLeadEndCustomerSchema),
  active_assets: z.array(realtorHomeAssetSchema),
  num_sold_assets: z.number(),
  buyers: z.array(realtorHomeBuyerEndCustomerSchema),
  sellers: z.array(realtorHomeSellerEndCustomerSchema),
  num_new_assets: z.number(),
  num_updated_assets: z.number(),
  notifications: z.array(realtorHomeNotificationsSchema),
  realtor_blacklist: z.array(minimalRealtorSchema),
  email: z.string().max(254).email().optional().nullable(),
  virtual_phone: z.string(),
  tiktok: z.string().max(200).optional().nullable(),
  instagram: z.string().max(200).optional().nullable(),
  webpage: z.string().max(200).optional().nullable(),
  about: z.string().optional().nullable(),
  facebook: z.string().max(200).optional().nullable(),
  image_url: z.string(),
  show_asset_numbers: z.boolean().optional(),
  show_buyer_numbers: z.boolean().optional(),
  show_deal_numbers: z.boolean().optional(),
  needs_onboarding: z.boolean().optional(),
});

export const listAssetSchema = z.object({
  id: z.number(),
  seller: z.number().optional().nullable(),
  realtor: z.number(),
  general_description: z.string().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.number().optional().nullable(),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  created_at: z.string(),
  last_modified: z.string(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema),
  separate_units: z.array(assetSeparateUnitSchema),
  image_urls: z.array(z.string()),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  realtor_contacts: z.array(realtorSlimSchema),
  realtors_can_view: z.array(realtorSlimSchema),
  roof_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  construction_year: z.number().optional().nullable(),
  is_rented: z.boolean().optional(),
  toilets: z.number().optional().nullable(),
  is_for_demolition: z.boolean().optional(),
});

export const paginatedAssetViewSetResponseSchemaListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(assetViewSetResponseSchemaSchema).optional(),
});

export const paginatedListAssetListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(listAssetSchema).optional(),
});

export const realtorAssetMatchesSchema = z.object({
  id: z.number(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  number_of_rooms: z.string(),
  number_of_potential_buyers: z.number(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  floor: z.number().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  image_url: z.string(),
  agency: minimalAgencySchema,
  realtor_contacts: z.array(realtorSlimSchema),
});

export const paginatedRealtorProfileListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(realtorProfileSchema).optional(),
});

export const patchedListAssetSchema = z.object({
  id: z.number().optional(),
  seller: z.number().optional().nullable(),
  realtor: z.number().optional(),
  general_description: z.string().optional().nullable(),
  city: citySchema.optional(),
  neighborhood: neighborhoodSchema.optional(),
  street: streetSchema.optional(),
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string().optional(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.number().optional().nullable(),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  created_at: z.string().optional(),
  last_modified: z.string().optional(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema).optional(),
  separate_units: z.array(assetSeparateUnitSchema).optional(),
  image_urls: z.array(z.string()).optional(),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  realtor_contacts: z.array(realtorSlimSchema).optional(),
  realtors_can_view: z.array(realtorSlimSchema).optional(),
  roof_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  construction_year: z.number().optional().nullable(),
  is_rented: z.boolean().optional(),
  toilets: z.number().optional().nullable(),
  is_for_demolition: z.boolean().optional(),
});

export const patchedPurchaseListingUpdateSchema = z.object({
  id: z.number().optional(),
  city: z.number().optional(),
  neighborhoods: z.array(z.number()).optional(),
  budget: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  requested_eviction_range: z
    .union([requestedEvictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z
    .string()
    .regex(/^-?\d{0,2}(?:\.\d{0,1})?$/)
    .optional(),
  from_floor: z.number().optional().nullable(),
  to_floor: z.number().optional().nullable(),
  not_first_floor: z.boolean().optional(),
  not_last_floor: z.boolean().optional(),
  asset_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  asset_outside_size: z
    .string()
    .regex(/^-?\d{0,3}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  should_have_elevator: z.boolean().optional(),
  elevator_from_floor: z.number().optional().nullable(),
  should_show_dangerous_buildings: z.boolean().optional(),
  should_have_parking_in_registry: z.boolean().optional(),
  should_have_sun_terrace: z.boolean().optional(),
  should_have_storage_space: z.boolean().optional(),
  requested_eviction_date: z.string().optional().nullable(),
  should_have_in_house_shelter: z.boolean().optional(),
});

export const publicAssetSchema = z.object({
  id: z.number(),
  seller: z.number().optional().nullable(),
  realtor: z.number(),
  general_description: z.string().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.number().optional().nullable(),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  created_at: z.string(),
  last_modified: z.string(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema),
  separate_units: z.array(assetSeparateUnitSchema),
  image_urls: z.array(z.string()),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  realtor_contacts: z.array(realtorSlimSchema),
  realtors_can_view: z.array(realtorSlimSchema),
  roof_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  construction_year: z.number().optional().nullable(),
  is_rented: z.boolean().optional(),
  toilets: z.number().optional().nullable(),
  is_for_demolition: z.boolean().optional(),
  banner: bannerSchema,
});

export const annotatedAssetSchema = z.object({
  id: z.number(),
  seller: z.number().optional().nullable(),
  realtor: z.number(),
  general_description: z.string().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  realtor_contacts: z.array(realtorSlimSchema),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.number().optional().nullable(),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  created_at: z.string(),
  last_modified: z.string(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema),
  separate_units: z.array(assetSeparateUnitSchema),
  image_urls: z.array(z.string()),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  ranking: z.string(),
});

export const assetSchema = z.object({
  id: z.number(),
  seller: z.number().optional().nullable(),
  realtor: z.number(),
  general_description: z.string().optional().nullable(),
  city: citySchema,
  neighborhood: neighborhoodSchema,
  street: streetSchema,
  address_number: z.string().max(8).optional().nullable(),
  asset_type: z
    .union([assetTypeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  number_of_rooms: z.string(),
  floor: z.number().optional().nullable(),
  highest_floor: z.number().optional().nullable(),
  asset_condition: z
    .union([assetConditionEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  asset_built_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  asset_outside_area: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  rent_price: z
    .string()
    .regex(/^-?\d{0,10}(?:\.\d{0,2})?$/)
    .optional()
    .nullable(),
  eviction_date: z.string().optional().nullable(),
  rent_end_date: z.string().optional().nullable(),
  eviction_range: z
    .union([evictionRangeEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  property_tax_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  house_committee_price: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  north_airway: z.boolean().optional(),
  south_airway: z.boolean().optional(),
  east_airway: z.boolean().optional(),
  west_airway: z.boolean().optional(),
  asset_facing: z
    .union([assetFacingEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  bathrooms: z.number().optional().nullable(),
  has_parent_unit: z.boolean().optional(),
  has_in_house_shelter: z.boolean().optional(),
  in_house_shelter_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_storage_space: z.boolean().optional(),
  storage_space_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  has_elevator: z.boolean().optional(),
  number_of_elevators: z.number().optional().nullable(),
  has_parking_in_registry: z.boolean().optional(),
  number_of_parking_spots: z.number().optional().nullable(),
  has_air_conditioning: z.boolean().optional(),
  air_conditioning_state: z
    .union([airConditioningStateEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  has_separate_unit: z.boolean().optional(),
  notes: z.string().optional().nullable(),
  is_accessible_for_disabled_people: z.boolean().optional(),
  visibility_status: visibilityStatusEnumSchema.optional(),
  should_show_address_number: z.boolean().optional(),
  created_at: z.string(),
  last_modified: z.string(),
  is_sold: z.boolean().optional(),
  asset_levels: z.array(assetLevelSchema),
  separate_units: z.array(assetSeparateUnitSchema),
  image_urls: z.array(z.string()),
  has_sun_terrace: z.boolean().optional(),
  sun_terrace_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  is_dangerous_building: z.boolean().optional(),
  realtor_contacts: z.array(realtorSlimSchema),
  realtors_can_view: z.array(realtorSlimSchema),
  potential_buyers: potentialBuyerSchema,
  roof_size: z
    .string()
    .regex(/^-?\d{0,4}(?:\.\d{0,1})?$/)
    .optional()
    .nullable(),
  construction_year: z.number().optional().nullable(),
  is_rented: z.boolean().optional(),
  toilets: z.number().optional().nullable(),
  is_for_demolition: z.boolean().optional(),
});

export const displayTaskSchema = z.object({
  id: z.number(),
  title: z.string().max(50),
  notes: z.string().optional().nullable(),
  customers: z.array(basicEndCustomerSchema),
  other_realtors: z.array(minimalRealtorSchema),
  asset: assetSchema,
  status: statusEnumSchema.optional(),
  created_at: z.string(),
  start_time: z.string().optional().nullable(),
  end_time: z.string().optional().nullable(),
  reminders: z.array(reminderSchema),
});

export const endCustomerSchema = z.object({
  id: z.number(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().regex(phoneRegex, { message: "יש להזין מספר תקני" }),
  phone_country_code: z.any(),
  registration_date: z.string(),
  buyer_end_customer_status: z
    .union([buyerEndCustomerStatusEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  realtor: z.number(),
  buyer_end_customer_activity_state: z
    .union([
      buyerEndCustomerActivityStateEnumSchema,
      blankEnumSchema,
      nullEnumSchema,
    ])
    .optional()
    .nullable(),
  number_of_potential_buyer_matches: z.number(),
  is_vip: z.boolean().optional(),
  lead_source: z
    .union([leadSourceEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  dont_call_me: z.boolean(),
  notes: z.array(endCustomerNoteSchema),
  purchase_listings: z.array(purchaseListingResponseSchema),
  phonecalls: z.array(phoneCallSchema),
  interested_in_assets: z.array(realtorHomeAssetSchema),
  sale_listings: z.array(assetSchema),
  dont_call_me_consent: z.boolean(),
  consent_document_id: z.number().nullable(),
});

export const paginatedDisplayTaskListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(displayTaskSchema).optional(),
});

export const paginatedRealtorAssetMatchesListSchema = z.object({
  count: z.number().optional(),
  next: z.string().optional().nullable(),
  previous: z.string().optional().nullable(),
  results: z.array(realtorAssetMatchesSchema).optional(),
});

export const patchedEndCustomerSchema = z.object({
  id: z.number().optional(),
  name: z.string().max(50).optional().nullable(),
  phone: z.string().max(128).optional(),
  registration_date: z.string().optional(),
  buyer_end_customer_status: z
    .union([buyerEndCustomerStatusEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  realtor: z.number().optional(),
  buyer_end_customer_activity_state: z
    .union([
      buyerEndCustomerActivityStateEnumSchema,
      blankEnumSchema,
      nullEnumSchema,
    ])
    .optional()
    .nullable(),
  number_of_potential_buyer_matches: z.number().optional(),
  is_vip: z.boolean().optional(),
  lead_source: z
    .union([leadSourceEnumSchema, blankEnumSchema, nullEnumSchema])
    .optional()
    .nullable(),
  dont_call_me: z.boolean().optional(),
  notes: z.array(endCustomerNoteSchema).optional(),
  purchase_listings: z.array(purchaseListingResponseSchema).optional(),
  phonecalls: z.array(phoneCallSchema).optional(),
  interested_in_assets: z.array(realtorHomeAssetSchema).optional(),
  sale_listings: z.array(assetSchema).optional(),
  dont_call_me_consent: z.boolean().optional(),
  consent_document_id: z.number().optional().nullable(),
});

export const purchaseListingWithMatchingAssetsAndCustomerSchema = z.object({
  end_customer: purchasingEndCustomerSchema,
  purchase_listing: purchaseListingResponseSchema,
  assets: z.array(annotatedAssetSchema),
});
