import { createEffect, createSignal } from "solid-js";
import { user } from "../globalState";



export const [showOnBoarding, setShowOnBoarding] = createSignal<boolean>(false)

createEffect(() => {
    if (user.needs_onboarding) {
        setShowOnBoarding(true)
    } else {
        setShowOnBoarding(false)
    }
})