import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from "@capacitor/core";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import { getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import axios from "axios";
import { getUserDetails } from "../api/getUserDetails";
import { checkGoogleConnectionStatus, checkWhatsupStatus } from "../Store/SocialNetworks";
import { getAllUserData } from "../Store/User";
import { setLoggedIn } from "../globalState";
import { setUserAuth } from "../Store/Auth";
export const InitFirebase = () => {
    if (Capacitor.getPlatform() === "web") {
        FirebaseAnalytics.initializeFirebase(firebaseConfig);
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    let auth;
    if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        auth = getAuth();
    }
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            const token = await user.getIdToken();
            axios.defaults.headers.common.Authorization = token;
            await getUserDetails();
            checkWhatsupStatus()
            checkGoogleConnectionStatus()
            getAllUserData()
        } else {
            setLoggedIn(false);
        }
        setUserAuth(auth)
    });
}