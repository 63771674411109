import axios from "axios";
import { setToastr } from "../../globalState";
import { setNewRequestsCount } from "../../Store/ConnectTeam";


interface IGetConnectsData {
    page: string;
    params?: any;
}
export const getConnectsData = async (data: IGetConnectsData) => {
    let url:string = '';
    if (data.page === 'requests') {
        url = '/connecteam/friend_request/'
        // url = '/connecteam/friend_requestV2/'
    } else if (data.page === 'friends') {
        url = '/connecteam/friends/'
        // url = '/connecteam/friendsV2/'
    } else if (data.page === 'potential') {
        url = '/connecteam/potential_friends/'
        // url = '/connecteam/potential_friendsV2/'
    }
    const request = await axios.get(url, {
        params: data.params
    })
    if (data.page === 'requests') {
        getNewConnectsCount()
    }
    return request
}
export const getMatches = async (data:any) => {
    let arr:Array<string | number> = []
    try {
        data.results.forEach((item:any) => {
            arr.push(item.id)
        })
    } catch(err) {
        console.log(err)
    }
    const request = await axios.get('/connecteam/realtors_matches/', {
        params: {
            realtors_ids: arr.join(',')
        }
    })
    return request.data
}


export const sendConnectRequest = async (id: string | number) => {
    const request = await axios.post('/connecteam/friend_request/', {
        user_id: id
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}

export const acceptConnectRequest = async (id: string | number) => {
    const request = await axios.patch('/connecteam/friend_request/update_status/', {
        user_id: id,
        action: 'Accepted'
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}

export const deleteConnectRequest = async (id: string | number) => {
    const request = await axios.patch('/connecteam/friend_request/update_status/', {
        user_id: id,
        action: 'Cancel'
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}

export const rejectConnectRequest = async (id: string | number) => {
    const request = await axios.patch('/connecteam/friend_request/update_status/', {
        user_id: id,
        action: 'Rejected'
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}

export const deleteUserFromFriends = async (id: string | number) => {
    const request = await axios.delete('/connecteam/friends/delete_friend/', {
        data: {friend_id: id},
    })
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    return request
}

export const getNewConnectsCount = async () => {
    const request:any = await axios.get('/connecteam/friend_request/num_new_requests/')
    .catch(err => {
        setToastr({
            status: 'error',
            show: true,
            text: 'Server error'
        })
    })
    setNewRequestsCount(request.data)
    return request
}